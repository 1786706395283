import React from "react";

export default function CertificationInvoice({
  date,
  reff,
  userId,
  data,
  businessData,
}) {
  return (
    <div ref={reff} className="p-4 ">
      <div className="flex justify-between items-center">
        <img
          src="/assets/images/municipalities/prishtina.svg"
          alt="prishtina image"
        />
        <h1 className="text-lg text-blue-800 text-[20px]">
          Republika e Kosovës/Republika Kosova
        </h1>
        <img
          src="/assets/images/municipalities/prishtina.svg"
          alt="prishtina image"
        />
      </div>
      <p className="text-[16px] mt-12">
        Bazuar në nenin 15 të ligjit nr. 04/L-060 mbi Mbeturinat, Nenet 44, 45,
        46 të Ligjit Nr. 05/L-031 për Procedurën e Përgjithshme Administrative,
        si dhe sipas Rregullores komunale 01 Nr 189/15, e cila ka të bëjë me
        urdhëresat e ndalimit të shërbimeve komunale për detyrim të pagesës së
        taksave për shërbim të grumbullimit të mbeturinave, lëshohet ky:
      </p>
      <p className="text-[16px] mt-12">
        Na osnovu Zakona o Otpadu Br. 04/L-060, Clan 15, Zakona o Opstim
        Administrativnim Procedurama, Br. 05/L-031, Clan 44, 45, 46 kao i po
        osnovi Opstinskog Pravilnika 01 Br. 110-247315, koji se bavi nalozima za
        zabranu opstinskih sluzbi za obaveznu placanja takse za prikupljanje
        otpada, izdaje se:
      </p>
      <div className="flex flex-col justify-start mt-12 mb-4 gap-4">
        <div className="flex items-center gap-2">
          <p className="border-2 border-gray-600 py-1 px-4 text-[12px] w-[20%]">
            Nr.i prot /Br.prot
          </p>
          <p className="border-2 border-gray-600 py-1 px-4 text-[12px] w-[10%]">
            28106/2023
          </p>
        </div>
        <div className="flex items-center gap-2">
          <p className="border-2 border-gray-600 py-1 px-4 text-[12px] w-[20%]">
            Datë / Datum
          </p>
          <p className="border-2 border-gray-600 py-1 px-4 text-[12px] w-[10%]">
            {date}
          </p>
        </div>
        <div className="flex items-center gap-2">
          <p className="border-2 border-gray-600 py-1 px-4 text-[12px] w-[20%]">
            Nr. i klientit/Br.klijenta
          </p>
          <p className="border-2 border-gray-600 py-1 px-4 text-[12px] w-[10%]">
            {userId}
          </p>
        </div>
        <div className="flex items-center gap-2">
          <p className="border-2 border-gray-600 py-1 px-4 text-[12px] w-[20%]">
            Vlen deri më
          </p>
          <p className="border-2 border-gray-600 py-1 px-4 text-[12px] w-[10%]">
            20/11/2023
          </p>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center gap-2">
        <h1 className="font-bold text-[40px]">V Ë R T E T I M</h1>
        <h1 className="font-semibold text-[20px]">POTVRDA / SERTIFIKA</h1>
        <p>për/ za / kime</p>

        <div className="flex flex-col gap-4 justify-between items-center">
          <p className="border-2 border-gray-600 py-1  px-24 text-[14px]">
            {data.name || businessData.name}{" "}
            {data.surname || businessData.description}
          </p>
          <div className="flex gap-4">
            <div>
              <p className="border-2 border-gray-600 py-1 px-24 text-[14px]">
                Prishtine
              </p>
              <p className="text-[10px] ">Komuna/Opstina</p>
            </div>
            <div>
              <p className="border-2 border-gray-600 py-1 px-24 text-[14px]">
                {data.address || businessData.address}
              </p>
              <p className="text-[10px] ">Adresa</p>
            </div>
          </div>
          <div className="my-4 flex flex-col gap-2 items-center">
            <p>
              I ka kryer pjeserisht ____/ plotësisht ____, obligimet e pagesës
              së mbeturinave
            </p>
            <p>
              Delimicno ____/ potpuno ____, je izvršio obavezu uplate otpada
            </p>
          </div>
        </div>
        <div className="my-4 flex flex-col gap-2 items-center">
          <p>
            Vërtetimi lëshohet për shërbime në bazë të kërkesës së palës:
            _____________________________________________
          </p>
          <p>
            Potvrda javne usluge se izdaje na osnovu zahteva stranke:
            _____________________________________________
          </p>
        </div>
      </div>
    </div>
  );
}
