import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { MdOutlineCancel } from "react-icons/md";
import { FiLogOut } from "react-icons/fi";
import { FaCircleUser } from "react-icons/fa6";
import { RiGovernmentFill } from "react-icons/ri";
import { useSelector, useDispatch } from "react-redux";
import { setActiveMenu } from "../../store/slices/appSlice";
// import { links } from "../../data/dataTable";
import "./Sidebar.css";
import { userLogout } from "../../store/slices/authSlice";
import { useTranslation } from "react-i18next";
import { LuLayoutDashboard, LuUsers2, LuActivitySquare } from "react-icons/lu";
import {
  HiOutlineDocumentChartBar,
  HiOutlineDocumentMagnifyingGlass,
  HiOutlineChartBarSquare,
} from "react-icons/hi2";
import { AiOutlineDollarCircle, AiOutlineUser } from "react-icons/ai";
import { PiChartBar } from "react-icons/pi";
import { TbFilterSearch } from "react-icons/tb";
import { RiExchangeDollarLine } from "react-icons/ri";
import { FiPieChart } from "react-icons/fi";

const Sidebar = () => {
  const {t} = useTranslation();
  const activeMenu = useSelector((state) => state.app.activeMenu);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const handleCloseSideBar = () => {
    dispatch(setActiveMenu(false));
  };

  const handleLogOut = () => {
    dispatch(userLogout());
  };

  useEffect(() => {
    const handleResize = () => {
      const screenSize = window.innerWidth;
      if (screenSize <= 900 && activeMenu) {
        dispatch(setActiveMenu(false));
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [activeMenu, dispatch]);

  const activeLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5  text-blue-800 bg-light-gray text-md border-r-4 border-blue-800 ";
  const normalLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 text-md text-gray-700  hover:text-blue-800 custom-hover-bg";

  const handleNavLinkClick = () => {
    const screenSize = window.innerWidth;
    if (screenSize <= 900 && activeMenu) {
      dispatch(setActiveMenu(false)); 
    }
  };

  const links = [
    {
      title: "Dashboard",
      links: [
        {
          name: "dashboard",
          title: "Dashboard",
          icon: <LuLayoutDashboard />,
        },
      ],
    },
  
    {
      title: "Pages",
      links: [
        {
          name: "household",
          title:t("houseHold"),
          icon: <LuUsers2 />,
        },
        {
          name: "business",
          title: t("businesess"),
          icon: <LuActivitySquare />,
        },
        {
          name: "household-bills",
          title: t("household-bills"),
          icon: <HiOutlineDocumentChartBar />,
        },
        {
          name: "business-bills",
          title: t("business-bills"),
          icon: <HiOutlineDocumentMagnifyingGlass />,
        },
        {
          name: "collection",
          title: t("collection"),
          icon: <AiOutlineDollarCircle />,
        },
        {
          name: "cashiers-report",
          title: t("cashiersRaport"),
          icon: <PiChartBar />,
        },
        {
          name: "canceled-invoices",
          title: t("canceledBillsTitle"),
          icon: <HiOutlineDocumentChartBar />,
        },
        {
          name: "users",
          title: t("usersTitle"),
          icon: <AiOutlineUser />,
        },
        {
          name: "liabilities-report",
          title: t("oweRaport"),
          icon: <HiOutlineChartBarSquare />,
        },
        {
          name: "filters",
          title: t("filters"),
          icon: <TbFilterSearch />,
        },
        {
          name: "payment-import",
          title: t("paymentImport"),
          icon: <RiExchangeDollarLine />,
        },
        // {
        //   name: "total",
        //   title: "Raporti total",
        //   icon: <FiPieChart />,
        // },
      ],
    },
  ];
  

  return (
    <div className="relative h-screen overflow-hidden flex ">
      <div
        className={`${
          activeMenu ? "w-64" : "w-0"
        } fixed h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10 bg-white transition-width duration-500 ease-in-out `}
        style={{ borderRight: "2px solid rgb(241 241 241 / 51%)" }}
      >
        <div className="flex justify-center items-center">
          <Link to="/" onClick={handleCloseSideBar} className="mt-4 mr-4 flex">
            {/* <img src="/assets/images/logo.svg" alt="Logo" />{" "} */}
            <h1 className="text-theme-color font-bold">{t("titleMunicipalities")}</h1>
          </Link>
          <button
            type="button"
            onClick={handleCloseSideBar}
            className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden"
          >
            <MdOutlineCancel />
          </button>
        </div>
        <div className="mt-10 mb-[9rem] ">
          {links.map((item) => (
            <>
              <div key={item.title}>
                {item.links.map((link) =>
                  link.isDropdown ? (
                    <div key={link.name}>
                      <div className="flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 m-2 cursor-pointer">
                        {link.icon}
                        <span className="capitalize text-black-500">
                          {link.title}
                        </span>
                      </div>
                      <div className="pl-6">
                        {link.links.map((subLink) => (
                          <NavLink
                            to={`/${subLink.name}`}
                            key={subLink.name}
                            onClick={handleNavLinkClick}
                            className={({ isActive }) =>
                              isActive ? activeLink : normalLink
                            }
                          >
                            {subLink.icon}
                            <span className="capitalize text-black-500">
                              {subLink.title}
                            </span>
                          </NavLink>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <NavLink
                      to={`/${link.name}`}
                      key={link.name}
                      onClick={handleNavLinkClick}
                      className={({ isActive }) =>
                        isActive ? activeLink : normalLink
                      }
                    >
                      {link.icon}
                      <span className=" text-black-500">
                        {link.title}
                      </span>
                    </NavLink>
                  )
                )}
              </div>
            </>
          ))}

          <div
            className={`${
              activeMenu ? "fixed" : ""
            } bottom-0 left-0 right-0  bg-white dark:bg-secondary-dark-bg border-t border-r border-gray-100 py-4 px-2 flex flex-col `}
            style={{ width: 255 }}
          >
            {user?.role === "superadmin" && (
              <div className="flex items-center cursor-pointer border-b  border-gray-100 ">
                <div className=" w-9 h-9 rounded-full flex items-center justify-center text-gray-500 mr-2 ">
                  <RiGovernmentFill className="mb-3"/>
                </div>
                <Link to="/municipalities">
                  <div>
                    <p className="text-sm font-bold text-gray-600 mb-3">{t("municipalities")}</p>
                  </div>
                </Link>
              </div>
            )}
            <div className="flex items-center cursor-pointer mt-4 ">
              <div className=" w-9 h-9 rounded-full flex items-center justify-center text-gray-500 mr-2">
                <FaCircleUser />
              </div>
              <Link to="/profile">
                <div>
                  {user && (
                    <p className="text-sm font-bold text-gray-600">
                      {user.name} {user.user}
                    </p>
                  )}
                </div>
              </Link>
            </div>
            <div className="my-4 border-t border-gray-100 px-0 " />
            <div className="flex items-center cursor-pointer">
              <div className=" w-9 h-9 rounded-full flex items-center justify-center  mr-1">
                <FiLogOut />
              </div>
              <div>
                <button
                  className="text-sm font-bold text-gray-600 "
                  onClick={handleLogOut}
                >
                   {t("logout")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
