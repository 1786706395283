import React from "react";
import { TableLiabilitiesReport } from "../../components";

const LiabilitiesReport = () => {
  return (
    <div className="m-4" data-aos="zoom-in-down">
      <TableLiabilitiesReport />
    </div>
  );
};

export default LiabilitiesReport;
