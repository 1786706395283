import React from "react";
import "../Bills/invoice.module.css";
import Barcode from "react-barcode";

export default function BillInvoice({ reff, billData }) {
  return (
    <div ref={reff} className="p-6 w-[600px] print-font-family ">
      <div className="flex justify-center items-center gap-4 pt-[20px]">
        <div className="flex flex-col" style={{ width: "33%" }}>
          <h1 className="font-bold text-[13px] text-right">
            TAKSA E GRUMBULLIMIT TË MBETURINAVE
          </h1>
          <h1 className=" text-[11px] text-right mt-1 ">
            POREZ ZA SAKUPLJANJE OTPADA
          </h1>
        </div>
        <img
          src="/assets/images/Stema_e_Komunës_Prishtinë.svg.png"
          alt="prishtina image"
          className="w-[60px] h-[77px]"
        />
        <div className="flex flex-col" style={{ width: "33%" }}>
          <p className="font-bold text-[35px] text-left ml-[-2px]">
            FATUR&Euml;
          </p>
          <p className=" text-[11px] text-left mb-2">FAKTURA</p>
        </div>
      </div>

      <div className="flex justify-between py-8 px-4 w-full text-[8px] mt-2">
        <div className="flex flex-col justify-between font-semibold gap-2 w-1/2">
          <div className="flex flex-col gap-4">
            <h1 className="font-bold text-[10px]">
              {billData?.name} {billData?.surname}
            </h1>
            <p className="font-normal text-[10px] w-[70%]">
              NR. 25, KOM-PIKA K-5, VËLLEZËRIT BYTYQI, PRISHTINË
            </p>
          </div>
          <div className="flex gap-6 items-center pt-4">
            <div className="text-[9px]">
              <h1 className="font-bold">Kategoria e klientit:</h1>
              <p className="font-light">kategorija kupaca</p>
            </div>
            <h1 className="text-[25px] !font-bold">A</h1>
          </div>
        </div>
        <div className="flex flex-col  gap-2 w-[41%]">
          <div className="flex w-full text-[9px]">
            <div className="w-[80%]">
              <p className="font-bold">Numri i faturës:</p>
              <p>Broj fakture</p>
            </div>
            <p className="font-bold w-1/2">1761096</p>
          </div>

          <div className="flex w-full text-[9px]">
            <div className="w-[80%]">
              <p className="font-bold">Data e lëshimit:</p>
              <p>Datum izdavanja</p>
            </div>
            <p className="font-bold w-1/2">14.02.2024</p>
          </div>
          <div className="flex w-full text-[9px]">
            <div className="w-[80%]">
              <p className="font-bold">Numri i klientit:</p>
              <p>Broj klienta</p>
            </div>
            <p className="font-bold w-1/2">{billData?.personal_number}</p>
          </div>
          <div className="flex w-full text-[9px]">
            <div className="w-[80%]">
              <p className="font-bold text-nowrap	">Periudha e faturimit:</p>
              <p>Period fakturisanja</p>
            </div>
            <p className="font-bold w-1/2">01.01.2024 - 31.01.2024</p>
          </div>
        </div>
      </div>
      <div className="flex justify-between py-1 px-4 w-full text-[9px]">
        <div className="flex flex-col justify-between font-semibold gap-2 w-1/2">
          <h1 className="font-bold">
            Specifikimi / <span className="font-light">Specifikacija</span>
          </h1>
          <div className="flex flex-col gap-2 my-2">
            <h1 className="font-bold">
              Taksa e muajit Janar /{" "}
              <span className="font-light">Porez za mesec januar</span>
            </h1>
            <h1 className="font-bold">
              Borxhi paraprak /{" "}
              <span className="font-light">Preliminarni dug</span>
            </h1>
            <h1 className="font-bold">
              Totali për pagesë /{" "}
              <span className="font-light">Ukupno treba platiti</span>
            </h1>
          </div>
          <div className="mt-3">
            <h1 className="font-bold">Pagesa mund të kryhet në llogarinë</h1>
            <p className=" font-light">Uplata se može izvršiti na račun</p>
          </div>
          <h1 className="font-bold">1000650230000067</h1>
        </div>
        <div className="flex flex-col  gap-4 w-[41%] text-[9px] ">
          <div className="flex flex-col justify-between font-semibold gap-2 ">
            <div className="flex justify-between">
              <div className="flex flex-col">
                <h1 className="font-bold">
                  Tarifa / <span className="font-light">Tarifa (€)</span>
                </h1>
                <div className="flex flex-col my-1 justify-end text-right gap-1 text-[13px] my-2 ">
                  <p className="font-bold">6.20</p>
                  <h1 className="font-bold">-</h1>
                  <h1 className="font-bold">-</h1>
                </div>
              </div>
              <div className="flex flex-col">
                <p className="font-bold">
                  Shuma / <span className="font-light"> Iznos (€)</span>
                </p>
                <div className="flex flex-col my-1 justify-end text-right gap-1 text-[13px] my-2">
                  <p className="font-bold">6.20</p>
                  <p className="font-bold">24.80</p>
                  <p className="font-bold">{billData?.value}100.00</p>
                </div>
              </div>
            </div>
            <div className="flex flex-col  mt-[16px] pt-1">
              <h1 className="font-bold">
                apo fizikisht duke vizituar zyren përkatese në komunen tuaj
              </h1>
              <h1 className="font-light">
                ili fizički posetom relevantnoj kancelariji u vašoj opštini
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div className="text-[9px] py-2 pt-6 px-4 ">
        <h1 className="font-bold ">
          Pse duhet të paguajmë taksen e mbeturinave?
        </h1>
        <p className="text-left text-[7.8px]">
          Fatura e taksës së grumbullimit të mbeturinava dorëzohet për çdo muaj
          tek të gjithë klientët që marrin shërbimin e grumbullimit të
          mbeturinave. Pagesa e kësaj takse ndikon drejtëpërsëdrejti në
          kualitetin e shërbimit të grumbullimit, përmirësimin e kushteve të
          punës së punëtorëve të kompanisë si dhe avancimin e infrastrukturës,
          respektivisht kontenjerëve dhe kamionëve grumbullues. Kjo taksë
          mblidhet dhe menaxhohet nga komuna përkatëse. Klientët janë të
          obliguar të paguajnë faturën me rregull dhe në rast të vonesës apo mos
          pagesës, ata do të sanksionohen në përpuethshmëri me rregulloren
          komunale për menaxhimin e mbeturinave.
        </p>
      </div>

      <div className="text-[9px]  py-2 px-4">
        <h1 className="font-bold">Zašto moramo da plaćamo porez na otpad?</h1>
        <p className="text-[left] text-[7.8px]">
          Račun takse za odvoz smeća se dostavlja mesečno svim kupcima koji
          dobiju uslugu odvoza smeća. Plaćanje ove takse direktno utiče na
          kvalitet usluge prikupljanja, poboljšanje uslova rada radnika
          kompanije kao i unapređenje infrastrukture, odnosno kontejnera i
          sabirnih kamiona. Ovaj porez prikuplja i njime upravlja odgovarajuća
          opština. Kupci su u obavezi da redovno plaćaju račun iu slučaju
          kašnjenja ili neplaćanja biće sankcionisani u skladu sa opštinskim
          propisom za upravljanje otpadom.
        </p>
      </div>

      <div className="flex  items-center gap-1 text-[7.7px] py-4 px-2 ">
        <img
          src="/assets/images/ekosova-svg.png"
          alt="ekosova"
          className="w-[70px] h-[40px]"
        ></img>
        <div className="">
          <p className="font-bold">
            Tani faturën mund ta paguani edhe online përmes e-Kosova, duke
            vizituar faqen ekosova.rks-gov.net
          </p>
          <p>
            Sada možete da platite račun i onlajn preko e-Kosova, posećujući veb
            stranicu ekosova.rks-gov.net
          </p>
        </div>
      </div>

      <div className="flex flex-col items-center justify-end text-sm h-[50px]">
        <Barcode
          value="PRMMB1714170018T"
          format="CODE39"
          fontSize={10}
          width={1}
          height={30}
        />
      </div>
    </div>
  );
}
