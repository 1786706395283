import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchAllHouseholdBills } from "../../../store/slices/tablesInfoSlices/householdBillSlice";
import { fetchHouseholdBillById } from "../../../store/slices/tablesInfoSlices/householdBillSlice";
import moment from "moment";

const BillInfo = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const householdBillsData = useSelector((state) => state.householdBills.data);

  useEffect(() => {
    dispatch(fetchHouseholdBillById(id));
  }, [dispatch, id]);

  if (!householdBillsData) {
    return <div>Bill not found</div>;
  }

  return (
    <div className="p-6  w-[70%]" key={householdBillsData.id}>
      <h1 className="text-blue-900 font-bold py-4 ">Fatura</h1>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400 w-[20%]">Nr Klientit</p>
        <p>{householdBillsData.id}</p>
      </div>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400 w-[20%]">Emri</p>
        <p>{householdBillsData.name}</p>
      </div>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400 w-[20%]">Mbiemri</p>
        <p>{householdBillsData.surname}</p>
      </div>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400 w-[20%]">Përshkrimi</p>
        <p>{householdBillsData.description}</p>
      </div>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400 w-[20%]">Vlera</p>
        <p>{householdBillsData.value}</p>
      </div>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400 w-[20%]">Numri i pronës</p>
        <p>{householdBillsData.household_id}</p>
      </div>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400 w-[20%]">Data e Skadimit</p>
        <p>{moment(householdBillsData.skanin).format("L")}</p>
      </div>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400 w-[20%]">Data Regjistrimit</p>
        <p>{moment(householdBillsData.created_at).format("L")}</p>
      </div>

      <div className="flex justify-end py-4">
        <Link to="/household">
          <button
            type="submit"
            className="px-12 py-2 bg-[#2FBFDE] text-white rounded-md"
          >
            Kthehu te lista
          </button>
        </Link>
      </div>
    </div>
  );
};

export default BillInfo;
