import React, { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Login,
  ForgotPassword,
  ChangePassword,
  SearchBill,
  AddNewCitizen,
  AddNewCollection,
  AreasRatio,
  Business,
  BusinessBills,
  CanceledInvoices,
  CashiersReport,
  Collections,
  Dashboard,
  Filters,
  HouseHoldBills,
  Household,
  LiabilitiesReport,
  PaymentImport,
  TotalReport,
  Users,
  EditCitizen,
  CitizenInfo,
  Profile,
  FinancialCard,
  MyBills,
  GenerateBill,
  Municipalities,
  MunicipalitiesRegistrationForm,
  MunicipalitiesInfo,
  AddNoBillNewCollection,
  AddNewBill,
} from "../pages";
import Certification from "../pages/Forms/Certification/Certification";
import BillInfo from "../pages/Forms/Bills/BillInfo";
import EditBill from "../pages/Forms/Bills/EditBill";
import AddNewBusiness from "../pages/Forms/BusinessForms/AddNewBusiness";
import EditBusiness from "../pages/Forms/BusinessForms/EditBusiness";
import AddUser from "../pages/Users/AddUser";
import EditUser from "../pages/Users/EditUser";
import CertificationInvoice from "../pages/Forms/Certification/CertificationInvoice";
import BusinessInfo from "../pages/Business/BusinessInfo";
import BusinessNewCollection from "../pages/Forms/AddNewCollection/BusinessNewCollection";
import AddNewBusinessBill from "../pages/Forms/Bills/AddNewBusinessBill";
import Info from "../pages/Info/Info";
import FirstPage from "../pages/Info/FirstPage";
import BillInvoice from "../pages/Forms/Bills/BillInvoice";
import CollectionUpdateBill from "../pages/Forms/AddNewCollection/CollectionUpdateBill";

const PrivateRoute = ({ children }) => {
  const { user } = useSelector((state) => state.auth);
  return user ? children : <Navigate to="/first" />;
};

const PublicRoute = ({ children }) => {
  const { user } = useSelector((state) => state.auth);
  return user ? <Navigate to="/" /> : children;
};

const Path = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/municipalities"
        element={
          <PrivateRoute>
            <Municipalities />{" "}
          </PrivateRoute>
        }
      />
      <Route
        path="/municipalities/:id"
        element={
          <PrivateRoute>
            <MunicipalitiesRegistrationForm />
          </PrivateRoute>
        }
      />
      <Route
        path="/municipalities/info/:id"
        element={
          <PrivateRoute>
            <MunicipalitiesInfo />
          </PrivateRoute>
        }
      />
      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/business"
        element={
          <PrivateRoute>
            <Business />
          </PrivateRoute>
        }
      />
      <Route
        path="/business/:id"
        element={
          <PrivateRoute>
            <BusinessBills />
          </PrivateRoute>
        }
      />
      <Route
        path="/business-bills"
        element={
          <PrivateRoute>
            <BusinessBills />
          </PrivateRoute>
        }
      />
      <Route
        path="/household"
        element={
          <PrivateRoute>
            <Household />
          </PrivateRoute>
        }
      />
      <Route
        path="/household/:id"
        element={
          <PrivateRoute>
            <HouseHoldBills />
          </PrivateRoute>
        }
      />
      <Route
        path="/household-bills"
        element={
          <PrivateRoute>
            <HouseHoldBills />
          </PrivateRoute>
        }
      />
      <Route
        path="/collection"
        element={
          <PrivateRoute>
            <Collections />
          </PrivateRoute>
        }
      />
      <Route
        path="/add-new-bill"
        element={
          <PrivateRoute>
            <AddNewBill />
          </PrivateRoute>
        }
      />
          <Route
        path="/add-new-bill/:id"
        element={
          <PrivateRoute>
            <AddNewBill />
          </PrivateRoute>
        }
      />
      <Route
        path="/add-new-collection/:id"
        element={
          <PrivateRoute>
            <AddNewCollection />
          </PrivateRoute>
        }
      />
      <Route
        path="/add-new-collection"
        element={
          <PrivateRoute>
            <AddNoBillNewCollection />
          </PrivateRoute>
        }
      />
      <Route
        path="/cashiers-report"
        element={
          <PrivateRoute>
            <CashiersReport />
          </PrivateRoute>
        }
      />
      <Route
        path="/canceled-invoices"
        element={
          <PrivateRoute>
            <CanceledInvoices />
          </PrivateRoute>
        }
      />
      <Route
        path="/users"
        element={
          <PrivateRoute>
            <Users />
          </PrivateRoute>
        }
      />
      <Route
        path="/liabilities-report"
        element={
          <PrivateRoute>
            <LiabilitiesReport />
          </PrivateRoute>
        }
      />
      <Route
        path="/filters"
        element={
          <PrivateRoute>
            <Filters />
          </PrivateRoute>
        }
      />
      <Route
        path="/payment-import"
        element={
          <PrivateRoute>
            <PaymentImport />
          </PrivateRoute>
        }
      />
      <Route
        path="/total"
        element={
          <PrivateRoute>
            <TotalReport />
          </PrivateRoute>
        }
      />
      <Route
        path="/areas-ratio"
        element={
          <PrivateRoute>
            <AreasRatio />
          </PrivateRoute>
        }
      />
      <Route
        path="/add-new-citizen"
        element={
          <PrivateRoute>
            <AddNewCitizen />
          </PrivateRoute>
        }
      />
      <Route
        path="/edit-citizen/:id"
        element={
          <PrivateRoute>
            <EditCitizen />
          </PrivateRoute>
        }
      />
      <Route
        path="/edit-businesess/:id"
        element={
          <PrivateRoute>
            <EditBusiness />
          </PrivateRoute>
        }
      />
      <Route
        path="/add-new-business"
        element={
          <PrivateRoute>
            <AddNewBusiness />
          </PrivateRoute>
        }
      />
      <Route
        path="/citizen-info/:id"
        element={
          <PrivateRoute>
            <CitizenInfo />
          </PrivateRoute>
        }
      />
      <Route
        path="/bill-info/:id"
        element={
          <PrivateRoute>
            <BillInfo />
          </PrivateRoute>
        }
      />
      <Route
        path="/edit-bill/:id"
        element={
          <PrivateRoute>
            <EditBill />
          </PrivateRoute>
        }
      />
      <Route
        path="/certificates/:id"
        element={
          <PrivateRoute>
            <Certification />
          </PrivateRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <PrivateRoute>
            <Profile />
          </PrivateRoute>
        }
      />
      <Route path="/financial-card/:id/:name/:surname" element={<FinancialCard />} />
      <Route
        path="/my-bills"
        element={
          <PrivateRoute>
            <MyBills />
          </PrivateRoute>
        }
      />
      <Route
        path="/generate-bill"
        element={
          <PrivateRoute>
            <GenerateBill />
          </PrivateRoute>
        }
      />
      <Route
        path="/users/add-new-user"
        element={
          <PrivateRoute>
            <AddUser />
          </PrivateRoute>
        }
      ></Route>
      <Route
        path="/generate"
        element={
          <PrivateRoute>
            <CertificationInvoice />
          </PrivateRoute>
        }
      />
      <Route
        path="/users/add-new-user/:id"
        element={
          <PrivateRoute>
            <EditUser />
          </PrivateRoute>
        }
      ></Route>
      <Route
        path="/business/businessInfo/:id"
        element={
          <PrivateRoute>
            <BusinessInfo />
          </PrivateRoute>
        }
      />
      <Route
        path="/business/add-new-bill"
        element={
          <PrivateRoute>
            <AddNewBusinessBill />
          </PrivateRoute>
        }
      ></Route>
      <Route
        path="/collection/update-bill/:id"
        element={
          <PrivateRoute>
            {" "}
            <CollectionUpdateBill />
          </PrivateRoute>
        }
      />
      <Route
        path="/business/add-new-collection/:id"
        element={<BusinessNewCollection />}
      />
      <Route
        path="/login"
        element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        }
      />
      <Route
        path="/forgot-password"
        element={
          <PublicRoute>
            <ForgotPassword />
          </PublicRoute>
        }
      />
      <Route
        path="/change-password"
        element={
          <PublicRoute>
            <ChangePassword />
          </PublicRoute>
        }
      />
      <Route
        path="/search-bill"
        element={
          <PublicRoute>
            <SearchBill />
          </PublicRoute>
        }
      />
      <Route
        path="/first"
        element={
          <PublicRoute>
            <FirstPage />
          </PublicRoute>
        }
      />
      <Route
        path="/info"
        element={
          <PublicRoute>
            <Info />
          </PublicRoute>
        }
      />
      <Route path="/*" element={<FirstPage />} />

      <Route path="/test" element={<BillInvoice />}></Route>
    </Routes>
  );
};

export default Path;
