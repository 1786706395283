import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Input from "../../../components/Inputs/Input";
import { updateHousehold } from "../../../store/slices/tablesInfoSlices/houseHoldsSlice";
import { fetchHouseholdById, fetchHouseholds } from "../../../store/slices/tablesInfoSlices/houseHoldsSlice";
import { useDispatch, useSelector } from "react-redux";
import ActionButton from "../../../components/Button/ActionButton";

const validationSchema = Yup.object().shape({
  client_number: Yup.string().required("Required"),
  name: Yup.string().required("Required"),
  surname: Yup.string().required("Required"),
  parent_name: Yup.string().required("Required"),
  personal_number: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  door: Yup.string().required("Required"),
  parcel_number: Yup.string().required("Required"),
  zone: Yup.string().required("Required"),
  surface: Yup.string().required("Required"),
  status: Yup.string().required("Required"),
  comment: Yup.string().required("Required"),
});

export default function EditCitizen() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const household = useSelector((state) => state.households.data);
  const isLoading = useSelector((state) => state.households.isLoading);

  useEffect(() => {
    dispatch(fetchHouseholdById(id));
  }, [dispatch, id]);

  const handleSubmit = (values) => {
    dispatch(updateHousehold({ id, updatedHousehold: values }));
    dispatch(fetchHouseholds());
    navigate("/household");
  };

  const initialValues = {
    client_number: household.id || "",
    name: household.name || "",
    surname: household.surname || "",
    parent_name: household.parent_name || "",
    personal_number: household.personal_number || "",
    address: household.address || "",
    door: household.door || "",
    parcel_number: household.parcel_number || "",
    zone: household.zone || "",
    surface: household.surface || "",
    status: household.status || "",
    comment: household.comment || "",
  }

  return (
    <div className="p-6">
      <h1 className="py-6 text-blue-900 font-semibold">Edito te dhenat</h1>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ errors, touched, handleChange, values }) => (
          <Form className="space-y-4 text-xs">
            <Input
              label="Nr Klientit"
              id="client_numbers"
              value={values.client_number}
              name="client_number"
              placeholder="Nr Klientit"
              onChange={handleChange}
            />
            <Input
              label="Emri"
              name="name"
              placeholder="Emri"
              onChange={handleChange}
              value={values.name}
            />
            <Input
              label="Mbiemri"
              name="surname"
              placeholder="Mbiemri"
              onChange={handleChange}
              value={values.surname}
            />
            <Input
              label="Emri i Prindit"
              name="parent_name"
              placeholder="Emri i Prindit"
              onChange={handleChange}
              value={values.parent_name}
            />
            <Input
              label="Nr. Personal"
              name="personal_number"
              placeholder="Nr. Personal"
              onChange={handleChange}
              value={values.personal_number}
            />
            <Input
              label="Adresa"
              name="address"
              placeholder="Adresa"
              onChange={handleChange}
              value={values.address}
            />
            <Input
              label="Dera"
              name="door"
              placeholder="Dera"
              onChange={handleChange}
              value={values.door}
            />
            <Input
              label="Nr. Parcela"
              name="parcel_number"
              placeholder="Nr. Parcela"
              onChange={handleChange}
              value={values.parcel_number}
            />
            <Input
              label="Zona"
              name="zone"
              placeholder="Zona"
              onChange={handleChange}
              value={values.zone}
            />
            <Input
              label="Sipërfaqja"
              name="surface"
              placeholder="Sipërfaqja"
              onChange={handleChange}
              value={values.surface}
            />
            <Input
              label="Status"
              name="status"
              placeholder="Status"
              onChange={handleChange}
              value={values.status}
            />
            <Input
              as="textarea"
              label="Koment"
              name="comment"
              placeholder="Koment"
              onChange={handleChange}
              value={values.comment}
            />
            <div className="flex justify-end">
              <Link to="/household">
                <button
                  type="button"
                  className="px-4 py-2 mr-2 bg-white text-[#28ABE2] rounded-md"
                >
                  Anulo
                </button>
              </Link>
              <ActionButton
                title="Ruaj"
                type="submit"
                isLoading={isLoading}
              ></ActionButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
