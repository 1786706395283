import React, { useState } from "react";
import { MdOutlineLanguage } from "react-icons/md";
import { useTranslation } from "react-i18next";

const LanguageSwitcher = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="flex items-center text-gray-400 ml-2">
      <MdOutlineLanguage style={{ width: 24, height: 24 }} />
      <select
        onChange={(e) => i18n.changeLanguage(e.target.value)}
        value={i18n.language}
        className="pl-1 bg-white rounded-md border-none outline-none text-sm"
      >
        <option value="al">AL</option>
        <option value="en">EN</option>
        <option value="sr">SR</option>
      </select>
    </div>
  );
};

export default LanguageSwitcher;
