import React from "react";
import { TableHouseHold } from "../../components";

const Household = () => {
  return (
    <div className="m-4" data-aos="zoom-in-down"  >
      <TableHouseHold />
    </div>
  );
};

export default Household;
