import React from "react";
import { useTranslation } from "react-i18next";

export default function CashiersReportChart() {
  const {t} = useTranslation();
  return (
    <div className="p-3 border-r-1 border-l-1 ">
      <div>
        <div>
          <h1 className="text-sm p-2">{t("cashiersRaport")}</h1>
          <p className="text-xs p-2">Adipiscing elit, sed do eiusmod tempor</p>
        </div>
        <div className="flex justify-between px-2 py-4  border-b-1">
          <p className="text-xs">
            <span className="text-red-600">↓ 2.1% </span> vs Muaji i Kaluar
          </p>
          <p className="text-xs ">Komuna</p>
        </div>
        <div className="flex justify-between px-2 py-4  border-b-1">
          <p className="text-xs">
            <span className="text-red-600">↓ 2.1% </span>vs Muaji i Kaluar
          </p>
          <p className="text-xs ">Komuna</p>
        </div>
        <div className="flex justify-between px-2 py-4  border-b-1">
          <p className="text-xs">
            <span className="text-red-600">↓ 2.1%</span> vs Muaji i Kaluar
          </p>
          <p className="text-xs ">Komuna</p>
        </div>
        <div className="flex justify-between px-2 py-4  ">
          <p className="text-xs">
            <span className="text-red-600">↓ 2.1% </span>vs Muaji i Kaluar
          </p>
          <p className="text-xs ">Komuna</p>
        </div>
      </div>
    </div>
  );
}
