import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Info() {
  const { t } = useTranslation();
  return (
    <div
      className=" flex flex-col items-center justify-between p-12  gap-12 text-[#9A9A9A] font-light text-center"
      data-aos="fade-down"
    >
      <img src="/assets/images/sms-eu logo 2022_3(latest).svg"></img>
      <p>
        Ky projekt është realizuar me mbështetjen financiare të Bashkimit
        Evropian dhe Qeverisë Gjermane. Përmbajtja e tij është përgjegjësi e
        vetme e GIZ-së dhe nuk pasqyron domosdoshmërisht pikëpamjet e Bashkimit
        Evropian apo Qeverisë Gjermane.
      </p>
      <p>
        Ovaj projekat je realizovan uz finansijsku podrsku Evropske unije i
        Vlade Nemacke. Njegov sadriaj je iskljuiva odgovornost GIZ-a i ne
        odrazava nuzno stavove Evropske unije ili nemacke vlade.
      </p>
      <Link to="/first">
        <a className="border-2 p-2 mt-12 cursor-pointer rounded">{t("back")}</a>
      </Link>
    </div>
  );
}
