import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "./../../api/axiosInstance";

export const fetchDebtReports = createAsyncThunk(
  "debtReports/fetchDebtReports",
  async (params) => {
    try {
      const response = await axiosInstance.get("/debt_raports/getDebtRaports", {
        params,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const debtReportsSlice = createSlice({
  name: "debtReports",
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDebtReports.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDebtReports.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchDebtReports.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const selectDebtReports = (state) => state.debtReports;

export default debtReportsSlice.reducer;
