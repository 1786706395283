import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../api/axiosInstance";
import { toast } from "react-toastify";

const initialState = {
  data: [],
  status: "idle",
  error: null,
};

// Define async thunks for your API endpoints
export const createHouseholdBill = createAsyncThunk(
  "householdBills/create",
  async (householdBillData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        "/householdBill/createHouseholdBill",
        householdBillData
      );
      return response.data;
    } catch (error) {
      toast.error("Diqka shkoi keq!")
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchAllHouseholdBills = createAsyncThunk(
  "householdBills/fetchAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        "/householdBill/getAllHouseholdBills"
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchHouseholdBillsByHouseholdId = createAsyncThunk(
  "householdBills/fetchById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `/householdBill/getHouseholdBillByHouseholdId/${id}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchHouseholdBillById = createAsyncThunk(
  "householdBills/fetchById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `/householdBill/getHouseholdBillById/${id}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateHouseholdBill = createAsyncThunk(
  "householdBills/update",
  async ({ id, updatedHouseholdBill }, { rejectWithValue }) => {
    console.log("iddd", id);
    try {
      const response = await axiosInstance.put(
        `/householdBill/updateHouseHoldBill/${id}`,
        updatedHouseholdBill
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteHouseholdBill = createAsyncThunk(
  "householdBills/delete",
  async (id, { rejectWithValue }) => {
    try {
      await axiosInstance.delete(`/householdBill/deleteHouseholdBill/${id}`);
      return id;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchSoftDeletedBills = createAsyncThunk(
  "householdBills/fetchSoftDeletedBills",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        "/householdBill/softDeletedHouseHoldBills"
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteSoftDeletedBillById = createAsyncThunk(
  "softDeletedBills/delete",
  async (id) => {
    try {
      const response = await axiosInstance.delete(
        `/householdBill/deleteSoftDeletedHouseholdBill/${id}`,
        {
          method: "DELETE",
        }
      );
      if (!response.ok) {
        throw new Error("Failed to delete household bill");
      }
      return id;
    } catch (error) {
      throw error;
    }
  }
);

export const undeleteBillById = createAsyncThunk(
  "softDeletedBills/undelete",
  async (id) => {
    try {
      const response = await axiosInstance.put(
        `/householdBill/undeleteBill/${id}`,
        {
          method: "PUT",
        }
      );
      if (!response.ok) {
        throw new Error("Failed to undelete bill");
      }
      return id;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteAllCanceledBills = createAsyncThunk(
  "deleteAllCanceledBills/delete",
  async () => {
    try {
      const response = await axiosInstance.delete(
        `/businessBill/deleteAllBills`,
        {
          method: "DELETE",
        }
      );
      if (!response.ok) {
        throw new Error("Failed to delete bills");
      }
    } catch (error) {
      throw error;
    }
  }
);


const householdBillsSlice = createSlice({
  name: "householdBills",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createHouseholdBill.fulfilled, (state, action) => {
        state.data.push(action.payload.household);
        toast.success("Fatura u krijua me sukses!");
      })
      .addCase(fetchAllHouseholdBills.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(fetchHouseholdBillById.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(updateHouseholdBill.fulfilled, (state, action) => {
        state.data = action.payload;
        toast.success("Fatura u editua me sukses!");
      })
      .addCase(deleteHouseholdBill.fulfilled, (state, action) => {
        state.data = state.householdBills.filter(
          (bill) => bill.id !== action.payload
        );
        toast.success("Fatura u fshi me sukses!");
      })
      .addCase(fetchSoftDeletedBills.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSoftDeletedBills.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(fetchSoftDeletedBills.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteSoftDeletedBillById.fulfilled, (state, action) => {
        state.data = state.data.filter((bill) => bill.id !== action.payload);
        toast.success("Fatura u fshi me sukses!");
      })
      .addCase(undeleteBillById.fulfilled, (state, action) => {
        toast.success("Fatura u rikthye me sukses!");
      })
      .addCase(deleteAllCanceledBills.fulfilled, (state, action) => {
        state.data = action.payload;
        toast.success("Fatura u fshi me sukses!");
      })
      .addMatcher(
        (action) => action.type.endsWith("/pending"),
        (state) => {
          state.status = "loading";
          state.error = null;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.status = "failed";
          state.error = action.payload;
        }
      );
  },
});

export default householdBillsSlice.reducer;
