import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSearchQuery } from "../../../store/slices/tableSlice";
import { SearchBarTable, Pagination, Button, Table } from "../../../components";
import { FaPlus, FaDollarSign, FaTrash } from "react-icons/fa";
import { RiUpload2Fill } from "react-icons/ri";
import { Link } from "react-router-dom";
import {
  getAllCollections,
  deleteCollection,
} from "../../../store/slices/tablesInfoSlices/collectionsSlice";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const TableCollections = () => {
  const dispatch = useDispatch();
  const collectionsData = useSelector((state) => state.collections.data);
  const [selectedFilter, setSelectedFilter] = useState("household");
  const {t} = useTranslation();


  const filteredCollectionsData =
    selectedFilter === "household"
      ? Array.isArray(collectionsData?.householdCollections)
        ? collectionsData?.householdCollections
        : []
      : Array.isArray(collectionsData?.businessCollections)
      ? collectionsData?.businessCollections
      : [];
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const totalItems = filteredCollectionsData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredCollectionsData.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  useEffect(() => {
    dispatch(getAllCollections());
  }, [dispatch]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    dispatch(updateSearchQuery(searchInput));
  };

  const handleDeleteCollection = (collectionId) => {
    dispatch(deleteCollection(collectionId))
      .then(() => {
        dispatch(getAllCollections());
        toast.success("Collection deleted successfully");
      })
      .catch((error) => {
        toast.error("Failed to delete collection");
      });
  };



  const currentItemsWithIcons = currentItems.map((item) => ({
    ...item,
    actions: (
      <div className="flex gap-2 cursor-pointer text-gray-400">
        {selectedFilter === "household" ? (
          <Link to={`/add-new-collection/${item.household_id}`}>
            <div title="Inkasimi per amviseri">
              <FaDollarSign className="w-[17px] h-[17px]" />
            </div>
          </Link>
        ) : (
          <Link to={`/business/add-new-collection/${item.business_id}`}>
            <div title="Inkasimi per bizens">
              <FaDollarSign className="w-[17px] h-[17px]" />
            </div>
          </Link>
        )}

        <div title="Delete" onClick={() => handleDeleteCollection(item.id)}>
          <FaTrash className="w-[17px] h-[17px]" />
        </div>
      </div>
    ),
  }));

  useEffect(() => {
    dispatch(getAllCollections());
  }, [dispatch]);

  const columns =
    selectedFilter === "household"
      ? [
          { key: "household_id", label: "ID" },
          { key: "id", label: t("billNumber") },
          { key: "value", label: t("collectionValue") },
          { key: "created_at", label: t("date")},
          { key: "type", label: t("type") },
          { key: "tipifat", label: "Tipifat" },
          { key: "actions", label: "" },
        ]
      : [
          { key: "business_id", label: "Nr i Biznesit" },
          { key: "id", label: "Nr i Fatures" },
          { key: "value", label: "Inkasuar Vlera €" },
          { key: "created_at", label: "Data" },
          { key: "type", label: "Tipi" },
          { key: "tipifat", label: "Tipifat" },
          { key: "actions", label: "" },
        ];

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
  };


  return (
    <div className="p-3 mt-3">
      <div className="flex items-center justify-between pb-4 ">
        <h1 className="text-blue-900 font-semibold	">{t("collections")}</h1>
        <div className="flex items-center justify-end bg-white dark:bg-gray-900">
          <SearchBarTable
            searchInput={searchInput}
            handleSearchInputChange={handleSearchInputChange}
            handleSearchSubmit={handleSearchSubmit}
            placeholder={t("search")}
          />
          <div className="flex">
            <Button
              className={`text-blue-800 bg-gray-100 ${
                selectedFilter === "household" ? "!bg-blue-200" : ""
              }`}
              onClick={() => handleFilterChange("household")}
            >
              {t("houseHoldCollections")}
            </Button>
            <Button
              className={`text-blue-800 bg-gray-100 ${
                selectedFilter === "business" ? "!bg-blue-200" : ""
              }`}
              onClick={() => handleFilterChange("business")}
            >
              {t("businessCollections")}
            </Button>
            <Button className="text-blue-800 text-sm bg-gray-100">
              <RiUpload2Fill className="mx-2" />
            </Button>
            <Link to="/add-new-collection">
              <Button className="text-white bg-blue-800 transition duration-300 ease-in-out hover:bg-gray-200 hover:text-blue-800">
                <FaPlus className="mr-1" /> {t("addNewCollection")}
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <Table data={currentItemsWithIcons} columns={columns} />
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
        className="flex items-center justify-center mt-4"
      />
    </div>
  );
};

export default TableCollections;
