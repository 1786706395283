import React from "react";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Input from "../../../components/Inputs/Input";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createBusinessBill } from "../../../store/slices/tablesInfoSlices/businessBillSlice";
import { useTranslation } from "react-i18next";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  surname: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
  personal_number: Yup.string().required("Required"),
  skanin: Yup.string().required("Required"),
  value: Yup.string().required("Required"),
  business_id: Yup.string().required("Required"),
});

export default function AddNewBusinessBill() {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);


  const initialValues = {
    name: "",
    surname: "",
    description: "",
    personal_number: "",
    skanin: "",
    value: "",
    business_id: "",
    user_id: user.id || ""
  };

  return (
    <div className="p-6">
      <h1 className="py-6 text-blue-900 font-semibold">{t("addNewBill")} Biznes</h1>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          dispatch(createBusinessBill(values));
          navigate("/business")
        }}
      >
        {({ errors, touched, handleChange, values }) => (
          <Form className="space-y-4 text-xs">
            <Input
              label={t("name")}
              id="name"
              onChange={handleChange}
              name="name"
              placeholder={t("name")}
              error={errors.name && touched.name ? errors.name : null}
            />
            <Input
              label={t("surname")}
              id="surname"
              onChange={handleChange}
              name="surname"
              placeholder={t("surname")}
              error={errors.surname && touched.surname ? errors.surname : null}
            />
            <Input
              label={t("description")}
              id="description"
              onChange={handleChange}
              name="description"
              placeholder={t("description")}
              error={
                errors.description && touched.description
                  ? errors.description
                  : null
              }
            />
            <Input
              label={t("personalNumber")}
              id="personal_number"
              value={values.personal_number}
              onChange={handleChange}
              name="personal_number"
              placeholder={t("personalNumber")}
              error={
                errors.personal_number && touched.personal_number
                  ? errors.personal_number
                  : null
              }
            />
            <Input
              label={t("expired")}
              id="skanin"
              onChange={handleChange}
              name="skanin"
              placeholder={t("expired")}
              error={errors.skanin && touched.skanin ? errors.skanin : null}
            />
            <Input
              label={t("value")}
              id="value"
              onChange={handleChange}
              name="value"
              placeholder={t("value")}
              error={errors.value && touched.value ? errors.value : null}
            />
            <Input
              label="Business ID"
              id="business_id"
              onChange={handleChange}
              name="business_id"
              placeholder="Business ID"
              error={
                errors.business_id && touched.business_id
                  ? errors.business_id
                  : null
              }
            />
            <div className="flex justify-end">
              <Link to="/household">
                <button
                  type="button"
                  className="px-4 py-2 mr-2 bg-white text-[#28ABE2] rounded-md"
                >
                  {t("cancel")}
                </button>
              </Link>
              <button
                type="submit"
                className="px-12 py-2 bg-[#2FBFDE] text-white rounded-md"
              >
                {t("save")}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
