import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../../../store/slices/authSlice";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const dispatch = useDispatch();
  const { loading, message } = useSelector((state) => state.auth);
  const { t, i18n } = useTranslation();
  

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("invalidEmail"))
      .required(t("errorField")),
    password: Yup.string().required(t("errorField")),
  });

  return (
    <div className="relative flex flex-col justify-center min-h-screen overflow-hidden bg-main-bg">
      <div className="w-full p-6 m-auto lg:max-w-xl">
        <div className="w-full max-w-xs mx-auto" data-aos="zoom-in">
          <h1 className="text-3xl font-semibold text-center text-black-900 p-4">
            {t("welcomeWord")}
          </h1>
          <p className="text-center text-[14px]">{t("fillFields")} </p>
          {message && (
            <div
              class="p-4 my-4 text-sm text-red-800 rounded-lg bg-red-50"
              role="alert"
            >
              <span class="font-medium">Error!</span> {message}
            </div>
          )}
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              dispatch(userLogin(values));
            }}
          >
            {({ errors, touched, values, handleChange, handleSubmit }) => (
              <Form
                className="form-group custom-form mt-6"
                onSubmit={handleSubmit}
              >
                <div className="mb-2">
                  <label className="relative text-gray-400 focus-within:text-gray-600 block">
                    <p className="text-black text-sm pb-1">{t("user")}:</p>
                    <Field
                      type="email"
                      name="email"
                      placeholder={t("fillUser")}
                      className="form-input border rounded-md py-2 px-3 bg-white placeholder-gray-400 text-gray-500 text-sm appearance-none w-full block focus:outline-none focus:border-black outline-none"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </label>
                </div>
                <div className="mb-2">
                  <label className="relative text-gray-400 focus-within:text-gray-600 block">
                    <p className="text-black text-sm pb-1">{t("password")}:</p>
                    <Field
                      type="password"
                      name="password"
                      placeholder={t("fillPassword")}
                      className="form-input border rounded-md py-2 px-3 bg-white placeholder-gray-400 text-gray-500 text-sm appearance-none w-full block focus:outline-none focus:border-black outline-none"
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="text-red-500 text-sm"
                    />
                  </label>
                </div>
                <div className="mt-6">
                  <button
                    type="submit"
                    className="w-full pl-4 pt-3 pb-2.5 tracking-wide text-white text-sm transition-colors duration-200 transform bg-theme-color rounded-md hover:bg-hover-color focus:outline-none focus:bg-hover-color"
                    disabled={loading}
                  >
                    {loading ? t("inProgress") : t("loginWord") }
                  </button>
                </div>
                <Link to="/first">
                  <a className="text-[12px] text-center items-center text-theme-color mt-12">
                    {t("back")}
                  </a>
                </Link>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Login;
