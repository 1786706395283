import React, { useEffect } from "react";
import Path from "./routes/Path";
import { Sidebar, Navbar } from "./layouts";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AOS from "aos";
import "aos/dist/aos.css";
import './i18n';
import Loader from "./components/Loader/Loader";

const App = () => {
  const activeMenu = useSelector((state) => state.app.activeMenu);
  const screenSize = window.innerWidth;
  const { user, isAuthLoading } = useSelector((state) => state.auth);
  const location = useLocation();
  const isAuthPage =
    location.pathname === "/login" ||
    location.pathname === "/forgot-password" ||
    location.pathname === "/change-password" ||
    location.pathname === "/financial-card" ||
    location.pathname === "/my-bills" ||
    location.pathname === "/municipalities" ||
    location.pathname.startsWith("/municipalities/") ||
    location.pathname === "/search-bill" || 
    location.pathname === "/info" || 
    location.pathname === "/first";

  useEffect(() => {
    AOS.init();
  }, []);


  const mainContentStyles = {
    marginLeft: activeMenu && user ? "16rem" : "0",
    overflowX: "hidden",
  };


  return (
    <div>
     {isAuthLoading ? (<Loader loading={true} />) : (
      <div className="flex relative dark:bg-main-dark-bg">
        {activeMenu && user ? (
          <div
            className="w-64 fixed sidebar dark:bg-secondary-dark-bg  bg-white transition-height duration-500 ease-in-out "
            style={{
              borderRight: "2px solid rgb(241 241 241 / 51%)",
              zIndex: "9999",
            }}
          >
            <Sidebar screenSize={screenSize} />
          </div>
        ) : (
          ""
        )}
        <div
          className={`dark:bg-main-bg bg-white min-h-screen w-full transition-width duration-500 ease-in   ${
            activeMenu && !user ? "md:ml-64 " : "flex-2"
          } `}
          style={mainContentStyles}
        >
          {user && (
            <div className="fixed md:static bg-white dark:bg-main-dark-bg navbar w-full">
              <Navbar />
            </div>
          )}
          <ToastContainer
            position="bottom-left"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
          <div>{isAuthLoading ? <p>Loading...</p> : <Path />}</div>
        </div>
      </div>
      )}
    </div>
  );
};

export default App;
