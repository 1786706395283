import React from "react";

const DeleteModal = ({ isOpen, closeModal, handleDelete, question, actionTitle }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center top-0 z-50">
      <div className="fixed inset-0 bg-white-smoke opacity-75"/>
      <div className="modal-container bg-white w-1/3 p-10 rounded-lg shadow-lg z-50">
        <h2 className="text-lg font-semibold mb-3 text-blue-900">{question}</h2>
        <p className="mb-5 text-xs">Komento pse deshiron te anulosh.</p>
        <input className="text-xs border-b-[1px] w-full mb-5 outline-none" placeholder="Komento ketu..." />
        <div className="flex justify-start">
          <button
            onClick={closeModal}
            className="bg-gray-100 text-gray-800 hover:bg-gray-400 px-4 py-2 rounded-md mr-2"
          >
            Cancel
          </button>
          <button
            onClick={handleDelete}
            className="bg-[#28ABE2] text-white hover:bg-red-600 px-4 py-2 rounded-md"
          >
            {actionTitle}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
