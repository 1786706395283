import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeMenu: true,
  loader: null,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setActiveMenu: (state, action) => {
      state.activeMenu = action.payload;
    },
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
  },
});

export const { setActiveMenu, setLoader } = appSlice.actions;

export default appSlice.reducer;
