import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setActiveMenu } from "../../store/slices/appSlice";
import { AiOutlineMenu } from "react-icons/ai";
import { SearchBar, LanguageSwitcher } from "../../components";

const NavButton = ({  customFunc, icon, color, dotColor }) => (
  <button
    type="button"
    onClick={customFunc}
    style={{ color }}
    className="relative text-xl rounded-full p-3 hover:bg-light-gray"
  >
    <span
      style={{ background: dotColor }}
      className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
    />
    {icon}
  </button>
);

const Navbar = () => {
  const activeMenu = useSelector((state) => state.app.activeMenu);
  const dispatch = useDispatch();

  const handleCloseSideBar = () => {
    dispatch(setActiveMenu(!activeMenu));
  };

  return (
    <div className="flex justify-between p-2 md:mx-1 relative">
      <NavButton
        title="Menu"
        customFunc={handleCloseSideBar}
        color="gray"
        icon={<AiOutlineMenu />}
      />
      {/* <SearchBar /> */}
      <LanguageSwitcher />
    </div>
  );
};

export default Navbar;
