import React from "react";
import { useParams } from "react-router-dom";
import { tableDataDummy } from "../../data/dummyData";
import { Link } from "react-router-dom";

const MunicipalitiesInfo = () => {
  const { id } = useParams();
  const municipalities = tableDataDummy.find(
    (item) => item.id === parseInt(id, 20)
  );
  if (!municipalities) {
    return <div>Municipalities data not found</div>;
  }

  return (
    <div className="p-6  w-full">
      <h1 className="text-blue-900 font-bold py-4">Informatat e Komunes </h1>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400">Nr Klientit</p>
        <p>{municipalities.id}</p>
      </div>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400">Emri</p>
        <p>{municipalities.emri}</p>
      </div>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400">Vendodhja</p>
        <p>{municipalities.mbiemri}</p>
      </div>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400">Zona</p>
        <p>{municipalities.zona}</p>
      </div>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400">Nr</p>
        <p>{municipalities.personalNumber}</p>
      </div>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400">Adresa</p>
        <p>{municipalities.address}</p>
      </div>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400">Dera</p>
        <p>{municipalities.dera}</p>
      </div>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400">Nr.Parcela</p>
        <p>{municipalities.bill}</p>
      </div>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400">Zona </p>
        <p>{municipalities.zona}</p>
      </div>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400">Sipërfaqja</p>
        <p>{municipalities.siperfaqja}</p>
      </div>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400">Status</p>
        <p>{municipalities.status}</p>
      </div>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400">Data Regjistrimit</p>
        <p>{municipalities.date}</p>
      </div>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400">Koment</p>
        <p>{municipalities.clientNumber}</p>
      </div>
      <div className="flex justify-end py-4">
        <Link to="/municipalities">
          <button
            type="submit"
            className="px-12 py-2 bg-[#2FBFDE] text-white rounded-md mr-4"
          >
            Kthehu te komunat
          </button>
        </Link>
        <Link to="/">
          <button
            type="submit"
            className="px-12 py-2 bg-[#2FBFDE] text-white rounded-md"
          >
            Shko te komuna
          </button>
        </Link>
      </div>
    </div>
  );
};

export default MunicipalitiesInfo;
