import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSearchQuery } from "../../../store/slices/tableSlice";
import {
  SearchBarTable,
  Pagination,
  Button,
  DeleteModal,
} from "../../../components";
import { FaEdit, FaTrash } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";
import { getUsers, deleteUser } from "../../../store/slices/authSlice";
import { useTranslation } from "react-i18next";

const TableUsers = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.auth.data);
  const initialFilteredData = Array.isArray(users) ? users : [];
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const {t} = useTranslation();

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const [searchInput, setSearchInput] = useState("");

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    dispatch(updateSearchQuery(searchInput));
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = initialFilteredData.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalItems = users.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleDeleteModal = (item) => {
    setUserToDelete(item);
    setDeleteModalOpen(true);
  };

  const handleDeleteUser = () => {
    if (userToDelete && userToDelete.id) {
      dispatch(deleteUser(userToDelete.id));
      setDeleteModalOpen(false);
      setUserToDelete(null);
    }
  };

  return (
    <div className="p-3 mt-3">
      <div className="flex items-center justify-between pb-4 ">
        <h1 className="text-blue-900 font-semibold	">{t("usersTitle")}</h1>
        <div className="flex items-center justify-end bg-white dark:bg-gray-900">
          <SearchBarTable
            searchInput={searchInput}
            handleSearchInputChange={handleSearchInputChange}
            handleSearchSubmit={handleSearchSubmit}
            placeholder={t("search")}
          />
          <div className="flex">
            <Link to="/users/add-new-user">
              <Button className="text-white bg-blue-800 transition duration-300 ease-in-out hover:bg-gray-200 hover:text-blue-800">
                <MdDelete className="mr-1" /> {t("addUsers")}
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <div
        className="relative overflow-x-auto shadow-md sm:rounded-lg"
        style={{ borderRadius: 1 }}
      >
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-blue-800 font-light whitespace-nowrap	 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                ID
              </th>
              <th scope="col" className="px-6 py-3">
              {t("name")}
              </th>
              <th scope="col" className="px-6 py-3">
              {t("user")}
              </th>
              <th scope="col" className="px-6 py-3">
              {t("role")}
              </th>
              <th scope="col" className="px-6 py-3">
              {t("directory")}
              </th>
              <th scope="col" className="px-6 py-3">
              {t("personalNumber")}
              </th>
              <th scope="col" className="px-6 py-3">
              {t("position")}
              </th>
            </tr>
          </thead>
          <tbody className="text-xs">
            {currentItems.map((item) => (
              <tr key={item.id} className="border-b">
                <td className="px-6 py-4">{item.id}</td>
                <td className="px-6 py-4">{item.name}</td>
                <td className="px-6 py-4">{item.user}</td>
                <td className="px-6 py-4">{item.role}</td>
                <td className="px-6 py-4">{item.directory}</td>
                <td className="px-6 py-4">{item.personal_nr}</td>
                <td className="px-6 py-4">{item.position}</td>
                <td className="py-4 flex gap-2 cursor-pointer text-gray-400">
                  <Link to={`/users/add-new-user/${item.id}`}>
                    <div title="Edit">
                      <FaEdit className="w-[17px] h-[17px]" />
                    </div>
                  </Link> 
                  <div title="Delete" onClick={() => handleDeleteModal(item)}>
                    <FaTrash className="w-[17px] h-[17px]" />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
        className="flex items-center justify-center mt-4"
      />
      <DeleteModal
        isOpen={isDeleteModalOpen}
        closeModal={() => setDeleteModalOpen(false)}
        handleDelete={handleDeleteUser}
        question="Deshironi ta fshini userin ?"
        actionTitle="Delete"
      />
    </div>
  );
};

export default TableUsers;
