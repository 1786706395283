import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const navigate = useNavigate();

  const handleChangePassword = async (e) => {
    e.preventDefault();
    // Implement the logic for changing the password here
    // Handle the password change process and navigate to the appropriate page
    navigate("/login");
  };

  return (
    <div className="relative flex flex-col justify-center min-h-screen overflow-hidden bg-main-bg">
      <div className="w-full p-6 m-auto lg:max-w-xl">
        <div className="w-full max-w-xs mx-auto">
          <img
            className="mb-4 mx-auto"
            src="assets/images/logo.svg"
            alt="loginImages"
          />
          <h1 className="text-3xl font-semibold text-center text-black-900">
            Change Password
          </h1>
          <p className="text-center">Enter your current and new password.</p>
          <form
            className="form-group custom-form mt-6"
            onSubmit={handleChangePassword}
          >
            <div className="mb-2">
              <label className="relative text-gray-400 focus-within:text-gray-600 block">
                <p className="text-black text-sm pb-1">New password:</p>
                <input
                  type="password"
                  placeholder="Enter your new password"
                  className="form-input border rounded-md py-2 px-3 bg-white placeholder-gray-400 text-gray-500 text-sm appearance-none w-full block focus:outline-none focus:border-black"
                  required
                />
              </label>
            </div>
            <div className="mb-2">
              <label className="relative text-gray-400 focus-within:text-gray-600 block">
                <p className="text-black text-sm pb-1">Confirm New password:</p>
                <input
                  type="password"
                  placeholder="Confirm your new password"
                  className="form-input border rounded-md py-2 px-3 bg-white placeholder-gray-400 text-gray-500 text-sm appearance-none w-full block focus:outline-none focus:border-black"
                  required
                />
              </label>
            </div>
            <div className="mt-6">
              <button
                type="submit"
                className="w-full pl-4 pt-3 pb-2.5 tracking-wide text-white text-sm transition-colors duration-200 transform bg-theme-color rounded-md hover:bg-hover-color focus:outline-none focus:bg-hover-color"
              >
                Reset Password
              </button>
            </div>
          </form>
          <div className="flex justify-center gap-2 text-sm pt-5">
            <Link to="/login" className="text-blue-500 text-sm">
              Sign In
            </Link>
          </div>
        </div>
      </div>
      <img src="assets/images/loginImages.svg" alt="loginImages" />
    </div>
  );
};

export default ChangePassword;
