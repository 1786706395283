import React from "react";
import "./Pagination.css";

const Pagination = ({
  currentPage,
  totalPages,
  handlePageChange,
  className,
}) => {
  return (
    <div className={`pagination ${className}`}>
      <button
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="pagination-btn"
      >
        {"<"}
      </button>
      {totalPages <= 10 ? (
        Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            className={`pagination-btn ${
              currentPage === index + 1 ? "active-pagination-btn" : ""
            }`}
          >
            {index + 1}
          </button>
        ))
      ) : (
        <>
          {currentPage > 5 && (
            <button
              onClick={() => handlePageChange(1)}
              className="pagination-btn"
            >
              1
            </button>
          )}
          {currentPage > 5 && <span className="pagination-ellipsis">...</span>}
          {Array.from(
            {
              length: Math.min(totalPages - currentPage + 4, 9),
            },
            (_, index) => (
              <button
                key={currentPage + index - 4}
                onClick={() => handlePageChange(currentPage + index - 4)}
                className={`pagination-btn ${
                  currentPage === currentPage + index - 4
                    ? "active-pagination-btn"
                    : "no-active-pagination-btn"
                }`}
              >
                {currentPage + index - 4}
              </button>
            )
          )}
          {currentPage + 4 < totalPages && (
            <>
              <span className="pagination-ellipsis">...</span>
              <button
                onClick={() => handlePageChange(totalPages)}
                className="pagination-btn"
              >
                {totalPages}
              </button>
            </>
          )}
        </>
      )}
      <button
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="pagination-btn "
      >
        {">"}
      </button>
    </div>
  );
};

export default Pagination;
