import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateSearchQuery } from "../../../store/slices/tableSlice";
import { SearchBarTable, Pagination, Button } from "../../../components";
import {
  FaDollarSign,
  FaReceipt,
  FaMinus,
  FaEdit,
  FaTrash,
  FaSearch,
  FaFilter,
  FaPlus,
  FaParking,
} from "react-icons/fa";
import { RiUpload2Fill } from "react-icons/ri";
import { FiRefreshCcw } from "react-icons/fi";
import { tableDataDummy } from "../../../data/dummyData";
import { Link } from "react-router-dom";

const TableTotalReport = () => {
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    dispatch(updateSearchQuery(searchInput));
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const filteredData = tableDataDummy.filter((item) => {
    if (!selectedStartDate && !selectedEndDate) {
      return true;
    }

    const itemDate = new Date(item.date);

    if (selectedStartDate && selectedEndDate) {
      return (
        itemDate >= new Date(selectedStartDate) &&
        itemDate <= new Date(selectedEndDate)
      );
    } else if (selectedStartDate) {
      return itemDate >= new Date(selectedStartDate);
    } else if (selectedEndDate) {
      return itemDate <= new Date(selectedEndDate);
    }
  });

  const totalItems = filteredData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData?.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div className="p-3 mt-3">
      <h1 className="text-blue-900 font-semibold	">Raporti Total</h1>

      <div className="flex items-center justify-between pb-4 ">
        <div className="mt-4 flex">
          <Button className="text-white bg-blue-800 transition duration-300 ease-in-out hover:bg-gray-200 hover:text-blue-800">
            <FiRefreshCcw className="mr-1" /> Gjenero Raportin Total
          </Button>
          <Button className="text-white bg-blue-800 transition duration-300 ease-in-out hover:bg-gray-200 hover:text-blue-800">
            <FiRefreshCcw className="mr-1" /> Gjenero Raportin Prishtine
          </Button>
        </div>
        <div className="flex items-center justify-end bg-white dark:bg-gray-900">
          <SearchBarTable
            searchInput={searchInput}
            handleSearchInputChange={handleSearchInputChange}
            handleSearchSubmit={handleSearchSubmit}
          />
          <div className="flex">
            <Button className="text-blue-800 text-sm bg-gray-100">
              <RiUpload2Fill className="mx-2" />
            </Button>
          </div>
        </div>
      </div>
      <div
        className="relative overflow-x-auto shadow-md sm:rounded-lg"
        style={{ borderRadius: 1 }}
      >
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-blue-800 font-light whitespace-nowrap	 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Adresa
              </th>
              <th scope="col" className="px-6 py-3">
                Shuma e faturuar
              </th>
              <th scope="col" className="px-6 py-3">
                Shuma e inkasuar
              </th>
              <th scope="col" className="px-6 py-3">
                Perqindja %
              </th>
              <th scope="col" className="px-6 py-3"></th>
            </tr>
          </thead>
          <tbody className="text-xs">
            {currentItems.map((item) => (
              <tr key={item.id} className="border-b">
                <td className="px-6 py-4">{item.id}</td>
                <td className="px-6 py-4">
                  {item.emri} {item.mbiemri}
                </td>
                <td className="px-6 py-4">{item.personalNumber}</td>
                <td className="px-6 py-4">{item.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
        className="flex items-center justify-center mt-4"
      />
    </div>
  );
};

export default TableTotalReport;
