import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axiosInstance"; // Update the import path to match your axios instance configuration

const initialState = {
  startDate: "",
  endDate: "",
  userName: "",
  filteredData: [], 
};

export const fetchFilteredData = createAsyncThunk(
  "cashiersRaport/fetchFilteredData",
  async ({ startDate, endDate, userName }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `/debt_raports/filter?startDate=${startDate}&endDate=${endDate}&userName=${userName}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const cashierRaportSlice = createSlice({
  name: "cashierRaport",
  initialState,
  reducers: {
    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setUserName: (state, action) => {
      state.userName = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFilteredData.fulfilled, (state, action) => {
      state.filteredData = action.payload; 
    });
  },
});

export const { setStartDate, setEndDate, setUserName } =
  cashierRaportSlice.actions;
export default cashierRaportSlice.reducer;
