import React from "react";
import { useField } from "formik";

const Input = (props) => {
  const [field, meta] = useField(props);
  const error = meta.touched && meta.error ? meta.error : "";
  return (
    <div className="w-full flex space-x-2 items-center ">
      {props.label && (
        <label
          htmlFor={props.name}
          className={`block mb-2 text-sm font-medium w-[20%] ${
            error && "text-red-700"
          }`}
        >
          {props.label}
        </label>
      )}
      <div className="relative w-full">
        {props.as !== "textarea" ? (
          <input
            id={props.name}
            name={props.name}
            type={props.type}
            placeholder={props.placeholder}
            className={`
          relative border border-gray-300 p-1.5 rounded-lg outline-none w-full my-1 text-sm  ${
            props.classes
          }
            ${
              error &&
              "text-red-900 focus:ring-red-500 bg-red-50 border-red-500 placeholder-red-700 focus:border-red-500 "
            }
          `}
            {...field}
            value={props.value}
            onChange={props.onChange}
            disabled={props.disabled}
          />
        ) : (
          <textarea
            rows="4"
            id={props.name}
            name={props.name}
            placeholder={props.placeholder}
            className={`
            border border-gray-300 p-2.5 rounded-lg outline-none w-full my-1 text-sm max-h-[200px] ${
              props.classes
            }
            ${
              error &&
              "text-red-900 focus:ring-red-500 bg-red-50 border-red-500 placeholder-red-700 focus:border-red-500"
            }
          `}
            {...field}
          />
        )}

        {error && !props.hideError && (
          <p class=" absolute left-2 text-xs text-red-600">{error}</p>
        )}
      </div>
    </div>
  );
};

export default Input;
