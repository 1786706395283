import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchBusinessesById } from "../../store/slices/tablesInfoSlices/businessSlice";

const BusinessInfo = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const businesessData = useSelector((state) => state.businesses.data);

  console.log(businesessData);

  useEffect(() => {
    dispatch(fetchBusinessesById(id));
  }, [dispatch, id]);

  if (!businesessData) {
    return <div>Business not found</div>;
  }

  return (
    <div className="p-6  w-[70%]" key={businesessData.id}>
      <h1 className="text-blue-900 font-bold py-4 ">Biznesi</h1>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400 w-[20%]">Nr Klientit</p>
        <p>{businesessData.company_name}</p>
      </div>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400 w-[20%]">Emri</p>
        <p>{businesessData.name}</p>
      </div>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400 w-[20%]">Mbiemri</p>
        <p>{businesessData.surname}</p>
      </div>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400 w-[20%]">Emri i Prindit</p>
        <p>{businesessData.parent_name}</p>
      </div>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400 w-[20%]">Nr.Personal</p>
        <p>{businesessData.business_balance}</p>
      </div>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400 w-[20%]">Adresa</p>
        <p>{businesessData.entry}</p>
      </div>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400 w-[20%]">Dera</p>
        <p>{businesessData.total_business_collections_values}</p>
      </div>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400 w-[20%]">Nr.Parcela</p>
        <p>{businesessData.address}</p>
      </div>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400 w-[20%]">Zona</p>
        <p>{businesessData.residence}</p>
      </div>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400 w-[20%]">Sipërfaqja</p>
        <p>{businesessData.surface}</p>
      </div>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400 w-[20%]">Status</p>
        <p>{businesessData.deleted_at}</p>
      </div>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400 w-[20%]">Data Regjistrimit</p>
        <p>{businesessData.created_at}</p>
      </div>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400 w-[20%]">Koment</p>
        <p>{businesessData.comment}</p>
      </div>
      <div className="flex justify-end py-4">
        <Link to="/business">
          <button
            type="submit"
            className="px-12 py-2 bg-[#2FBFDE] text-white rounded-md"
          >
            Kthehu te lista
          </button>
        </Link>
      </div>
    </div>
  );
};

export default BusinessInfo;
