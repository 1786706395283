import React from "react";
import { TableFinancialCard } from "../../components";

const MyBills = () => {
  return (
    <div className="relative  min-h-screen bg-main-bg">
      <div className="   text-[22px] text-darkslategray-100 ">
        <div className="py-4 flex flex-col items-center justify-center">
          <div className="flex flex-col items-start justify-center gap-[24px]">
            <div className="flex flex-row items-center justify-start gap-[742px]">
              <div className="flex flex-row items-center justify-start gap-[49px]">
                <div className="flex flex-row items-start justify-start">
                  <img
                    className="relative w-[46px] h-14 object-cover"
                    alt=""
                    src="/assets/images/logo.svg"
                  />
                </div>
                <div className="relative tracking-[-0.01em] font-semibold inline-block w-[195.2px] shrink-0 text-[#3E506F]">
                  Faturat e Mia
                </div>
              </div>
              <div className="flex flex-row items-start justify-start gap-[12px] ">
                <div className="rounded bg-gray-100 w-[50px] h-[35px] flex flex-row items-center justify-center py-[6.521653652191162px] px-[5px] box-border gap-[5px] cursor-pointer hover:bg-gray-300">
                  <img
                    className="relative w-[18px] h-[18px]"
                    alt=""
                    src="/assets/images/export.svg"
                  />
                </div>
                <div className="rounded bg-gray-100 w-[50px] h-[35px] flex flex-row items-center justify-center py-[6.521653652191162px] px-[5px] box-border gap-[5px] cursor-pointer hover:bg-gray-300">
                  <img
                    className="relative w-[18px] h-[18px] overflow-hidden shrink-0"
                    alt=""
                    src="/assets/images/print.svg"
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start justify-start p-2.5 text-sm text-darkslategray-300">
              <div className="flex flex-row items-center justify-start gap-[21px]">
                <div className="flex flex-col items-start justify-start gap-[13px] text-gray-400 font-semibold">
                  <div className="relative leading-[20px] font-medium ">{`Emri Mbiemri `}</div>
                  <div className="relative leading-[20px] font-medium">
                    Adresa
                  </div>
                  <div className="relative leading-[20px] font-medium">
                    Nr Klientit
                  </div>
                  <div className="relative leading-[20px] font-medium">
                    Periudha
                  </div>
                  <div className="relative leading-[20px] font-medium">
                    Status
                  </div>
                </div>
                <div className="flex flex-col items-start justify-start gap-[13px] text-darkslategray-200">
                  <div className="relative leading-[20px] font-medium">{`John Doe`}</div>
                  <div className="relative leading-[20px] font-medium">
                    Sejdi Kryeziu
                  </div>
                  <div className="relative leading-[20px] font-medium">
                    2121210707
                  </div>
                  <div className="relative leading-[20px] font-medium">
                    21.07.2021 - 25.09.2023
                  </div>
                  <div className="relative leading-[20px] font-medium">
                    I Rregullt
                  </div>
                </div>
              </div>
            </div>
            <div className="relative w-[1153.4px] h-[365.72px] text-3xs">
              <TableFinancialCard />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyBills;
