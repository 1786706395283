import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSearchQuery } from "../../../store/slices/tableSlice";
import { SearchBarTable, Pagination, Button } from "../../../components";
import {
  FaDollarSign,
  FaReceipt,
  FaMinus,
  FaEdit,
  FaTrash,
  FaSearch,
  FaFilter,
  FaPlus,
  FaParking,
} from "react-icons/fa";
import { RiUpload2Fill } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";
import {
  fetchDebtReports,
  selectDebtReports,
} from "../../../store/slices/debtReportSlice";
import { useTranslation } from "react-i18next";

const TableLiabilitiesReport = () => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [searchInput, setSearchInput] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");

  const { data, loading, error } = useSelector(selectDebtReports);

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    dispatch(updateSearchQuery(searchInput));
  };

  useEffect(() => {
    dispatch(
      fetchDebtReports({
        created_at1: selectedStartDate,
        created_at2: selectedEndDate,
      })
    );
  }, [dispatch, selectedStartDate, selectedEndDate]);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const totalItems = data.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div className="p-3 mt-3">
      <h1 className="text-blue-900 font-semibold	">{t("oweRaport")}</h1>

      <div className="flex items-center justify-between pb-4 ">
        <div className="mt-4">
          <label className="text-sm text-blue-900">{t("from")}</label>
          <input
            value={selectedStartDate}
            onChange={(e) => setSelectedStartDate(e.target.value)}
            className="ml-2 p-2 bg-[#F7F7FB] rounded text-sm"
            type="date"
          />
          <label className="text-sm text-blue-900 ml-2">{t("to")}</label>
          <input
            value={selectedEndDate}
            onChange={(e) => setSelectedEndDate(e.target.value)}
            className="ml-2 p-2 bg-[#F7F7FB] rounded text-sm"
            type="date"
          />
        </div>
      </div>
      {data?.length > 0 && (
        <div>
          <div
            className="relative overflow-x-auto shadow-md sm:rounded-lg"
            style={{ borderRadius: 1 }}
          >
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-blue-800 font-light whitespace-nowrap	 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                  {t("clientNumber")}
                  </th>
                  <th scope="col" className="px-6 py-3">
                  {t("name")}
                  </th>
                  <th scope="col" className="px-6 py-3">
                  {t("personalNumber")}
                  </th>
                  <th scope="col" className="px-6 py-3">
                  {t("address")}
                  </th>
                  <th scope="col" className="px-6 py-3">
                  {t("owes")}
                  </th>
                  <th scope="col" className="px-6 py-3"></th>
                </tr>
              </thead>
              <tbody className="text-xs">
                {data.map((item) => (
                  <tr key={item.id} className="border-b">
                    <td className="px-6 py-4">{item.id}</td>
                    <td className="px-6 py-4">
                      {item.name} {item.surname}
                    </td>
                    <td className="px-6 py-4">{item.personal_number}</td>
                    <td className="px-6 py-4">{item.address}</td>
                    <td className="px-6 py-4">
                      {item.subscription === null
                        ? "Ska obligime"
                        : item.subscription}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            handlePageChange={handlePageChange}
            className="flex items-center justify-center mt-4"
          />
        </div>
      )}
    </div>
  );
};

export default TableLiabilitiesReport;
