import React from "react";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Input from "../../../components/Inputs/Input";
import { createHousehold } from "../../../store/slices/tablesInfoSlices/houseHoldsSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const validationSchema = Yup.object().shape({
  client_number: Yup.string().required("Required"),
  name: Yup.string().required("Required"),
  surname: Yup.string().required("Required"),
  parent_name: Yup.string().required("Required"),
  personal_number: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  door: Yup.string().required("Required"),
  parcel_number: Yup.string().required("Required"),
  zone: Yup.string().required("Required"),
  surface: Yup.string().required("Required"),
  status: Yup.string().required("Required"),
  comment: Yup.string().required("Required"),
});

export default function AddNewCitizen() {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialValues = {
    client_number: "",
    name: "",
    surname: "",
    parent_name: "",
    personal_number: "",
    address: "",
    door: "",
    parcel_number: "",
    zone: "",
    surface: "",
    status: "",
    comment: "",
  };

  return (
    <div className="p-6">
      <h1 className="py-6 text-blue-900 font-semibold">{t("addNewCitizen")}</h1>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          dispatch(createHousehold(values));
          navigate("/household");
        }}
      >
        {({ errors, touched, handleChange, values }) => (
          <Form className="space-y-4 text-xs">
            <Input
              label={t("clientNumber")}
              id="client_number"
              onChange={handleChange}
              name="client_number"
              placeholder={t("clientNumber")}
              error={
                errors.client_number && touched.client_number
                  ? errors.client_number
                  : null
              }
            />
            <Input
              label={t("name")}
              id="name"
              onChange={handleChange}
              name="name"
              placeholder={t("name")}
              error={errors.name && touched.name ? errors.name : null}
            />
            <Input
              label={t("surname")}
              id="surname"
              onChange={handleChange}
              name="surname"
              placeholder={t("surname")}
              error={errors.surname && touched.surname ? errors.surname : null}
            />
            <Input
              label={t("parentName")}
              id="parent_name"
              onChange={handleChange}
              name="parent_name"
              placeholder={t("parentName")}
              error={
                errors.parent_name && touched.parent_name
                  ? errors.parent_name
                  : null
              }
            />
            <Input
              label={t("personalNumber")}
              id="personal_number"
              value={values.personal_number}
              onChange={handleChange}
              name="personal_number"
              placeholder={t("personalNumber")}
              error={
                errors.personal_number && touched.personal_number
                  ? errors.personal_number
                  : null
              }
            />
            <Input
              label={t("address")}
              id="address"
              onChange={handleChange}
              name="address"
              placeholder={t("address")}
              error={errors.address && touched.address ? errors.address : null}
            />
            <Input
              label={t("door")}
              id="door"
              onChange={handleChange}
              name="door"
              placeholder={t("door")}
              error={errors.door && touched.door ? errors.door : null}
            />
            <Input
              label={t("parcelNumber")}
              id="parcel_number"
              onChange={handleChange}
              name="parcel_number"
              placeholder={t("parcelNumber")}
              error={
                errors.parcel_number && touched.parcel_number
                  ? errors.parcel_number
                  : null
              }
            />
            <Input
              label={t("zone")}
              id="zone"
              onChange={handleChange}
              name="zone"
              placeholder={t("zone")}
              error={errors.zone && touched.zone ? errors.zone : null}
            />
            <Input
              label={t("surface")}
              id="surface"
              onChange={handleChange}
              name="surface"
              placeholder={t("surface")}
              error={errors.surface && touched.surface ? errors.surface : null}
            />
            <Input
              label={t("status")}
              id="status"
              onChange={handleChange}
              name="status"
              placeholder={t("status")}
              error={errors.status && touched.status ? errors.status : null}
            />

            <Input
              as="textarea"
              label={t("comment")}
              id="comment"
              onChange={handleChange}
              name="comment"
              placeholder={t("comment")}
              error={errors.comment && touched.comment ? errors.comment : null}
            />

            <div className="flex justify-end">
              <Link to="/household">
                <button
                  type="button"
                  className="px-4 py-2 mr-2 bg-white text-[#28ABE2] rounded-md"
                >
                  {t("cancel")}
                </button>
              </Link>
              <button
                type="submit"
                className="px-12 py-2 bg-[#2FBFDE] text-white rounded-md"
              >
                {t("save")}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
