import { configureStore } from "@reduxjs/toolkit";
import { composeWithDevTools } from "redux-devtools-extension";
import appReducer from "./slices/appSlice";
import authReducer from "./slices/authSlice";
import householdsReducer from "./slices/tablesInfoSlices/houseHoldsSlice";
import businessesReducer from "./slices/tablesInfoSlices/businessSlice";
import collectionsReducer from "./slices/tablesInfoSlices/collectionsSlice";
import householdBillsReducer from "./slices/tablesInfoSlices/householdBillSlice";
import businessBillReducer from "./slices/tablesInfoSlices/businessBillSlice";
import debtReportReducer from "./slices/debtReportSlice";
import filtersReducer from "./slices/filtersSlice";
import cashiersRaportReducer from "./slices/cashiersRaportSlice";

const store = configureStore({
  reducer: {
    app: appReducer,
    auth: authReducer,
    households: householdsReducer,
    householdBills: householdBillsReducer,
    businessBills: businessBillReducer,
    collections: collectionsReducer,
    businesses: businessesReducer,
    debtReports: debtReportReducer,
    filters: filtersReducer,
    cashiersRaport: cashiersRaportReducer,
  },
  composeWithDevTools,
});

export default store;
