import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../api/axiosInstance";
import { toast } from "react-toastify";

const initialState = {
  data: [],
  status: "idle",
  error: null,
};

// Define async thunks for your API endpoints
export const createBusinessBill = createAsyncThunk(
  "businessBill/create",
  async (householdBillData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        "/businessBill/createBusinessBill",
        householdBillData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchAllBusinessBill = createAsyncThunk(
  "businessBill/fetchAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        "/businessBill/getAllBusinessBills"
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchBusinessBillById = createAsyncThunk(
  "businessBill/fetchById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `/businessBill/getBusinessBillById/${id}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchHouseholdBillById = createAsyncThunk(
  "businessBill/fetchById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `/businessBill/getHouseholdBillById/${id}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateHouseholdBill = createAsyncThunk(
  "businessBill/update",
  async ({ id, updatedHouseholdBill }, { rejectWithValue }) => {
    console.log("iddd", id);
    try {
      const response = await axiosInstance.put(
        `/businessBill/updateBusinessBill/${id}`,
        updatedHouseholdBill
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteBusinessBill = createAsyncThunk(
  "businessBill/delete",
  async (id, { rejectWithValue }) => {
    try {
      await axiosInstance.delete(`/businessBill/deleteHouseholdBill/${id}`);
      return id;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchSoftDeletedBills = createAsyncThunk(
  "businessBill/fetchSoftDeletedBills",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        "/businessBill/softDeletedHouseHoldBills"
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteSoftDeletedBillById = createAsyncThunk(
  "softDeletedBills/delete",
  async (id) => {
    try {
      const response = await axiosInstance.delete(
        `/businessBill/deleteSoftDeletedHouseholdBill/${id}`,
        {
          method: "DELETE",
        }
      );
      if (!response.ok) {
        throw new Error("Failed to delete household bill");
      }
      return id;
    } catch (error) {
      throw error;
    }
  }
);

export const undeleteBillById = createAsyncThunk(
  "softDeletedBills/undelete",
  async (id) => {
    try {
      const response = await axiosInstance.put(
        `/businessBill/undeleteBill/${id}`,
        {
          method: "PUT",
        }
      );
      if (!response.ok) {
        throw new Error("Failed to undelete bill");
      }
      return id;
    } catch (error) {
      throw error;
    }
  }
);

const businessBillsSlice = createSlice({
  name: "businessBills",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createBusinessBill.fulfilled, (state, action) => {
        state.data.push(action.payload.household);
        toast.success("Fatura u krijua me sukses!");
      })
      .addCase(fetchAllBusinessBill.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(fetchHouseholdBillById.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(updateHouseholdBill.fulfilled, (state, action) => {
        state.data = action.payload;
        toast.success("Fatura u editua me sukses!");
      })
      .addCase(deleteBusinessBill.fulfilled, (state, action) => {
        state.data = state.businessBill.filter(
          (bill) => bill.id !== action.payload
        );
        toast.success("Fatura u fshi me sukses!");
      })
      .addCase(fetchSoftDeletedBills.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSoftDeletedBills.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(fetchSoftDeletedBills.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteSoftDeletedBillById.fulfilled, (state, action) => {
        state.data = state.data.filter((bill) => bill.id !== action.payload);
        toast.success("Fatura u fshi me sukses!");
      })
      .addCase(undeleteBillById.fulfilled, (state, action) => {
        toast.success("Fatura u rikthye me sukses!");
      })
      .addMatcher(
        (action) => action.type.endsWith("/pending"),
        (state) => {
          state.status = "loading";
          state.error = null;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.status = "failed";
          state.error = action.payload;
        }
      );
  },
});

export default businessBillsSlice.reducer;
