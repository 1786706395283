import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Input from "../../../components/Inputs/Input";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchBusinessesById,
  updateBusiness,
} from "../../../store/slices/tablesInfoSlices/businessSlice";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  company_name: Yup.string().required("Required"),
  entry: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  residence: Yup.string().required("Required"),
  surface: Yup.string().required("Required"),
});

export default function EditBusiness() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const businessData = useSelector((state) => state.businesses.data);

  useEffect(() => {
    dispatch(fetchBusinessesById(id));
  }, [dispatch, id]);

  const initialValues = {
    name: businessData.name || "",
    company_name: businessData.company_name || "",
    entry: businessData.entry || "",
    address: businessData.address || "",
    residence: businessData.residence || "",
    surface: businessData.surface || "",
  };

  return (
    <div className="p-6">
      <h1 className="py-6 text-blue-900 font-semibold">Edito Biznesin</h1>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          dispatch(updateBusiness(id, values));
          navigate("/business")
        }}
        enableReinitialize
      >
        {({ errors, touched, handleChange, values }) => (
          <Form className="space-y-4 text-xs">
            <Input
              label="Emri"
              id="name"
              value={values.name}
              onChange={handleChange}
              name="name"
              placeholder="Emri"
              error={errors.name && touched.name ? errors.name : null}
            />
            <Input
              label="Emri i kompanise"
              id="company_name"
              value={values.company_name}
              onChange={handleChange}
              name="company_name"
              placeholder="Emri i kompanise"
              error={
                errors.company_name && touched.company_name
                  ? errors.company_name
                  : null
              }
            />
            <Input
              label="Hyrja"
              id="entry"
              value={values.entry}
              onChange={handleChange}
              name="entry"
              placeholder="Hyrja"
              error={errors.entry && touched.entry ? errors.entry : null}
            />
            <Input
              label="Adresa"
              id="address"
              value={values.address}
              onChange={handleChange}
              name="address"
              placeholder="Adresa"
              error={errors.address && touched.address ? errors.address : null}
            />
            <Input
              label="Residenca"
              id="residence"
              type="text"
              value={values.residence}
              onChange={handleChange}
              name="residence"
              placeholder="Residenca"
              error={
                errors.residence && touched.residence ? errors.residence : null
              }
            />
            <Input
              label="Sipërfaqja"
              id="surface"
              value={values.surface}
              onChange={handleChange}
              name="surface"
              placeholder="Sipërfaqja"
              error={errors.surface && touched.surface ? errors.surface : null}
            />
            <div className="flex justify-end">
              <Link to="/household">
                <button
                  type="button"
                  className="px-4 py-2 mr-2 bg-white text-[#28ABE2] rounded-md"
                >
                  Anulo
                </button>
              </Link>
              <button
                type="submit"
                className="px-12 py-2 bg-[#2FBFDE] text-white rounded-md"
              >
                Ruaj
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
