import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../api/axiosInstance';

const initialState = {
  filterType: '',
  input1: '',
  input2: '',
  data: [], 
  loading: false, 
  error: null,
};



export const fetchData = createAsyncThunk('filters/fetchData', async (filterData, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get('/businesses/filter', {
      params: {
        filterType: filterData.filterType,
        minValue: filterData.input1,
        maxValue: filterData.input2,
      },
    });

    return response.data;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setFilterType: (state, action) => {
      state.filterType = action.payload;
    },
    setInput1: (state, action) => {
      state.input1 = action.payload;
    },
    setInput2: (state, action) => {
      state.input2 = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setFilterType, setInput1, setInput2 } = filtersSlice.actions;

export default filtersSlice.reducer;
