import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Profile = () => {
  const user = useSelector((state) => state.auth.user);
  return (
    <div className="p-6  w-[70%]">
      <h1 className="text-blue-900 font-bold py-4">Profili im </h1>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400">Emri</p>
        <input placeholder="Emri" value={user?.name} />
      </div>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400">Mbiemri</p>
        <input placeholder="Mbiemri" value={user?.role} />
      </div>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400">Status</p>
        <input placeholder="Financat" value={user.directory} />
      </div>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400">Email</p>
        <input placeholder="email@example.com" value={user.email} />
      </div>
      {/* <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400">Password</p>
        <input placeholder="*****" value={user.password} type="password" />
      </div> */}
    </div>
  );
};

export default Profile;
