import React, { useState } from "react";

const GenerateBill = () => {
  const [filterType, setFilterType] = useState("Në Baz Te Metrave");

  const [placeholder1, setPlaceholder1] = useState("Data");

  const [placeholder2, setPlaceholder2] = useState("Data");

  const handleFilterTypeChange = (event) => {
    const selectedFilter = event.target.value;
    setFilterType(selectedFilter);

    if (selectedFilter === "date") {
      setPlaceholder1("Data");
      setPlaceholder2("Data");
    } else if (selectedFilter === "metric") {
      setPlaceholder1("Metra");
      setPlaceholder2("Metra");
    } else if (selectedFilter === "price") {
      setPlaceholder1("Cmimi");
      setPlaceholder2("Cmimi");
    }
  };

  return (
    <div className="m-6">
      <p className="text-[16px] font-semibold text-blue-900 mb-5 text-left">
        Gjenero faturen
      </p>
      <select
        value={filterType}
        onChange={handleFilterTypeChange}
        className="w-full px-2 py-1 border rounded-md"
      >
        <option value="date">Prishtina</option>
        <option value="metric">Prizreni</option>
        <option value="price">Gjakova</option>
      </select>
      <div className="flex items-center space-x-2 mt-4">
        <label htmlFor="input1" className="w-32">
          Nga :
        </label>
        <input
          type="date"
          id="input1"
          name="input1"
          className="flex-grow px-2 py-1 border rounded-md"
        />
      </div>
      <div className="flex items-center space-x-2 mt-2">
        <label htmlFor="input2" className="w-32">
          Deri :
        </label>
        <input
          type="date"
          id="input2"
          name="input2"
          className="flex-grow px-2 py-1 border rounded-md"
        />
      </div>
      <div className="flex justify-end mt-4">
        <button
          type="submit"
          className="px-12 py-2 bg-[#2FBFDE] text-white rounded-md"
        >
          Gjenero
        </button>
      </div>
    </div>
  );
};

export default GenerateBill;
