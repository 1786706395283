import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { SearchBar, LanguageSwitcher } from "../../components";
import { municipalitiesData } from "../../data/dummyData";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getMunicipalities } from "../../store/slices/authSlice";

export default function Municipalities() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const municipalities = useSelector((state) => state.app.municipalities);


  const filteredMunicipalities = municipalitiesData.filter((item) =>
    item.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleNavigateToMunicipality = (itemId) => {
    navigate(`/municipalities/${itemId}`);
  };

  const handleNavigateToMunicipalityInfo = (itemId) => {
    navigate(`/municipalities/info/${itemId}`);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    dispatch(getMunicipalities());
  }, [dispatch]);

  

  return (
    <div className="p-4">
      <div className="container">
        <div className="flex justify-between pb-4">
          <div className="municipalities-header-title">
            <h1 className="font-bold text-xl">Komunat e Kosoves</h1>
            <p className="text-xs text-gray-500 py-2">
              Shkruaj te dhenat dhe merr informatat rreth pagesave tuaja.
            </p>
          </div>
          <div className="flex ">
            <SearchBar onChange={handleSearchChange} />
          </div>
        </div>
        <div className="box-container flex flex-wrap justify-between">
          {filteredMunicipalities.map((item, index) => (
            <div
              key={item.id}
              className={`w-[23%] mb-6 p-4 flex gap-2 items-center bg-white-smoke rounded group relative cursor-pointer  ${
                index % 4 === 3 ? "mr-0" : "mr-6"
              }`}
              data-aos="zoom-in"
              onClick={
                item.active
                  ? () => handleNavigateToMunicipalityInfo(item.id)
                  : () => handleNavigateToMunicipality(item.id)
              }
            >
              <div className="box-logo">
                <img
                  className={`${item.active ? "" : "grayscale"}`}
                  src={item.image}
                  alt={item.title}
                />
              </div>
              <div className="box-info">
                <div className="flex items-center">
                  <h1
                    className={`${
                      item.active ? "font-bold" : "font-bold text-gray-400"
                    }`}
                  >
                    {item.title}
                  </h1>
                  {item.active === true && (
                    <div className="w-2 h-2 bg-green-500 rounded-full mx-2" />
                  )}
                </div>
                <p className="text-xs text-gray-500">{item.description}</p>
              </div>
              <div className="absolute z-[-1] -bottom-0 right-0 w-0 h-full bg-gradient-to-r from-gray-200 transition-all group-hover:w-full rounded" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
