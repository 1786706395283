import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux/es/hooks/useSelector";
import ReCAPTCHA from "react-google-recaptcha";
import { TableFinancialBillsCard } from "../../components";
import axiosInstance from "../../api/axiosInstance";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

const SearchBill = () => {
  const [personal_number, setPersonalNumber] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });
  const { t } = useTranslation();

  const handleSearch = async () => {
    setLoading(true);

    try {
      const response = await axiosInstance.get("/businessBill/billSearch", {
        params: {
          personalNumber: personal_number,
          name: name,
          surname: surname,
        },
      });

      if (response?.data === null) {
        setSearchResults("false");
      }
      setSearchResults(response.data);
      setTimeout(()=>{
        const element = document.getElementById("table");
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "end" });
        }
      },0)
    } catch (error) {
      console.error("Error searching for bills:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="relative flex flex-col justify-center min-h-screen overflow-hidden bg-main-bg">
        <div className="w-full flex items-center justify-center gap-4">
          {!isMobile && (
            <div data-aos="zoom-out-right">
              <img src="/assets/images/people.svg" alt="people" />
            </div>
          )}
          <div data-aos="zoom-out-left" className="p-4">
            <h2 className="text-2xl m-2 font-semibold text-center text-black-900">
              {t("searchBillM")}
            </h2>
            <p className="text-center">{t("searchBillInfoP")}</p>
            <form className="form-group custom-form mt-6">
              <div className="mb-2">
                <label className="relative text-gray-400 focus-within:text-gray-600 block">
                  <p className="text-black text-sm pb-1">
                    {t("personalNumber")}
                  </p>
                  <input
                    type="number"
                    value={personal_number}
                    onChange={(e) => setPersonalNumber(e.target.value)}
                    placeholder={t("personalNumber")}
                    className="form-input border rounded-md py-2 px-3 bg-white placeholder-gray-400 text-gray-500 text-sm appearance-none w-full block focus:outline-none focus:border-black"
                    required
                  />
                </label>
              </div>
              <div
                className={
                  isMobile
                    ? "flex flex-col w-full justify-between gap-4"
                    : `flex w-full justify-between gap-4`
                }
              >
                <div className="mb-2 w-full">
                  <label className="relative text-gray-400 focus-within:text-gray-600 block">
                    <p className="text-black text-sm pb-1">{t("name")}</p>
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder={t("name")}
                      className="form-input border rounded-md py-2 pl-3 bg-white placeholder-gray-400 text-gray-500 text-sm appearance-none w-full block focus:outline-none focus:border-black"
                      required
                    />
                  </label>
                </div>
                <div className="mb-2 w-full">
                  <label className="relative text-gray-400 focus-within:text-gray-600 block">
                    <p className="text-black text-sm pb-1">{t("surname")}</p>
                    <input
                      type="text"
                      value={surname}
                      onChange={(e) => setSurname(e.target.value)}
                      placeholder={t("personalNumber")}
                      className="form-input border rounded-md py-2 pl-3 bg-white placeholder-gray-400 text-gray-500 text-sm appearance-none w-full block focus:outline-none focus:border-black"
                      required
                    />
                  </label>
                </div>
              </div>
              <div className="flex justify-center items-center mt-4 w-full">
                <ReCAPTCHA sitekey="RECAPTCHA_SITE_KEY" />
              </div>
              <div className="mt-6">
                <button
                  type="submit"
                  onClick={handleSearch}
                  className="w-full pl-4 pt-3 pb-2.5 tracking-wide text-white text-sm transition-colors duration-200 transform bg-theme-color rounded-md hover:bg-hover-color focus:outline-none focus:bg-hover-color"
                  disabled={loading}
                >
                  {loading ? t("inProgress") : t("search")}
                </button>
              </div>
            </form>
          </div>
        </div>

        <div id="table">
          <TableFinancialBillsCard data={searchResults} />
        </div>
      </div>
    </>
  );
};

export default SearchBill;
