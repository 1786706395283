import React, { useRef, useState, useEffect } from "react";
import { TableFinancialCard } from "../../components";
import jsPDF from "jspdf";
import { useParams } from "react-router-dom";
import axiosInstance from "../../api/axiosInstance";

const FinancialCard = () => {
  const handleExportPDF = () => {
    const doc = new jsPDF();

    doc.text("Financial Card", 10, 10);

    const content = document.getElementById("pdf-content");
    doc.fromHTML(content, 10, 20);

    doc.save("financial_card.pdf");

    window.print();
  };

  const printRef = useRef();

  const handlePrint = () => {
    window.print();
  };

  const { id, name, surname } = useParams();
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  const handleSearch = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get("/businessBill/billSearch", {
        params: {
          personalNumber: id,
          name: name,
          surname: surname,
        },
      });
      setSearchResults(response.data);
    } catch (error) {
      console.error("Error searching for bills:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleSearch();
  },[])

  return (
    <div className="relative  min-h-screen bg-main-bg">
      <div ref={printRef} className="   text-[22px] text-darkslategray-100 ">
        <div className="py-4 flex flex-col items-center justify-center">
          <div className="flex flex-col items-start justify-center gap-[24px]">
            <div className="flex flex-row items-center justify-start gap-[742px]">
                <div className="relative tracking-[-0.01em] font-semibold inline-block w-[195.2px] shrink-0 text-[#3E506F] ml-2">
                  Kartela Financiare
                </div>
              <div className="flex flex-row items-start justify-end gap-[12px] ">
                <div
                  className="rounded bg-gray-100 w-[50px] h-[35px] flex flex-row items-center justify-center py-[6.521653652191162px]  box-border gap-[5px] ml-[150px] cursor-pointer hover-bg-gray-300"
                  onClick={handlePrint}
                >
                  <img
                    className="relative w-[18px] h-[18px] overflow-hidden shrink-0"
                    alt=""
                    src="/assets/images/print.svg"
                  />
                </div>
              </div>
            </div>
            <div className="relative flex flex-col items-start justify-start p-2.5 text-sm text-darkslategray-300">
              <div className="flex flex-row items-center justify-start gap-[21px]">
                <div className="flex flex-col items-start justify-start gap-[13px] text-gray-400 font-semibold">
                  <div className="relative leading-[20px] font-medium ">
                    Emri Mbiemri
                  </div>
                  <div className="relative leading-[20px] font-medium">
                    Nr Klientit
                  </div>
                  <div className="relative leading-[20px] font-medium">
                    Periudha
                  </div>
                  <div className="relative leading-[20px] font-medium">
                    Status
                  </div>
                </div>
                <div className="flex flex-col items-start justify-start gap-[13px] text-darkslategray-200">
                  <div className="relative leading-[20px] font-medium">
                    {name} {surname}
                  </div>
                  <div className="relative leading-[20px] font-medium">
                    {id}
                  </div>
                  <div className="relative leading-[20px] font-medium">
                    21.07.2021 - 25.09.2024
                  </div>
                  <div className="relative leading-[20px] font-medium">
                    I Rregullt
                  </div>
                </div>
              </div>
            </div>
            <div
              id="pdf-content"
              className="relative w-[1153.4px] h-[365.72px] text-3xs"
            >
              <TableFinancialCard data={searchResults} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinancialCard;
