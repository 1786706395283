import React from "react";
import { TableCollections } from "../../components";

const Collections = () => {
  return (
    <div className="m-4" data-aos="zoom-in-down">
      <TableCollections />
    </div>
  );
};

export default Collections;
