import React from "react";
import { BsCurrencyDollar } from "react-icons/bs";
import {
  AreasReportChart,
  CashiersReportChart,
  LiabilitiesReportChart,
  MonthlyReportChart,
  TotalReportChart,
} from "../../components";

const Dashboard = () => {
  const chartData = [
    { month: "1", julyValue: 100, augustValue: 120 },
    { month: "2", julyValue: 120, augustValue: 130 },
    { month: "3", julyValue: 80, augustValue: 130 },
    { month: "4", julyValue: 10, augustValue: 130 },
    { month: "5", julyValue: 120, augustValue: 130 },
    { month: "6", julyValue: 120, augustValue: 130 },
    { month: "7", julyValue: 120, augustValue: 130 },
    { month: "8", julyValue: 120, augustValue: 130 },
    { month: "9", julyValue: 120, augustValue: 130 },
    { month: "10", julyValue: 120, augustValue: 130 },
    { month: "11", julyValue: 120, augustValue: 130 },
    { month: "12", julyValue: 120, augustValue: 130 },
  ];

  const totalData = [
    { category: "Faturimi", value: 50 },
    { category: "Inkasimi", value: 30 },
    { category: "Perqindja", value: 20 },
  ];

  const areaData = [
    { label: "Shuma e faturuar", value: 50, color: "#3C4D9F" },
    { label: "Shuma e inkasuar", value: 30, color: "#FEC101" },
    { label: "Perqindja", value: 20, color: "#F2711D" },
  ];

  const income = 2000;
  const expense = 1500;

  const transactions = [
    { description: "Transaction 1", amount: 100 },
    { description: "Transaction 2", amount: 50 },
    { description: "Transaction 3", amount: 200 },
  ];

  return (
    <div className="m-4" data-aos="zoom-in-down">
      <p className="text-xl my-7 mx-4 text-blue-900 font-semibold"> Dashboard</p>

      <div className="flex-column w-full">
        <div className="flex ">
          <div className="w-6/12">
            <MonthlyReportChart data={chartData} />
          </div>
          <div className="w-6/12">
            <TotalReportChart data={totalData} />
          </div>
        </div>
        <div className="flex">
          <div className="w-3/12">
            <AreasReportChart data={areaData} />
          </div>
          <div className="w-3/12">
            <CashiersReportChart
              income={income}
              expense={expense}
              transactions={transactions}
            />
          </div>
          <div className="w-6/12">
            <LiabilitiesReportChart />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
