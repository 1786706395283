import React from "react";
import { Avatar, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function AreasReportChart() {
  const {t} = useTranslation();
  return (
    <div className="p-3">
      <div className="">
        <h1 className="text-sm p-2">{t("oweRaport")}</h1>
        <p className="text-xs p-2">Adipiscing elit, sed do eiusmod tempor</p>
      </div>
      <div className="flex justify-between items-center mt-4 ">
        <div className="relative top-[130px] left-[50px]">
          <Avatar
            sx={{
              width: 100,
              height: 100,
              backgroundColor: "#2fbfde7a",
              color: "white",
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            92%
          </Avatar>
          <div
            className="absolute"
            style={{
              width: "116px",
              height: "116px",
              border: "10px double #C6E8F0",
              borderRadius: "50%",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          ></div>
        </div>
        <div className="relative top-[80px] right-[30px]">
          <Avatar
            sx={{
              width: 80,
              height: 80,
              backgroundColor: "#6463d6ab",
              position: "absolute",
              color: "white",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            80%
          </Avatar>
          <div
            className="absolute"
            style={{
              width: "100px",
              height: "100px",
              border: "10px double rgb(187, 181, 226)",
              borderRadius: "50%",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          ></div>
        </div>
        <div className="relative top-[100px] right-[100px]">
          <Avatar
            sx={{
              width: 120,
              height: 120,
              backgroundColor: "#5eba47ad",
              position: "absolute",
              color: "white",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            85%
          </Avatar>
          <div
            className="absolute"
            style={{
              width: "140px",
              height: "140px",
              border: "10px  double rgb(168 214 156)",
              borderRadius: "50%",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}
