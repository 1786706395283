import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Input from "../../../components/Inputs/Input";
import { createHousehold } from "../../../store/slices/tablesInfoSlices/houseHoldsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  updateHouseholdBill,
  fetchHouseholdBillById,
} from "./../../../store/slices/tablesInfoSlices/householdBillSlice";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  surname: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
  personal_number: Yup.string().required("Required"),
  skanin: Yup.string().required("Required"),
  value: Yup.string().required("Required"),
  household_id: Yup.string().required("Required"),
});

export default function EditBill() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const householdBillsData = useSelector((state) => state.householdBills.data);

  useEffect(() => {
    dispatch(fetchHouseholdBillById(id));
  }, [dispatch, id]);

  const initialValues = {
    name: householdBillsData.name || "",
    surname: householdBillsData.surname || "",
    description: householdBillsData.description || "",
    personal_number: householdBillsData.personal_number || "",
    skanin: householdBillsData.skanin || "",
    value: householdBillsData.value || "",
    household_id: householdBillsData.household_id || "",
  };

  return (
    <div className="p-6">
      <h1 className="py-6 text-blue-900 font-semibold">Edito Faturen</h1>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          dispatch(updateHouseholdBill(id, values));
          navigate("/household")
        }}
        enableReinitialize
      >
        {({ errors, touched, handleChange, values }) => (
          <Form className="space-y-4 text-xs">
            <Input
              label="Emri"
              id="name"
              value={values.name}
              onChange={handleChange}
              name="name"
              placeholder="Emri"
              error={errors.name && touched.name ? errors.name : null}
            />
            <Input
              label="Mbiemri"
              id="surname"
              value={values.surname}
              onChange={handleChange}
              name="surname"
              placeholder="Mbiemri"
              error={errors.surname && touched.surname ? errors.surname : null}
            />
            <Input
              label="Description"
              id="description"
              value={values.description}
              onChange={handleChange}
              name="description"
              placeholder="Description"
              error={
                errors.description && touched.description
                  ? errors.description
                  : null
              }
            />
            <Input
              label="Nr. Personal"
              id="personal_number"
              value={values.personal_number}
              onChange={handleChange}
              name="personal_number"
              placeholder="Nr. Personal"
              error={
                errors.personal_number && touched.personal_number
                  ? errors.personal_number
                  : null
              }
            />
            <Input
              label="Skanin"
              id="skanin"
              type="date"
              value={values.skanin}
              onChange={handleChange}
              name="skanin"
              placeholder="Skanin"
              error={errors.skanin && touched.skanin ? errors.skanin : null}
            />
            <Input
              label="Vlera"
              id="value"
              value={values.value}
              onChange={handleChange}
              name="value"
              placeholder="Vlera"
              error={errors.value && touched.value ? errors.value : null}
            />
            <Input
              label="Numri i pronës"
              id="household_id"
              value={values.household_id}
              onChange={handleChange}
              name="household_id"
              placeholder="Numri i pronës"
              error={
                errors.household_id && touched.household_id
                  ? errors.household_id
                  : null
              }
            />
            <div className="flex justify-end">
              <Link to="/household">
                <button
                  type="button"
                  className="px-4 py-2 mr-2 bg-white text-[#28ABE2] rounded-md"
                >
                  Anulo
                </button>
              </Link>
              <button
                type="submit"
                className="px-12 py-2 bg-[#2FBFDE] text-white rounded-md"
              >
                Ruaj
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
