import React from "react";
import { BsSearch } from "react-icons/bs";

const SearchBar = ({onChange}) => {
  return (
    <div className="flex items-center gap-2 relative rounded-full w-full bg-gray-100 pl-5 text-gray-500">
      <BsSearch />
      <input
        type="text"
        placeholder="Search..."
        className="w-full bg-transparent outline-none placeholder-gray-500 text-gray-500 text-sm"
        onChange={onChange}
      />
    </div>
  );
};

export default SearchBar;
