import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setStartDate,
  setEndDate,
  setUserName,
  fetchFilteredData,
} from "../../../store/slices/cashiersRaportSlice";
import { getUsers } from "../../../store/slices/authSlice";
import Table from "../Table/Table";
import { useTranslation } from "react-i18next";

const FiltersCashiersReport = () => {
  const dispatch = useDispatch();
  const startDate = useSelector((state) => state.cashiersRaport.startDate);
  const endDate = useSelector((state) => state.cashiersRaport.endDate);
  const userName = useSelector((state) => state.cashiersRaport.userName);
  const filteredData = useSelector(
    (state) => state.cashiersRaport.filteredData
  );

  const user = useSelector((state) => state.auth.data);

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  useEffect(() => {
    if (user && user.length > 0 && !userName) {
      dispatch(setUserName(user[0].name));
    }
  }, [user, userName, dispatch]);

  const userOptions = Array.isArray(user)
    ? user.map((userData) => (
        <option key={userData.id} value={userData.name}>
          {userData.name}
        </option>
      ))
    : null;

  const handleStartDateChange = (event) => {
    dispatch(setStartDate(event.target.value));
  };

  const handleEndDateChange = (event) => {
    dispatch(setEndDate(event.target.value));
  };

  const handleUserNameChange = (event) => {
    dispatch(setUserName(event.target.value));
  };

  const handleSearch = () => {
    dispatch(fetchFilteredData({ startDate, endDate, userName }));
  };

  const { t } = useTranslation();

  const columns = [
    { key: "id", label: "Nr i ID" },
    { key: "name", label: "Emri" },
    { key: "surname", label: "Mbiemri" },
    { key: "type", label: "Tipi" },
    { key: "total_bill_value", label: "Vlera Totale e Faturave €" },
  ];

  return (
    <div className="m-6">
      <p className="text-[16px] font-semibold text-blue-900 mb-5 text-left">
        {t("cashiersRaport")}
      </p>
      <div className="flex items-center space-x-2 mt-4">
        <label htmlFor="input1" className="w-32">
          {t("selectUser")}:
        </label>
        <select
          value={userName}
          onChange={handleUserNameChange}
          className="flex-grow px-2 py-1 border rounded-md"
        >
          {userOptions}
        </select>
      </div>
      <div className="flex items-center space-x-2 mt-4">
        <label htmlFor="startDate" className="w-32">
        {t("from")}:
        </label>
        <input
          type="date"
          id="startDate"
          name="startDate"
          value={startDate}
          onChange={handleStartDateChange}
          className="flex-grow px-2 py-1 border rounded-md"
        />
      </div>
      <div className="flex items-center space-x-2 mt-2">
        <label htmlFor="endDate" className="w-32">
        {t("to")}:
        </label>
        <input
          type="date"
          id="endDate"
          name="endDate"
          value={endDate}
          onChange={handleEndDateChange}
          className="flex-grow px-2 py-1 border rounded-md"
        />
      </div>
      <div className="flex justify-end mt-4">
        <button
          type="button"
          onClick={handleSearch}
          className="px-12 py-2 bg-[#2FBFDE] text-white rounded-md"
        >
          {t("search")}
        </button>
      </div>
      <div>
        {filteredData?.length > 0 && (
          <Table data={filteredData} columns={columns} />
        )}
        {filteredData?.length === 0 && (
          <p className="text-red-700">{t("noData")}</p>
        )}
      </div>
    </div>
  );
};

export default FiltersCashiersReport;
