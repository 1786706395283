import React, { useState } from "react";
import {
  setFilterType,
  fetchData,
  setInput1,
  setInput2,
} from "../../store/slices/filtersSlice";
import { useSelector, useDispatch } from "react-redux";
import Table from "../../components/Tables/Table/Table";
import { useTranslation } from "react-i18next";

const Filters = () => {
  const dispatch = useDispatch();
  const [selectedFilter, setSelectedFilter] = useState(0);

  const input1 = useSelector((state) => state.filters.input1);
  const input2 = useSelector((state) => state.filters.input2);
  const filtersData = useSelector((state) => state.filters.data);
  const loading = useSelector((state) => state.filters.loading);

  const handleFilterTypeChange = (event) => {
    const selectedValue = parseInt(event.target.value);
    setSelectedFilter(selectedValue);
  };

  const handleSearch = () => {
    dispatch(fetchData({ filterType: selectedFilter, input1, input2 }));
  };

  const {t} = useTranslation();

  const columns = [
    { key: "id", label: "ID" },
    { key: "name", label: t("name") },
    { key: "surname", label: t("surname") },
    { key: "type", label: t("type") },
    { key: "total_bill_value", label: t("total") },
  ];

  return (
    <div className="m-6">
      <p className="text-[16px] font-semibold text-blue-900 mb-5 text-left">
        {t("filters")}
      </p>
      <select
        value={selectedFilter}
        onChange={handleFilterTypeChange}
        className="w-full px-2 py-1 border rounded-md"
      >
        <option value={0}>{t("m")}</option>
        <option value={1}>{t("price")}</option>
      </select>
      <div className="flex items-center space-x-2 mt-4">
        <label htmlFor="input1" className="w-32">
        {t("from")} :
        </label>
        <input
          type="text"
          id="input1"
          name="input1"
          placeholder="Nga"
          className="flex-grow px-2 py-1 border rounded-md"
          value={input1}
          onChange={(event) => dispatch(setInput1(event.target.value))}
        />
      </div>
      <div className="flex items-center space-x-2 mt-2">
        <label htmlFor="input2" className="w-32">
        {t("to")} :
        </label>
        <input
          type="text"
          id="input2"
          name="input2"
          placeholder="Deri"
          className="flex-grow px-2 py-1 border rounded-md"
          value={input2}
          onChange={(event) => dispatch(setInput2(event.target.value))}
        />
      </div>
      <div className="flex justify-end mt-4">
        <button
          type="submit"
          className="px-12 py-2 bg-[#2FBFDE] text-white rounded-md"
          onClick={handleSearch}
        >
          {t("search")}
        </button>
      </div>
      <div className="py-4">
        {filtersData.length > 0 ? (
          <Table data={filtersData} columns={columns} />
        ) : (
          <p>{t("noData")}</p>
        )}
      </div>
    </div>
  );
};

export default Filters;
