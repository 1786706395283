import React from "react";
import { Link } from "react-router-dom";
import { Paper, Typography } from "@mui/material";
import { PieChart, Pie, Tooltip, Legend, Cell } from "recharts";
import { useTranslation } from "react-i18next";

export default function TotalReportChart({ data }) {
  const COLORS = ["#5A6ACF", "#8593ED", "#C7CEFF"];
  const {t} = useTranslation();

  return (
    <div elevation={3} className="px-4 border-b-1">
      <div className="flex-column">
        <div className="flex justify-between items-center">
          <Typography variant="h6" gutterBottom>
          {t("totalRaport")} 
          </Typography>
          <Link to="/total">
            <button className="rounded-[4px] bg-gray-100 p-1 text-blue-900 font-medium border-[1px] text-xs">
            {t("goToRaport")} 
            </button>
          </Link>
        </div>
        <div className="text-xs">
          <p className="py-2">{t("payments")}  1-30 Gusht, 2023</p>
        </div>
      </div>
      <PieChart width={650} height={332}>
        <Pie
          data={data}
          dataKey="value"
          nameKey="category"
          cx="50%"
          cy="50%"
          outerRadius={80}
          fill="#8884d8"
          label
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
        <Legend />
      </PieChart>
    </div>
  );
}
