import React from "react";
import { TableUsers } from "../../components";

const Users = () => {
  return (
    <div className="m-4" data-aos="zoom-in-down">
      <TableUsers />
    </div>
  );
};

export default Users;
