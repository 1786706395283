import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getCurrentDate } from "../../../utils/date";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Input from "../../../components/Inputs/Input";
import { useSelector, useDispatch } from "react-redux";
import { fetchHouseholds } from "../../../store/slices/tablesInfoSlices/houseHoldsSlice";
import { createCollectionHousehold } from "../../../store/slices/tablesInfoSlices/collectionsSlice";
import { fetchHouseholdBillById } from "../../../store/slices/tablesInfoSlices/householdBillSlice";

const validationSchema = Yup.object().shape({});

export default function CollectionUpdateBill() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const households = useSelector((state) => state.households.data);
  const user = useSelector((state) => state.auth.user);
  const collectionInfo = households?.find(
    (item) => item.id === parseInt(id, 10)
  );

  const householdBillsData = useSelector((state) => state.householdBills.data);
  const houseHoldBillsArrayData = Array.isArray(householdBillsData)
    ? householdBillsData
    : [];

  console.log("houseeee", householdBillsData);
  const name = householdBillsData?.name;
  const surname = householdBillsData?.surname;
  const [collectionValue, setCollectionValue] = useState(null);
  const previousOwe = householdBillsData?.value;
  const [paymentType, setPaymentType] = useState("Komune");

  const filteredHouseholdBills = id
    ? houseHoldBillsArrayData?.filter(
        (item) => item?.household_id === parseInt(id)
      )
    : houseHoldBillsArrayData;

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const totalItems = filteredHouseholdBills.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = Array.isArray(filteredHouseholdBills)
    ? filteredHouseholdBills.slice(indexOfFirstItem, indexOfLastItem)
    : [];

  useEffect(() => {
    dispatch(fetchHouseholdBillById(id));
  }, [dispatch, id]);

  const calculatedTotal = () => {
    return previousOwe - collectionValue;
  };

  const handlePaymentType = () => {
    if (collectionValue > 10) {
      return setPaymentType("Banke");
    } else {
      return setPaymentType("Komune");
    }
  };

  useEffect(() => {
    calculatedTotal();
    handlePaymentType();
  }, [collectionValue]);

  const handleSubmit = (values) => {
    dispatch(createCollectionHousehold(values));
    navigate("/household");
  };

  return (
    <div className="p-6">
      <h1 className="py-6 text-blue-900 font-semibold">
        Shto inkasim për këtë faturë
      </h1>
      <Formik
        initialValues={{
          householdBill_id: householdBillsData?.id || "",
          value: collectionValue || "",
          type: paymentType || "",
          user_id: user?.id || "",
          tipifat: "Tipifat",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnBlur
        enableReinitialize
      >
        {({ errors, touched, values, setFieldValue }) => (
          <Form className="space-y-4 text-xs">
              <Input
              label="ID"
              id="id"
              name="id"
              placeholder="Id e fatures"
              value={householdBillsData?.id}
              disabled
            />
            <Input
              label="Emri"
              id="name"
              name="name"
              placeholder="Emri"
              value={name}
              disabled={true}
            />
            <Input
              label="Mbiemri"
              id="surname"
              name="surname"
              value={surname}
              placeholder="Mbiemri"
              error={errors.surname && touched.surname ? errors.surname : null}
              disabled
            />
            <Input
              label="Vlera"
              id="value"
              name="value"
              type="number"
              placeholder="Vlera"
              value={collectionValue}
              onChange={(e) => setCollectionValue(parseFloat(e.target.value))}
              error={
                errors.collectionValue && touched.collectionValue
                  ? errors.collectionValue
                  : null
              }
            />
            <Input
              label="Data"
              id="data"
              name="data"
              value={getCurrentDate()}
              placeholder="dd/mm/yyyy"
              disabled
            />
            <Field
              name="paymentType"
              render={({ field }) => (
                <Input
                  label="Tipi"
                  id="type"
                  name="type"
                  value={paymentType}
                  placeholder="Tipi"
                  disabled
                />
              )}
            />
            <Input
              label="Borgji Paraprak"
              id="previousOwe"
              name="previousOwe"
              placeholder="Borgji Paraprak"
              value={previousOwe}
              disabled
            />
            <Input
              label="Totali"
              id="totali"
              name="totali"
              value={calculatedTotal() || "0"}
              placeholder="Shuma Totale"
              disabled
            />
            <div className="flex justify-end">
              <Link to="/collection">
                <button
                  type="button"
                  className="px-4 py-2 mr-2 bg-white text-[#28ABE2] rounded-md"
                >
                  Anulo
                </button>
              </Link>
              <button
                type="submit"
                className="px-12 py-2 bg-[#2FBFDE] text-white rounded-md"
              >
                Ruaj
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
