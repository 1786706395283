import React, { useRef } from "react";
import { HiOutlineDocumentText } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import BillInvoice from "../../../pages/Forms/Bills/BillInvoice";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { fetchHouseholdBillById } from "../../../store/slices/tablesInfoSlices/householdBillSlice";
import { savePDF } from "@progress/kendo-react-pdf";
import { useTranslation } from "react-i18next";

const TableFinancialBillsCard = ({ data }) => {
  const navigate = useNavigate();
  const [billData, setBillData] = useState({});
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleNavigateBill = () => {
    navigate("/financial-card");
  };

  const componentRef = useRef();
  const generatePDF = () => {
    savePDF(
      componentRef.current,
      {
        title: `Order `,
        fileName: `Order `,
        paperSize: "A4",
      },
      () => setBillData(null)
    );
  };
  const handleFetchBillData = async (itemId) => {
    try {
      const response = await dispatch(fetchHouseholdBillById(itemId));
      if (response.payload) {
        setBillData(response.payload);
      }
    } catch (error) {
      console.error("Error fetching bill data:", error);
    } finally {
      generatePDF();
    }
  };

  return (
    <>
      {data?.bills?.length > 0 ? (
        <div className="px-12 py-4 mt-3">
          <div className="flex justify-between mb-2">
            <h1 className="text-blue-900 font-semibold">{t("yourCard")}</h1>
            {/* <HiOutlineDocumentText
          className="cursor-pointer text-gray-400"
          onClick={handleNavigateBill}
        /> */}
          </div>
          <div className="pb-4 text-sm text-gray-500">
            {data?.bills && data.bills.length > 0 ? (
              <>
                <div className="flex gap-2">
                  <p>{t("name")}:</p>
                  <p>
                    {data.bills[0].name} {data.bills[0].surname}
                  </p>
                </div>
                <div className="flex gap-2">
                  <p>{t("clientNumber")}:</p>
                  <p>{data.bills[0].personal_number}</p>
                </div>
              </>
            ) : (
              <p>{t("noData")}</p>
            )}
          </div>
          <div
            className="relative overflow-x-auto shadow-md sm:rounded-lg"
            style={{ borderRadius: 1 }}
          >
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-blue-800 font-light whitespace-nowrap	 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                      {t("billNumber")} 
                  </th>
                  <th scope="col" className="px-6 py-3">
                    {t("date")}
                  </th>
                  <th scope="col" className="px-6 py-3">
                    {t("description")}
                  </th>
                  <th scope="col" className="px-6 py-3">
                    {t("credit")} €
                  </th>
                  <th scope="col" className="px-6 py-3">
                    {t("debit")} €
                  </th>
                  <th scope="col" className="px-6 py-3">
                    {t("oweLeft")} €
                  </th>
                  <th scope="col" className=" py-3">
                    {t("bill")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.bills?.map((bill, index) => {
                  let remainingBalance = bill.value; 
                  return (
                    <>
                      <tr key={index} className="border-b">
                        <td className="px-6 py-4">{bill.household_bill_id}</td>
                        <td className="px-6 py-4">
                          {new Date(bill.created_at).toLocaleDateString()}
                        </td>
                        <td className="px-6 py-4">Faturë</td>
                        <td className="px-6 py-4">{bill.value}</td>
                        <td className="px-6 py-4">
                          
                        </td>
                        <td className="px-6 py-4"></td>
                        <td
                          className="py-4 flex items-center gap-2 cursor-pointer text-blue-400"
                          onClick={() =>
                            handleFetchBillData(bill.household_bill_id)
                          }
                        >
                          <HiOutlineDocumentText />
                          {t("printBill")}
                        </td>
                      </tr>
                      {bill.collections.length > 0  && (
                    <>
                    {bill.collections.map((collection, collectionIndex) => {
                        remainingBalance -= collection.collection_value;
            
                        if (collection.collection_value != null) {
                            return (
                                <tr
                                    key={`${index}-collection-${collectionIndex}`}
                                    className="border-b"
                                >
                                    <td className="px-6 py-4"></td>
                                    <td className="px-6 py-4"> {new Date(collection.collectionDate).toLocaleDateString()}</td>
                                    <td className="px-6 py-4">Pagesë</td>
                                    <td className="px-6 py-4"></td>
                                    <td className="px-6 py-4">
                                        {collection.collection_value}
                                    </td>
                                    <td className="px-6 py-4">
                                        {remainingBalance}
                                    </td>
                                    <td></td>
                                </tr>
                            );
                        } else {
                            return null; 
                        }
                    })}
                </>
                      )}
                    </>
                  );
                })}
                {data?.bills && data.bills.length > 0 ? (
                  <tr className="border-b text-black">
                    <td></td>
                    <td></td>
                    <td className="px-6 py-4 font-semibold">{t("total")}:</td>
                    <td className="px-6 py-4 font-semibold">
                      {data?.totals?.total_values}
                    </td>
                    <td className="px-6 py-4 font-semibold">
                      {data?.totals?.total_collection_values}
                    </td>
                    <td className="px-6 py-4 font-semibold">
                      {data?.totals?.total_remaining_balance}
                    </td>{" "}
                    <td></td> 
                  </tr>
                ) : (
                  <tr>
                    <td colSpan="8">{t("noData")}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div style={{ height: 0, overflow: "hidden" }}>
            <BillInvoice reff={componentRef} billData={billData} />
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default TableFinancialBillsCard;
