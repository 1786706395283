export const tableDataDummy = [
    {
      id: 1,
      clientNumber: "123456",
      emri: "Komuna",
      mbiemri: "Prishtine",
      prindi: "John Doe Sr.",
      personalNumber: "123456789",
      address:"Ismet Krasniqi",
      dera:"k-333",
      parcela:"P-121212",
      zona:"Prishtine",
      siperfaqja:"100m2",
      status:"I rregullt",
      bill:"12345678901",
      date:"11.03.2023",
      value:"11.03",
      tipi:"Bank",
      tipifat:"Qytetari"
    },
    {
      id: 2,
      clientNumber: "123456789",
      emri: "Alice",
      mbiemri: "Smith",
      prindi: "Alice Smith Sr.",
      personalNumber: "987654321",
      address:"Ismet Krasniqi",
      dera:"k-333",
      parcela:"P-121212",
      zona:"Prishtine",
      siperfaqja:"100m2",
      status:"I rregullt",
      bill:"12345678901",
      date:"11.03.2023",
      value:"11.03",
      tipi:"Bank",
      tipifat:"Qytetari"
    },
    {
      id: 3,
      clientNumber: "123456",
      emri: "Johnatan",
      mbiemri: "Doeser",
      prindi: "John Doe Sr.",
      personalNumber: "123456789",
      address:"Ismet Krasniqi",
      dera:"k-333",
      parcela:"P-121212",
      zona:"Prishtine",
      siperfaqja:"100m2",
      status:"I rregullt",
      bill:"12345678901",
      date:"11.03.2023",
      value:"11.03",
      tipi:"Bank",
      tipifat:"Qytetari"
    },
    {
      id: 4,
      clientNumber: "123456789",
      emri: "Alicente",
      mbiemri: "Smithe",
      prindi: "Alice Smith Sr.",
      personalNumber: "987654321",
      address:"Ismet Krasniqi",
      dera:"k-333",
      parcela:"P-121212",
      zona:"Prishtine",
      siperfaqja:"100m2",
      status:"I rregullt",
      bill:"12345678901",
      date:"11.03.2023",
      value:"11.03",
      tipi:"Bank",
      tipifat:"Qytetari"
    },
    {
      id: 5,
      clientNumber: "123456",
      emri: "Johnwwee",
      mbiemri: "Doerew",
      prindi: "John Doe Sr.",
      personalNumber: "123456789",
      address:"Ismet Krasniqi",
      dera:"k-333",
      parcela:"P-121212",
      zona:"Prishtine",
      siperfaqja:"100m2",
      status:"I rregullt",
      bill:"12345678901",
      date:"11.03.2023",
      value:"11.03",
      tipi:"Bank",
      tipifat:"Qytetari"
    },
    {
      id: 6,
      clientNumber: "123456789",
      emri: "Alicerrew",
      mbiemri: "Smithrewewr",
      prindi: "Alice Smith Sr.",
      personalNumber: "987654321",
      address:"Ismet Krasniqi",
      dera:"k-333",
      parcela:"P-121212",
      zona:"Prishtine",
      siperfaqja:"100m2",
      status:"I rregullt",
      bill:"12345678901",
      date:"11.03.2023",
      value:"11.03",
      tipi:"Bank",
      tipifat:"Qytetari"
    },
    {
      id: 7,
      clientNumber: "123456",
      emri: "Johnqqq",
      mbiemri: "Doeqqq",
      prindi: "John Doe Sr.",
      personalNumber: "123456789",
      address:"Ismet Krasniqi",
      dera:"k-333",
      parcela:"P-121212",
      zona:"Prishtine",
      siperfaqja:"100m2",
      status:"I rregullt",
      bill:"12345678901",
      date:"11.03.2023",
      value:"11.03",
      tipi:"Bank",
      tipifat:"Qytetari"
    },
    {
      id: 8,
      clientNumber: "123456789",
      emri: "Aliceee",
      mbiemri: "Smithwww",
      prindi: "Alice Smith Sr.",
      personalNumber: "987654321",
      address:"Ismet Krasniqi",
      dera:"k-333",
      parcela:"P-121212",
      zona:"Prishtine",
      siperfaqja:"100m2",
      status:"I rregullt",
      bill:"12345678901",
      date:"11.03.2023",
      value:"11.03",
      tipi:"Bank",
      tipifat:"Qytetari"
    },
    {
      id: 9,
      clientNumber: "123456",
      emri: "Johnrre",
      mbiemri: "Doewew",
      prindi: "John Doe Sr.",
      personalNumber: "123456789",
      address:"Ismet Krasniqi",
      dera:"k-333",
      parcela:"P-121212",
      zona:"Prishtine",
      siperfaqja:"100m2",
      status:"I rregullt",
      bill:"12345678901",
      date:"11.03.2023",
      value:"11.03",
      tipi:"Bank",
      tipifat:"Qytetari"
    },
    {
      id: 10,
      clientNumber: "123456789",
      emri: "Aceeeee",
      mbiemri: "Smithererer",
      prindi: "Alice Smith Sr.",
      personalNumber: "987654321",
      address:"Ismet Krasniqi",
      dera:"k-333",
      parcela:"P-121212",
      zona:"Prishtine",
      siperfaqja:"100m2",
      status:"I rregullt",
      bill:"12345678901",
      date:"11.03.2023",
      value:"11.03",
      tipi:"Bank",
      tipifat:"Qytetari"
    },
    {
      id: 11,
      clientNumber: "123456",
      emri: "Johnww",
      mbiemri: "Doewww",
      prindi: "John Doe Sr.",
      personalNumber: "123456789",
      address:"Ismet Krasniqi",
      dera:"k-333",
      parcela:"P-121212",
      zona:"Prishtine",
      siperfaqja:"100m2",
      status:"I rregullt",
      bill:"12345678901",
      date:"11.03.2023",
      value:"11.03",
      tipi:"Bank",
      tipifat:"Qytetari"
    },
    {
      id: 12,
      clientNumber: "123456789",
      emri: "qAqlice",
      mbiemri: "qSmith",
      prindi: "Alice Smith Sr.",
      personalNumber: "987654321",
      address:"Ismet Krasniqi",
      dera:"k-333",
      parcela:"P-121212",
      zona:"Prishtine",
      siperfaqja:"100m2",
      status:"I rregullt",
      bill:"12345678901",
      date:"11.03.2023",
      value:"11.03",
      tipi:"Bank",
      tipifat:"Qytetari"
    },
    {
      id: 13,
      clientNumber: "123456",
      emri: "qrtJohn",
      mbiemri: "reerDoe",
      prindi: "John Doe Sr.",
      personalNumber: "123456789",
      address:"Ismet Krasniqi",
      dera:"k-333",
      parcela:"P-121212",
      zona:"Prishtine",
      siperfaqja:"100m2",
      status:"I rregullt",
      bill:"12345678901",
      date:"11.03.2023",
      value:"11.03",
      tipi:"Bank",
      tipifat:"Qytetari"
    },
    {
      id: 14,
      clientNumber: "123456789",
      emri: "werAlice",
      mbiemri: "werSmith",
      prindi: "Alice Smith Sr.",
      personalNumber: "987654321",
      address:"Ismet Krasniqi",
      dera:"k-333",
      parcela:"P-121212",
      zona:"Prishtine",
      siperfaqja:"100m2",
      status:"I rregullt",
      bill:"12345678901",
      date:"11.03.2023",
      value:"11.03",
      tipi:"Bank",
      tipifat:"Qytetari"
    },
  ];



  export const municipalitiesData = [
    { 
      id:1,
      title: "Komuna Prishtinë",
      description: "Shkruaj te dhenat dhe merr informatat rreth pagesave tuaja.",
      image:"/assets/images/municipalities/prishtina.svg",
      active:true
    },
    {
      id:2,
      title: "Komuna Pejë",
      description: "Shkruaj te dhenat dhe merr informatat rreth pagesave tuaja.",
      image:"/assets/images/municipalities/peja.svg"
    },
    {
      id:3,
      title: "Komuna Gjakovë",
      description: "Shkruaj te dhenat dhe merr informatat rreth pagesave tuaja.",
      image:"/assets/images/municipalities/gjakova.svg"
    },
    {
      id:4,
      title: "Komuna Prizren",
      description: "Shkruaj te dhenat dhe merr informatat rreth pagesave tuaja.",
      image:"/assets/images/municipalities/prizreni.svg",
    },
    {
      id:5,
      title: "Komuna Ferizaj",
      description: "Shkruaj te dhenat dhe merr informatat rreth pagesave tuaja.",
      image:"/assets/images/municipalities/ferizaji.svg"
    },
    { 
      id:6,
      title: "Komuna Prishtinë",
      description: "Shkruaj te dhenat dhe merr informatat rreth pagesave tuaja.",
      image:"/assets/images/municipalities/prishtina.svg"
    },
    {
      id:7,
      title: "Komuna Pejë",
      description: "Shkruaj te dhenat dhe merr informatat rreth pagesave tuaja.",
      image:"/assets/images/municipalities/peja.svg"
    },
    {
      id:8,
      title: "Komuna Gjakovë",
      description: "Shkruaj te dhenat dhe merr informatat rreth pagesave tuaja.",
      image:"/assets/images/municipalities/gjakova.svg"
    },
    {
      id:9,
      title: "Komuna Prizren",
      description: "Shkruaj te dhenat dhe merr informatat rreth pagesave tuaja.",
      image:"/assets/images/municipalities/prizreni.svg"
    },
    {
      id:10,
      title: "Komuna Ferizaj",
      description: "Shkruaj te dhenat dhe merr informatat rreth pagesave tuaja.",
      image:"/assets/images/municipalities/ferizaji.svg"
    },
    { 
      id:11,
      title: "Komuna Prishtinë",
      description: "Shkruaj te dhenat dhe merr informatat rreth pagesave tuaja.",
      image:"/assets/images/municipalities/prishtina.svg"
    },
    {
      id:12,
      title: "Komuna Pejë",
      description: "Shkruaj te dhenat dhe merr informatat rreth pagesave tuaja.",
      image:"/assets/images/municipalities/peja.svg"
    },
    {
      id:13,
      title: "Komuna Gjakovë",
      description: "Shkruaj te dhenat dhe merr informatat rreth pagesave tuaja.",
      image:"/assets/images/municipalities/gjakova.svg"
    },
    {
      id:14,
      title: "Komuna Prizren",
      description: "Shkruaj te dhenat dhe merr informatat rreth pagesave tuaja.",
      image:"/assets/images/municipalities/prizreni.svg"
    },
    {
      id:15,
      title: "Komuna Ferizaj",
      description: "Shkruaj te dhenat dhe merr informatat rreth pagesave tuaja.",
      image:"/assets/images/municipalities/ferizaji.svg"
    },
    { 
      id:16,
      title: "Komuna Prishtinë",
      description: "Shkruaj te dhenat dhe merr informatat rreth pagesave tuaja.",
      image:"/assets/images/municipalities/prishtina.svg"
    },
    {
      id:18,
      title: "Komuna Pejë",
      description: "Shkruaj te dhenat dhe merr informatat rreth pagesave tuaja.",
      image:"/assets/images/municipalities/peja.svg"
    },
    {
      id:19,
      title: "Komuna Gjakovë",
      description: "Shkruaj te dhenat dhe merr informatat rreth pagesave tuaja.",
      image:"/assets/images/municipalities/gjakova.svg",
    },
    {
      id:20,
      title: "Komuna Prizren",
      description: "Shkruaj te dhenat dhe merr informatat rreth pagesave tuaja.",
      image:"/assets/images/municipalities/prizreni.svg"
    },
    {
      id:21,
      title: "Komuna Ferizaj",
      description: "Shkruaj te dhenat dhe merr informatat rreth pagesave tuaja.",
      image:"/assets/images/municipalities/ferizaji.svg"
    },
  ]