import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSearchQuery } from "../../../store/slices/tableSlice";
import { SearchBarTable, Pagination, Button, DeleteModal } from "../../../components";
import {
  FaDollarSign,
  FaDownload,
  FaEdit,
  FaTrash,
  FaPlus,
} from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import { RiUpload2Fill } from "react-icons/ri";
import { TbFilterSearch } from "react-icons/tb";
import { Link, useParams, useNavigate } from "react-router-dom";
import { fetchAllHouseholdBills, deleteHouseholdBill } from "../../../store/slices/tablesInfoSlices/householdBillSlice";
import { fetchAllBusinessBill, deleteBusinessBill, fetchBusinessBillById } from "../../../store/slices/tablesInfoSlices/businessBillSlice";
import moment from "moment";
import { toast } from "react-toastify";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import BillInvoice from "../../../pages/Forms/Bills/BillInvoice";
import { useTranslation } from "react-i18next";

const TableBusinessBill = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const businessBillData = useSelector((state) => state.businessBills.data);
  const businessBillDataArray = Array.isArray(businessBillData)
    ? businessBillData
    : [];

  const filteredHouseholdBills = id
    ? businessBillDataArray?.filter(
        (item) => item.household_id === parseInt(id)
      )
    : businessBillDataArray;

  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();

  const [businessbillData, setBusinessBillData] = useState("");

  useEffect(() => {
    dispatch(fetchAllBusinessBill());
  }, [dispatch, id]);

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    dispatch(updateSearchQuery(searchInput));
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const totalItems = filteredHouseholdBills.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = Array.isArray(filteredHouseholdBills)
    ? filteredHouseholdBills.slice(indexOfFirstItem, indexOfLastItem)
    : [];

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };



  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);


  const handleDeleteClick = (item) => {
    setItemToDelete(item);
    setDeleteModalOpen(true);
  };


  const handleDelete = () => {
    if (itemToDelete && itemToDelete.id) {
      dispatch(deleteHouseholdBill(itemToDelete.id));
      setDeleteModalOpen(false);
      toast.success("Fatura u fshie me sukses!")
      navigate("/household")
      setItemToDelete(null);
    }
  };

  const componentRef = useRef();
  const generatePDF = () => {
    const doc = new jsPDF();
    const canvas = componentRef.current;

    html2canvas(canvas).then((canvasImage) => {
      const imgData = canvasImage.toDataURL("image/png");
      const imgWidth = 200;
      const imgHeight = 280;
      doc.addImage(imgData, "PNG", 5, 0, imgWidth, imgHeight);
      doc.save(`Fatura-${id}`);
      toast.success("Vertetimi u gjenerua me sukses!");
    });
  };

  

  const handleFetchBillData = async (itemId) => {
    try {
      const response = await dispatch(fetchBusinessBillById(itemId));
      if (response.payload) {
        setBusinessBillData(response.payload);
      }
    } catch (error) {
      console.error("Error fetching bill data:", error);
    } finally {
      generatePDF();

    }
  };

  const {t} = useTranslation();


  return (
    <div className="p-3 mt-3">
      <div className="flex items-center justify-between pb-4 ">
        <h1 className="text-blue-900 font-semibold	">{t("businessBills")}</h1>
        <div className="flex items-center justify-end bg-white dark:bg-gray-900">
          <SearchBarTable
            searchInput={searchInput}
            handleSearchInputChange={handleSearchInputChange}
            handleSearchSubmit={handleSearchSubmit}
          />
          <div className="flex">
       

            <Button className="text-blue-800 text-sm bg-gray-100">
              <RiUpload2Fill className="mx-2" />
            </Button>

            <Link to="/business/add-new-bill">
              <Button className="text-white bg-blue-800 transition duration-300 ease-in-out hover:bg-gray-200 hover:text-blue-800">
                <FaPlus className="mr-1" /> {t("addNewBill")}
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <div
        className="relative overflow-x-auto shadow-md sm:rounded-lg"
        style={{ borderRadius: 1 }}
      >
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-blue-800 font-light whitespace-nowrap	 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
              <th scope="col" className="px-6 py-3">
                {t("billNumber")}
              </th>
              <th scope="col" className="px-6 py-3">
                {t("created")}
              </th>
              <th scope="col" className="px-6 py-3">
                {t("expired")}
              </th>
              <th scope="col" className="px-6 py-3">
              {t("personalNumber")}
              </th>
              <th scope="col" className="px-6 py-3">
              {t("name")}
              </th>
              <th scope="col" className="px-6 py-3">
              {t("surname")}
              </th>
              <th scope="col" className="px-6 py-3">
              {t("value")} €
              </th>
              <th scope="col" className="px-6 py-3">
              {t("description")}
              </th>
              <th scope="col" className="px-6 py-3"></th>
            </tr>
          </thead>
          <tbody className="text-xs">
            {currentItems.length === 0 && (
              <p>Nuk ka fatura per kete biznes!</p>
            )}
            {currentItems.map((item) => (
              <tr key={item.id} className="border-b">
                <td className="px-6 py-4">{item.id}</td>
                <td className="px-6 py-4">
                  {moment(item.created_at).format("L")}
                </td>
                <td className="px-6 py-4">{moment(item.skanin).format("L")}</td>
                <td className="px-6 py-4">{item.personal_number}</td>
                <td className="px-6 py-4">{item.name}</td>
                <td className="px-6 py-4">{item.surname}</td>
                <td className="px-6 py-4">{item.value} €</td>
                <td className="px-6 py-4">{item.description}</td>
                <td className="py-4 flex gap-2 cursor-pointer text-gray-400">
                <button onClick={handleFetchBillData.bind(null, item.id)}>
                    <FaDownload className="w-[17px] h-[17px]" />
                  </button>
                  <Link
                    to={
                      id
                        ? `/business/add-new-collection/${id}`
                        : `/business/add-new-collection/${item.business_id}`
                    }
                  >
                    <div title="Inkasimi">
                      <FaDollarSign className="w-[17px] h-[17px]" />
                    </div>
                  </Link>
                  <Link to={`/edit-bill/${item.id}`}>
                    <div title="Edit">
                      <FaEdit className="w-[17px] h-[17px]"/>
                    </div>
                  </Link>
                  <div title="Delete" onClick={() => handleDeleteClick(item)}>
                    <FaTrash className="w-[17px] h-[17px]" />
                  </div>
                  <Link to={`/bill-info/${item.id}`}>
                    <div title="Info">
                      <BsThreeDotsVertical className="w-[17px] h-[17px]" />
                    </div>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>  
        </table>
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
        className="flex items-center justify-center mt-4"
      />
      <DeleteModal
        isOpen={isDeleteModalOpen}
        closeModal={() => setDeleteModalOpen(false)}
        handleDelete={handleDelete}
        question="Deshironi ta fshini faturen"
        actionTitle="Delete"
      />
      <div style={{ height: 0, overflow: "hidden" }}>
        <BillInvoice reff={componentRef} />
      </div>
    </div>
  );
};

export default TableBusinessBill;
