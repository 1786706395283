import React, { useState } from "react";
import { CiImport } from "react-icons/ci";
import * as XLSX from "xlsx";
import { Table } from "../../components";
import { useTranslation } from "react-i18next";

const PaymentImport = () => {
  const {t} = useTranslation();
  const [selectedFileName, setSelectedFileName] = useState(t("noFileChoosen"));
  const [excelFile, setExcelFile] = useState(null);
  const [typeError, setTypeError] = useState(null);
  const [excelData, setExcelData] = useState(null);

  const handleFile = (e) => {
    let fileTypes = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/csv",
    ];
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile && fileTypes.includes(selectedFile.type)) {
        setTypeError(null);
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e) => {
          setExcelFile(selectedFile);
          setSelectedFileName(selectedFile.name);
        };
      } else {
        setTypeError("Please select only excel file types");
        setExcelFile(null);
      }
    } else {
      console.log("Please select your file");
    }
  };

  const handleFileSubmit = (e) => {
    e.preventDefault();
    if (excelFile !== null) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const worksheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[worksheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        setExcelData(jsonData);
      };
      reader.readAsArrayBuffer(excelFile);
    }
  };

  return (
    <div className="p-6 container">
      <p className="text-[16px] font-semibold text-blue-900 mb-5 text-left">
      {t("paymentImport")}
      </p>

      <div className="mb-5 relative">
        <input
          className="absolute h-full opacity-0 cursor-pointer"
          type="file"
          id="fileInput"
          onChange={handleFile}
        />
        <label
          htmlFor="fileInput"
          className="bg-[#F7F7FB] p-2 cursor-pointer flex items-center justify-between"
        >
          <span className="text-xs text-blue-800 cursor-pointer">
            {selectedFileName}
          </span>
          <span className="ml-2 text-blue-800">
            <CiImport />
          </span>
        </label>
      </div>

      <div className="flex justify-end">
        <button
          type="submit"
          onClick={handleFileSubmit}
          className="px-12 py-2 bg-[#2FBFDE] text-white rounded-md"
        >
          {t("upload")}
        </button>
      </div>
      {typeError && (
        <div className="alert alert-danger" role="alert">
          {typeError}
        </div>
      )}

      <div className="viewer relative overflow-x-auto shadow-md sm:rounded-lg mt-4">
        {excelData && excelData.length > 0 ? (
          <div className="table-responsive">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-blue-800 font-light whitespace-nowrap	 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  {Object.keys(excelData[0]).map((key) => (
                    <th className="px-4 py-3" key={key}>
                      {key}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="text-xs">
                {excelData.map((individualExcelData, index) => (
                  <tr key={index} className="border-b">
                    {Object.keys(individualExcelData).map((key) => (
                      <td className="px-4 py-4" key={key}>
                        {individualExcelData[key]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div>{t("noData")}</div>
        )}
      </div>
    </div>
  );
};

export default PaymentImport;
