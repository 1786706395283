import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";

export default function LiabilitiesReportChart() {
  const {t} = useTranslation();
  const data = [
    {
      month: "July",
      liabilities: 4000,
    },
    {
      month: "August",
      liabilities: 3000,
    },
    {
      month: "August",
      liabilities: 3000,
    },
    {
      month: "August",
      liabilities: 3000,
    },
    {
      month: "August",
      liabilities: 3000,
    },
  ];

  return (
    <div className="p-3">
      <div className="flex-column justify-between items-center px-4 pb-4">
        <div className="flex justify-between items-center">
          <h1 className="text-sm p-2">{t("oweRaport")}</h1>
          <Link to="/liabilities-report">
            <button className="rounded-[4px] bg-gray-100 p-1 text-blue-900 font-medium border-[1px] text-xs">
            {t("goToRaport")}
            </button>
          </Link>
        </div>
        <div>
          <p className="text-base p-2 font-semibold">2.568</p>
          <p className="text-xs">
            <span className="text-red-600">↓ 2.1% </span> vs {t("lastMonth")}
          </p>
        </div>
      </div>
      <div style={{ width: "100%", height: 200 }}>
        <ResponsiveContainer>
          <LineChart
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            {/* <YAxis dataKey="liabilities" type="number" /> */}
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="liabilities"
              name="Liabilities"
              stroke="#3C4D9F"
              strokeWidth={2}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
