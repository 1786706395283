import React from "react";

const Table = ({ data, columns }) => {
  return (
    <div
      className="relative overflow-x-auto shadow-md sm:rounded-lg"
      style={{ borderRadius: 1 }}
    >
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-blue-800 font-light whitespace-nowrap	 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            {columns?.map((column) => (
              <th key={column.key} scope="col" className="px-6 py-3">
                {column.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="text-xs">
          {data.length === 0 && <p>No data to show !</p>}
          {data?.map((item) => (
            <tr key={item.id} className="border-b">
              {columns.map((column, index) => (
                <td key={column.key} className="px-6 py-4">
                  {index === columns.length - 1 ? (
                    <div className="flex gap-2 cursor-pointer text-gray-400">
                      {item[column.key]}
                    </div>
                  ) : 
                  column.key === "created_at" ? (
                    <>{new Date(item[column.key]).toLocaleDateString()}</>
                  ) : (
                    <>{item[column.key]}</>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
