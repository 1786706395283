import React from "react";
import { FiltersCashiersReport } from "../../components";

const CashiersReport = () => {
  return (
    <div className="m-4" data-aos="zoom-in-down">
      <FiltersCashiersReport/>
    </div>
  );
};

export default CashiersReport;
