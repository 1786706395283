import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchHouseholds } from "../../../store/slices/tablesInfoSlices/houseHoldsSlice";

const CitizenInfo = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const households = useSelector((state) => state.households.data);
  const citizen = households?.find((item) => item.id === parseInt(id, 10));

  useEffect(() => {
    dispatch(fetchHouseholds());
  }, [dispatch]);

  if (!citizen) {
    return <div>Citizen not found</div>;
  }

  return (
    <div className="p-6  w-[70%]" key={citizen.id}>
      <h1 className="text-blue-900 font-bold py-4 ">Qytetari</h1>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400 w-[20%]">Nr Klientit</p>
        <p>{citizen.id}</p>
      </div>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400 w-[20%]">Emri</p>
        <p>{citizen.name}</p>
      </div>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400 w-[20%]">Mbiemri</p>
        <p>{citizen.surname}</p>
      </div>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400 w-[20%]">Emri i Prindit</p>
        <p>{citizen.parent_name}</p>
      </div>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400 w-[20%]">Nr.Personal</p>
        <p>{citizen.personal_number}</p>
      </div>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400 w-[20%]">Adresa</p>
        <p>{citizen.address}</p>
      </div>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400 w-[20%]">Dera</p>
        <p>{citizen.door}</p>
      </div>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400 w-[20%]">Nr.Parcela</p>
        <p>{citizen.parcel_number}</p>
      </div>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400 w-[20%]">Zona</p>
        <p>{citizen.zone}</p>
      </div>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400 w-[20%]">Sipërfaqja</p>
        <p>{citizen.surface}</p>
      </div>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400 w-[20%]">Status</p>
        <p>{citizen.status}</p>
      </div>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400 w-[20%]">Data Regjistrimit</p>
        <p>{citizen.created_at}</p>
      </div>
      <div className="text-xs flex border-b-[1px] py-4">
        <p className="pr-7 text-gray-400 w-[20%]">Koment</p>
        <p>{citizen.comment}</p>
      </div>
      <div className="flex justify-end py-4">
        <Link to="/household">
          <button
            type="submit"
            className="px-12 py-2 bg-[#2FBFDE] text-white rounded-md"
          >
            Kthehu te lista
          </button>
        </Link>
      </div>
    </div>
  );
};

export default CitizenInfo;
