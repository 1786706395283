import React from "react";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Input from "../../../components/Inputs/Input";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createCollectionHousehold } from "../../../store/slices/tablesInfoSlices/collectionsSlice";

const validationSchema = Yup.object().shape({
  household_id: Yup.string().required("Required"),
});

export default function AddNoBillNewCollection() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  const initialValues = {
    household_id: "",
    value: "",
    type: "",
    tipifat: "",
    user_id: user?.id || "",
  };

  return (
    <div className="p-6">
      <h1 className="py-6 text-blue-900 font-semibold">Shto Inkasim</h1>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          dispatch(createCollectionHousehold(values));
          navigate("/collection");
        }}
      >
        {({ errors, touched, handleChange, values }) => (
          <Form className="space-y-4 text-xs">
            <Input
              label="Nr Klientit"
              id="household_id"
              type="number"
              onChange={handleChange}
              name="household_id"
              placeholder="Nr Klientit"
              error={
                errors.household_id && touched.household_id
                  ? errors.household_id
                  : null
              }
            />
            <Input
              label="Emri"
              id="namee"
              onChange={handleChange}
              name="name"
              placeholder="Emri"
            />
            <Input
              label="Mbiemri"
              id="surnamee"
              onChange={handleChange}
              name="surname"
              placeholder="Mbiemri"
            />
            <Input
              label="Vlera "
              id="value"
              onChange={handleChange}
              name="value"
              placeholder="Vlera €"
              error={errors.value && touched.value ? errors.value : null}
            />
            <Input
              label="Tipi"
              id="type"
              type="text"
              value={values.type}
              onChange={handleChange}
              name="type"
              placeholder="Tipi"
              error={errors.type && touched.type ? errors.type : null}
            />
            <Input
              label="Tipifat"
              id="tipifat"
              type="text"
              value={values.tipifat}
              onChange={handleChange}
              name="tipifat"
              placeholder="Tipifat"
              error={errors.tipifat && touched.tipifat ? errors.tipifat : null}
            />
            <div className="flex justify-end">
              <Link to="/household">
                <button
                  type="button"
                  className="px-4 py-2 mr-2 bg-white text-[#28ABE2] rounded-md"
                >
                  Anulo
                </button>
              </Link>
              <button
                type="submit"
                className="px-12 py-2 bg-[#2FBFDE] text-white rounded-md"
              >
                Ruaj
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
