import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateSearchQuery } from "../../../store/slices/tableSlice";
import {
  SearchBarTable,
  Pagination,
  Button,
  DeleteModal,
} from "../../../components";
import { FaTrash } from "react-icons/fa";
import { GrRevert } from "react-icons/gr";
import { MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";
import {
  fetchSoftDeletedBills,
  deleteSoftDeletedBillById,
  undeleteBillById,
  deleteAllCanceledBills,
} from "../../../store/slices/tablesInfoSlices/householdBillSlice";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const TableCanceledBills = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState("");
  const canceledBills = useSelector((state) => state.householdBills.data);
  const filteredCanceledBills = Array.isArray(canceledBills)
    ? canceledBills
    : [];
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedItemIdToDelete, setSelectedItemIdToDelete] = useState(null);

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    dispatch(updateSearchQuery(searchInput));
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const totalItems = filteredCanceledBills.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredCanceledBills.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const [selectedItems, setSelectedItems] = useState([]);

  const handleSelectAll = () => {
    if (selectedItems.length === currentItems.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(currentItems.map((item) => item.id));
    }
  };

  const handleRowSelect = (itemId) => {
    if (selectedItems.includes(itemId)) {
      setSelectedItems(selectedItems.filter((id) => id !== itemId));
    } else {
      setSelectedItems([...selectedItems, itemId]);
    }
  };

  const openDeleteModal = (itemId) => {
    setSelectedItemIdToDelete(itemId);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setSelectedItemIdToDelete(null);
    setIsDeleteModalOpen(false);
  };

  const handleDelete = () => {
    const deletionResult = dispatch(
      deleteSoftDeletedBillById(selectedItemIdToDelete)
    );
    if (deletionResult && deletionResult.success) {
      closeDeleteModal();
      toast.success("Fatura u fshie me sukses!");
    }
  };

  const [isUndeleteModalOpen, setIsUndeleteModalOpen] = useState(false);
  const [selectedItemIdToUndelete, setSelectedItemIdToUndelete] =
    useState(null);

  const openUndeleteModal = (itemId) => {
    setSelectedItemIdToUndelete(itemId);
    setIsUndeleteModalOpen(true);
  };

  const closeUndeleteModal = () => {
    setSelectedItemIdToUndelete(null);
    setIsUndeleteModalOpen(false);
  };

  const handleUndelete = () => {
    dispatch(undeleteBillById(selectedItemIdToUndelete));
    closeUndeleteModal();
    toast.success("Fatura u kthye me sukses!");
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleDeleteAllInvoices = () => {
    dispatch(deleteAllCanceledBills());
    dispatch(fetchSoftDeletedBills());
  };

  useEffect(() => {
    dispatch(fetchSoftDeletedBills());
  }, [dispatch]);

  return (
    <div className="p-3 mt-3">
      <div className="flex items-center justify-between pb-4 ">
        <h1 className="text-blue-900 font-semibold	">
          {t("canceledBillsTitle")}
        </h1>
        <div className="flex items-center justify-end bg-white dark:bg-gray-900">
          <SearchBarTable
            searchInput={searchInput}
            handleSearchInputChange={handleSearchInputChange}
            handleSearchSubmit={handleSearchSubmit}
            placeholder={t("search")}
          />
          <div className="flex">
            <Button
              className="text-white bg-blue-800 transition duration-300 ease-in-out hover:bg-gray-200 hover:text-blue-800"
              onClick={handleDeleteAllInvoices}
            >
              <MdDelete className="mr-1" /> {t("deleteAll")}
            </Button>
          </div>
        </div>
      </div>
      <div
        className="relative overflow-x-auto shadow-md sm:rounded-lg"
        style={{ borderRadius: 1 }}
      >
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-blue-800 font-light whitespace-nowrap	 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                <input
                  type="checkbox"
                  checked={selectedItems.length === currentItems.length}
                  onChange={handleSelectAll}
                />
              </th>
              <th scope="col" className="px-6 py-3">
                ID
              </th>
              <th scope="col" className="px-6 py-3">
                {t("name")}
              </th>
              <th scope="col" className="px-6 py-3">
                {t("surname")}
              </th>
              <th scope="col" className="px-6 py-3">
                {t("personalNumber")}
              </th>
              <th scope="col" className="px-6 py-3">
                {t("value")} €
              </th>
              <th scope="col" className="px-6 py-3">
                {t("description")}
              </th>
              <th scope="col" className="px-6 py-3"></th>
            </tr>
          </thead>
          <tbody className="text-xs">
            {currentItems.map((item) => (
              <tr key={item.id} className="border-b">
                <td className="px-6 py-4">
                  <input
                    type="checkbox"
                    checked={selectedItems.includes(item.id)}
                    onChange={() => handleRowSelect(item.id)}
                  />
                </td>
                <td className="px-6 py-4">{item.id}</td>
                <td className="px-6 py-4">{item.name}</td>
                <td className="px-6 py-4">{item.surname}</td>
                <td className="px-6 py-4">{item.personal_number}</td>
                <td className="px-6 py-4">{item.value}</td>
                <td className="px-6 py-4">{item.description}</td>
                <td className="py-4 px-6 flex gap-2 cursor-pointer text-gray-400">
                  <div
                    title="Revert bg-gray-500"
                    onClick={() => openUndeleteModal(item.id)}
                    className={
                      selectedItems.includes(item.id) ? "text-gray-500" : ""
                    }
                  >
                    <GrRevert />
                  </div>
                  <div
                    title="Delete"
                    onClick={() => openDeleteModal(item.id)}
                    className={
                      selectedItems.includes(item.id) ? "text-red-500" : ""
                    }
                  >
                    <FaTrash />
                  </div>
                </td>
              </tr>
            ))}
            {currentItems?.length === 0 && <p>No data to show!</p>}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        handlePageChange={handlePageChange}
        className="flex items-center justify-center mt-4"
      />
      <DeleteModal
        isOpen={isDeleteModalOpen}
        closeModal={closeDeleteModal}
        handleDelete={handleDelete}
        question="Deshironi ta fshini faturen pergjithmone ?"
        actionTitle="Delete"
      />
      <DeleteModal
        isOpen={isUndeleteModalOpen}
        closeModal={closeUndeleteModal}
        handleDelete={handleUndelete}
        question="Deshironi ta riktheni faturen"
        actionTitle="Rikthe"
      />
    </div>
  );
};

export default TableCanceledBills;
