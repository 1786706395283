import React from "react";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Input } from "../../components";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createUser } from "../../store/slices/authSlice";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  user: Yup.string().required("Required"),
  email: Yup.string().required("Required"),
  password: Yup.string().required("Required"),
  role: Yup.string().required("Required"),
  directory: Yup.string().required("Required"),
  personal_nr: Yup.string().required("Required"),
  position: Yup.string().required("Required"),
});

export default function AddUser() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialValues = {
    name: "",
    user: "",
    email: "",
    password: "",
    role: "",
    residence: "",
    directory: "",
    personal_nr: "",
    position: "",
  };

  return (
    <div className="p-6">
      <h1 className="py-6 text-blue-900 font-semibold">Shto User</h1>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          dispatch(createUser(values));
          navigate("/users");
        }}
      >
        {({ errors, touched, handleChange, values }) => (
          <Form className="space-y-4 text-xs">
            <Input
              label="Emri"
              id="name"
              onChange={handleChange}
              name="name"
              placeholder="Emri"
              error={errors.name && touched.name ? errors.name : null}
            />
            <Input
              label="Useri"
              id="user"
              onChange={handleChange}
              name="user"
              placeholder="Useri"
              error={errors.user && touched.user ? errors.user : null}
            />
            <Input
              label="Email"
              id="email"
              type="email"
              onChange={handleChange}
              name="email"
              placeholder="Emaili"
              error={errors.email && touched.email ? errors.email : null}
            />
            <Input
              label="Password"
              id="password"
              type="password"
              onChange={handleChange}
              name="password"
              placeholder="Password"
              error={
                errors.password && touched.password ? errors.password : null
              }
            />
            <Input
              label="Roli"
              id="role"
              onChange={handleChange}
              name="role"
              placeholder="Roli"
              error={errors.role && touched.role ? errors.role : null}
            />

            <Input
              label="Residenca"
              id="residence"
              onChange={handleChange}
              name="residence"
              placeholder="Residenca"
              error={
                errors.residence && touched.residence ? errors.residence : null
              }
            />
            <Input
              label="Directory"
              id="directory"
              onChange={handleChange}
              name="directory"
              placeholder="Directory"
              error={
                errors.directory && touched.directory ? errors.directory : null
              }
            />
            <Input
              label="Personal Number"
              id="personal_nr"
              onChange={handleChange}
              name="personal_nr"
              placeholder="Personal Number"
              error={
                errors.personal_nr && touched.personal_nr
                  ? errors.personal_nr
                  : null
              }
            />
            <Input
              label="Position"
              id="position"
              onChange={handleChange}
              name="position"
              placeholder="Position"
              error={
                errors.position && touched.position ? errors.position : null
              }
            />
            <div className="flex justify-end">
              <Link to="/household">
                <button
                  type="button"
                  className="px-4 py-2 mr-2 bg-white text-[#28ABE2] rounded-md"
                >
                  Anulo
                </button>
              </Link>
              <button
                type="submit"
                className="px-12 py-2 bg-[#2FBFDE] text-white rounded-md"
              >
                Ruaj
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
