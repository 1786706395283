import React from "react";
import { TableBusiness } from "../../components";

const Business = () => {
  return (
    <div className="m-4" data-aos="zoom-in-down" >
      <TableBusiness />
    </div>
  );
};

export default Business;
