import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "./../../../api/axiosInstance";
import { toast } from 'react-toastify';


// async thunk for creating a household
export const createBusiness = createAsyncThunk(
  "business/create",
  async (householdData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/businesses/createBusiness`,
        householdData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// async thunk for fetching all businesses
export const fetchBusinesses = createAsyncThunk(
  "businesses/getAllBusinesses",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/businesses/getAllBusinesses`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// async thunk for fetching a businesses by ID
export const fetchBusinessesById = createAsyncThunk(
  "businesses/fetchById",
  async (businessId, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `/businesses/getBusinessById/${businessId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//  async thunk for updating a businesses by ID
export const updateBusiness = createAsyncThunk(
  "businesses/update",
  async ({ id, updateBusiness }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `/businesses/updateBusiness/${id}`,
        updateBusiness
      );
      return { id, updateBusiness };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//  async thunk for deleting a businesses by ID
export const deleteBusiness = createAsyncThunk(
  "businesses/delete",
  async (id, { rejectWithValue }) => {
    try {
      await axiosInstance.delete(`/businesses/deleteBusiness/${id}`);
      return id;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const businessesSlice = createSlice({
  name: "businesses",
  initialState: {
    data: [],
    status: "idle",
    error: null,
    isLoading: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createBusiness.fulfilled, (state, action) => {
        state.data.push(action.payload);
        state.isLoading= false; 
        toast.success('Biznesi u shtua me sukses!');       
      })
      .addCase(fetchBusinessesById.fulfilled, (state, action) => {
        state.data = action.payload;
        state.isLoading= false;
      })

      .addCase(fetchBusinesses.fulfilled, (state, action) => {
        state.data = action.payload;
        state.isLoading= false;
      })
      .addCase(updateBusiness.fulfilled, (state, action) => {
        const { id, updateBusiness } = action.payload;
        if (Array.isArray(state.data)) {
          const index = state.data?.findIndex((h) => h.id === id);
          if (index !== -1) {
            state.data[index] = { ...state.data[index], ...updateBusiness };
          }
        }
        state.isLoading = false;
        toast.success('Biznesi u editua me sukses');
      })
      .addCase(deleteBusiness.fulfilled, (state, action) => {
        state.data = state.data.filter((h) => h.id !== action.payload);
        state.isLoading= false;
        toast.success('Biznesi u fshi me sukses');

      })
      .addMatcher(
        (action) => action.type.endsWith("/pending"),
        (state) => {
          state.status = "loading";
          state.error = null;
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.status = "failed";
          state.error = action.payload;
        }
      );
  },
});

export default businessesSlice.reducer;
