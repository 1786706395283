import React from "react";
import { TableTotalReport } from "../../components";

const TotalReport = () => {
  return (
    <div className="m-4" data-aos="zoom-out-down">
      <TableTotalReport />
    </div>
  );
};

export default TotalReport;
