import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "./../../../api/axiosInstance";
import { toast } from "react-toastify";

// async thunk for creating a household
export const createHousehold = createAsyncThunk(
  "households/create",
  async (householdData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/households/createHouseholds`,
        householdData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// async thunk for fetching all households
export const fetchHouseholds = createAsyncThunk(
  "households/fetchAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/households/getAllHouseholds`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// async thunk for fetching a household by ID
export const fetchHouseholdById = createAsyncThunk(
  "households/fetchById",
  async (householdId, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `/households/getHouseholdById/${householdId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//  async thunk for updating a household by ID
export const updateHousehold = createAsyncThunk(
  "households/update",
  async ({ id, updatedHousehold }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `/households/updateHouseHolds/${id}`,
        updatedHousehold
      );
      return { id, updatedHousehold };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

//  async thunk for deleting a household by ID
export const deleteHousehold = createAsyncThunk(
  "households/delete",
  async (id, { rejectWithValue }) => {
    try {
      await axiosInstance.delete(`/households/deleteHousehold/${id}`);
      return id;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const householdsSlice = createSlice({
  name: "households",
  initialState: {
    data: [],
    status: "idle",
    error: null,
    isLoading: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createHousehold.fulfilled, (state, action) => {
        state.data.push(action.payload);
        state.isLoading = false;
        toast.success("Amviseria u shtua me sukses!");
      })
      .addCase(fetchHouseholdById.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      

      .addCase(fetchHouseholds.fulfilled, (state, action) => {
        state.data = action.payload;
        state.isLoading = false;
      })
      .addCase(updateHousehold.fulfilled, (state, action) => {
        const { id, updatedHousehold } = action.payload;
        if (Array.isArray(state.data)) {
          const index = state.data?.findIndex((h) => h.id === id);
          if (index !== -1) {
            state.data[index] = { ...state.data[index], ...updatedHousehold };
          }
        }
        state.isLoading = false;
        toast.success("Amviseria u editua me sukses");
      })
      .addCase(deleteHousehold.fulfilled, (state, action) => {
        state.data = state.data.filter((h) => h.id !== action.payload);
        state.isLoading = false;
        toast.success("Amviseria u fshi me sukses");
      })
      .addMatcher(
        (action) => action.type.endsWith("/rejected"),
        (state, action) => {
          state.status = "failed";
          state.error = action.payload;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith("/pending"),
        (state, action) => {
          state.isLoading = true;
        }
      );
  },
});

export default householdsSlice.reducer;
