import React from "react";
import { FiSearch } from "react-icons/fi";

const SearchBarTable = ({
  searchInput,
  handleSearchInputChange,
  handleSearchSubmit,
  placeholder,
}) => {
  return (
    <form onSubmit={handleSearchSubmit}>
      <div className="relative">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <FiSearch className="w-4 h-4 text-gray-500 dark:text-gray-400" />
        </div>
        <input
          type="text"
          id="table-search"
          className="block p-[6px] px-2  pl-10 text-xs text-gray-900 border border-gray-100 rounded-lg w-26 bg-gray-50 outline-none "
          placeholder={`${placeholder ? `${placeholder}` : "Kërko ketu"}`}
          value={searchInput}
          onChange={handleSearchInputChange}
        />
      </div>
    </form>
  );
};

export default SearchBarTable;
