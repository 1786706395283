import React from "react";
import { TableHouseHoldBill } from "../../components";

const HouseHoldBills = () => {
  return (
    <div className="m-4" data-aos="zoom-in-down">
      <TableHouseHoldBill />
    </div>
  );
};

export default HouseHoldBills;
