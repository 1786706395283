import React, { useState, useEffect } from "react";
import {
  SearchBarTable,
  Pagination,
  Button,
  DeleteModal,
} from "../../../components";
import {
  FaDollarSign,
  FaReceipt,
  FaAngleDoubleUp,
  FaEdit,
  FaTrash,
  FaPlus,
  FaCreditCard,
} from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import { RiUpload2Fill } from "react-icons/ri";
import { Link } from "react-router-dom";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import {
  deleteBusiness,
  fetchBusinesses,
} from "../../../store/slices/tablesInfoSlices/businessSlice";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const TableBusiness = () => {
  const [searchInput, setSearchInput] = useState("");
  const dispatch = useDispatch();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const business = useSelector((state) => state.businesses.data);
  const initialFilteredData = Array.isArray(business) ? business : [];
  const [filteredData, setFilteredData] = useState(initialFilteredData);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const itemsPerPageOptions = [10, 25, 50, 100];

  useEffect(() => {
    dispatch(fetchBusinesses());
  }, [dispatch]);

  const [searchOptions] = useState([
    "Filtro",
    "Filtro sipas emrit",
    "Filtro sipas mbiemrit",
  ]);

  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value, 10);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    filterTableData(searchInput, );
  };

  const filterTableData = (searchText, option) => {
    let filtered = [];

    if (Array.isArray(business)) {
      filtered = business.filter((item) => {
        const {
          name,
          company_name,
          address,
          residence,
        } = item;
        return (
          name?.toLowerCase().includes(searchText.toLowerCase()) ||
          company_name?.toLowerCase().includes(searchText.toLowerCase()) ||
          address?.toLowerCase().includes(searchText.toLowerCase()) ||
          residence?.toLowerCase().includes(searchText.toLowerCase()) 
        );
      });
    }
    setFilteredData(filtered);
  };

  useEffect(() => {
    filterTableData(searchInput, );
  }, [searchInput, ]);

  const totalItems = filteredData?.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleDeleteClick = (item) => {
    setItemToDelete(item);
    setDeleteModalOpen(true);
  };

  const handleDelete = () => {
    if (itemToDelete && itemToDelete.id) {
      dispatch(deleteBusiness(itemToDelete.id));
      setDeleteModalOpen(false);
      setItemToDelete(null);
    }
  };

  const handleExportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("MenaxhimiAmvisrive");

    const customHeaders = [
      "Nr i Klientit",
      "Emri",
      "Mbiemri",
      "Emri i Prindit",
      "Nr.Personal",
      "Adresa",
      "Dera",
      "Nr.Parcela",
      "Zona",
      "Sipërfaqja",
      "Status",
    ];

    const headerRow = worksheet.addRow(customHeaders);
    headerRow.eachCell((cell) => {
      cell.font = { bold: true };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "D0D5DD" },
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
    const dataColumn = worksheet.getColumn(7);
    dataColumn.width = 25;
    const emriColumn = worksheet.getColumn(2);
    emriColumn.width = 20;
    filteredData.forEach(
      ({
        client_number,
        name,
        surname,
        parent_name,
        personal_number,
        address,
        door,
        parcel_number,
        zone,
        surface,
        status,
      }) => {
        worksheet.addRow([
          client_number,
          name,
          surname,
          parent_name,
          personal_number,
          address,
          door,
          parcel_number,
          zone,
          surface,
          status,
        ]);
      }
    );
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "exportTable.xlsx");
  };

  useEffect(() => {
    if (business) {
      filterTableData(searchInput, );
    }
  }, [business, searchInput]);

  const {t} = useTranslation();

  return (
    <>
      <div className="p-3 mt-3">
        <div className="flex items-center justify-between pb-4 ">
          <h1 className="text-blue-900 font-semibold	">{t("businessTitle")}</h1>

          <div className="flex items-center justify-end bg-white dark:bg-gray-900">
            <SearchBarTable
              searchInput={searchInput}
              handleSearchInputChange={handleSearchInputChange}
              handleSearchSubmit={handleSearchSubmit}
              placeholder={t("search")}
            />
       
            <select
              id="itemsPerPage"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              className="p-[6px] ml-2 text-xs border text-gray-400 rounded-md outline-none focus:ring focus:ring-blue-300"
            >
              {itemsPerPageOptions?.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <Button
              className="text-blue-800 text-sm bg-gray-100"
              onClick={handleExportToExcel}
            >
              <RiUpload2Fill className="mx-2" />
            </Button>

            <Link to="/add-new-business">
              <Button className="text-white bg-blue-800 transition duration-300 ease-in-out hover:bg-gray-200 hover:text-blue-800">
                <FaPlus className="mr-1" /> {t("addBusiness")}
              </Button>
            </Link>
          </div>
        </div>
        <div
          className="relative overflow-x-auto shadow-md sm:rounded-lg"
          style={{ borderRadius: 1 }}
        >
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 whitespace-nowrap	">
            <thead className="text-xs text-blue-800 font-light whitespace-nowrap	bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                {t("name")}
                </th>
                <th scope="col" className="px-6 py-3">
                {t("businessName")}
                </th>
                <th scope="col" className="px-6 py-3">
                {t("address")}
                </th>
                <th scope="col" className="px-6 py-3">
                {t("location")}
                </th>
                <th scope="col" className="px-6 py-3">
                {t("surface")}
                </th>
                <th scope="col" className="px-6 py-3">
                {t("door")}
                </th>
                <th scope="col" className="px-6 py-3">
                  Nr. ID
                </th>
                <th scope="col" className="px-1 py-3"></th>
              </tr>
            </thead>
            <tbody className="text-xs">
              {currentItems.length === 0 ? (
                <tr>
                  <td colSpan="12" className="text-center py-4">
                  {t("noData")}
                  </td>
                </tr>
              ) : (
                currentItems.map((item) => (
                  <tr key={item.id} className="border-b">
                    <td className="px-6 py-4">{item.name}</td>
                    <td className="px-6 py-4">{item.company_name}</td>
                    <td className="px-6 py-4">{item.address}</td>
                    <td className="px-6 py-4">{item.residence}</td>
                    <td className="px-6 py-4">{item.address}</td>
                    <td className="px-6 py-4">{item.surface}</td>
                    <td className="px-6 py-4">{item.id}</td>
                    <td className="py-4 flex gap-2 cursor-pointer text-gray-400">
                      {item.business_balance ? (
                        <Link to={`/business/add-new-collection/${item.id}`}>
                          <div title="Inkasimi">
                            <FaDollarSign className="w-[17px] h-[17px]" />
                          </div>
                        </Link>
                      ) : (
                        <FaDollarSign className="text-gray-200 cursor-not-allowed w-[17px] h-[17px]	" />
                      )}
                      <Link to={`/business/${item.id}`}>
                        <div title="Fatura">
                          <FaReceipt className="w-[17px] h-[17px]" />
                        </div>
                      </Link>
                      <Link to={`/certificates/${item.id}`}>
                        <div title="Vertetimi">
                          <FaAngleDoubleUp className="w-[17px] h-[17px]" />
                        </div>
                      </Link>
                      <Link to="/financial-card">
                        <div title="Karta Financiare">
                          <FaCreditCard className="w-[17px] h-[17px]" />
                        </div>
                      </Link>
                      <Link to={`/edit-businesess/${item.id}`}>
                        <div title="Edit">
                          <FaEdit className="w-[17px] h-[17px]" />
                        </div>
                      </Link>
                      <div
                        title="Delete"
                        onClick={() => handleDeleteClick(item)}
                      >
                        <FaTrash />
                      </div>
                      <Link to={`/business/businessInfo/${item.id}`}>
                        <div title="Info">
                          <BsThreeDotsVertical className="w-[17px] h-[17px]" />
                        </div>
                      </Link>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
          className="flex items-center justify-center mt-4"
        />
      </div>
      <DeleteModal
        isOpen={isDeleteModalOpen}
        closeModal={() => setDeleteModalOpen(false)}
        handleDelete={handleDelete}
        question="Deshironi ta fshini biznesin"
        actionTitle="Delete"
      />
    </>
  );  
};

export default TableBusiness;
