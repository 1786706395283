import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "./../../api/axiosInstance";
import api from "../../api/api";
import { toast } from "react-toastify";

const initialState = {
  user: null,
  isLoading: false,
  isAuthLoading: true,
  message: null,
  data: "",
  municipalities: ""
};

export const userLogin = createAsyncThunk(
  "/user/userLogin",
  async (data, thunkAPI) => {
    try {
      const response = await api.post("/user/login", data);
      if (response.data.token) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("userId", response.data.user.id);
      }
      return response.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const createUser = createAsyncThunk(
  "/user/createUser",
  async (data, thunkAPI) => {
    try {
      const response = await api.post("/user/createUser", data);
      return response.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const deleteUser = createAsyncThunk(
  "deleteUser/delete",
  async (id, { rejectWithValue }) => {
    try {
      await axiosInstance.delete(`/user/deleteUser/${id}`);
      return id;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateUser = createAsyncThunk(
  'user/updateUser',
  async ({ id, userData }, { rejectWithValue }) => {
    console.log("userData", userData)
    try {
      const response = await api.put(`/user/updateUser/${id}`, userData);
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const getUserById = createAsyncThunk(
  "user/getUserById",
  async (id, { rejectWithValue }) => {
    try {
      await axiosInstance.get(`/user/getUserById/${id}`);
      return id;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getUsers = createAsyncThunk(
  "/user/getUsers",
  async (data, thunkAPI) => {
    try {
      const response = await api.get("/user/getUsers", data);
      return response.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const isAuthenticated = createAsyncThunk(
  "/user/isAuthenticated",
  async (_, thunkAPI) => {
    const userId = localStorage.getItem("userId");
    try {
      const response = await api.get(`/user/getUserById/${userId}`);
      if (!response.data.user) {
        localStorage.removeItem("token");
      }
      return response.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const userLogout = createAsyncThunk(
  "/user/userLogout",
  (_, thunkAPI) => {
    try {
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getMunicipalities = createAsyncThunk(
  "/getMunicipalities",
  async (data, thunkAPI) => {
    try {
      const response = await api.get("/municipalities/getAllMunicipalities", data);
      return response.data;
    } catch (error) {
      if (error.response) {
        return thunkAPI.rejectWithValue(error.response.data.message);
      }
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetAuth: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(userLogin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(userLogin.fulfilled, (state, action) => {
      state.isLoading = false;
      state.user = action.payload.user;
      state.message = null;
      toast.success("Jeni kyçur me sukses!");
    });
    builder.addCase(createUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.message = null;
      toast.success("Useri u krijua me sukses!");
    });
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.message = null;
      toast.success("Useri u fshi me sukses!");
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.user = action.payload.user;
      state.isLoading = false;
      state.message = null;
      toast.success("Useri u editua me sukses!");
    });
    builder.addCase(userLogin.rejected, (state, action) => {
      state.isLoading = false;
      state.message = action.payload;
      state.user = null;
    });

    builder.addCase(isAuthenticated.pending, (state) => {
      state.isAuthLoading = true;
    });
    builder.addCase(isAuthenticated.fulfilled, (state, action) => {
      state.isAuthLoading = false;
      state.user = action.payload;
    });
    builder.addCase(isAuthenticated.rejected, (state, action) => {
      state.isAuthLoading = false;
      state.message = action.payload;
    });

    builder.addCase(userLogout.fulfilled, (state) => {
      state.user = null;
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.message = null;
    });
    builder.addCase(getMunicipalities.fulfilled, (state, action) => {
      state.isLoading = false;
      state.municipalities = action.payload;
    });
  },
});

export const { resetAuth } = authSlice.actions;
export default authSlice.reducer;
