import React from "react";
import { Link } from "react-router-dom";
import { Paper, Typography } from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { useTranslation } from "react-i18next";

export default function MonthlyReportChart({ data }) {
  
  const {t} = useTranslation();

  return (
    <div elevation={3} className="px-4 border-b-1 border-r-1 w-full">
      <div className="flex-column">
        <div className="flex justify-between items-center">
          <Typography variant="h6" gutterBottom>
            {t("monthlyRaport")}
          </Typography>
          <Link to="/household">
            <button className="rounded-[4px] bg-gray-100 p-1 text-blue-900 font-medium border-[1px] text-xs">
            {t("goToRaport")}
            </button>
          </Link>
        </div>
        <div className="text-xs">
          <p className="py-2">
            <span className="text-[#149D52] ">↑ 2.1%</span> vs {t("lastMonth")} 
          </p>
          <p className="py-2">{t("payments")} 1-30 Gusht, 2023</p>
        </div>
      </div>
      <BarChart width={600} height={300} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="month" tickFormatter={(value) => `${value}`} />
        <Tooltip />
        <Legend />
        <Bar
          barCategoryGap={10}
          barGap={20}
          dataKey="julyValue"
          fill="#3C4D9F"
          name="July"
        />
        <Bar
          barCategoryGap={20}
          barGap={20}
          dataKey="augustValue"
          fill="#E6E8EC"
          name="August"
        />
      </BarChart>
    </div>
  );
}
