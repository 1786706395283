import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Input from "../../../components/Inputs/Input";
import { fetchHouseholdById } from "../../../store/slices/tablesInfoSlices/houseHoldsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { createHouseholdBill } from "./../../../store/slices/tablesInfoSlices/householdBillSlice";
import { useTranslation } from "react-i18next";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  surname: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
  personal_number: Yup.string().required("Required"),
  expiration_date: Yup.string().required("Required"),
  value: Yup.string().required("Required"),
  household_id: Yup.string().required("Required"),
  comment: Yup.string().required("Required"),
});

export default function AddNewBill() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const { id } = useParams();
  const {t} = useTranslation(); 
  const householdData = useSelector((state) => state.households.data);

  useEffect(() => {
    dispatch(fetchHouseholdById(id));
  }, [id]);


  const initialValues = {
    name: householdData.name || "" ,
    surname: householdData.surname ||  "",
    description: householdData.description || "",
    personal_number: householdData.personal_number || "",
    created_date: "",
    expiration_date: "",
    value: "",
    household_id: householdData.id || "",
    comment: "",
    user_id: user.id || "",
  };

  return (
    <div className="p-6">
      <h1 className="py-6 text-blue-900 font-semibold">{t("addNewBill")}</h1>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          dispatch(createHouseholdBill(values));
          navigate("/household-bills");
        }}
        enableReinitialize
      >
        {({ errors, touched, handleChange, values }) => (
          <Form className="space-y-4 text-xs">
            <Input
              label={t("name")}
              id="name"
              onChange={handleChange}
              value={values.name || ""}
              name="name"
              placeholder={t("name")}
              error={errors.name && touched.name ? errors.name : null}
            />
            <Input
              label={t("surname")}
              id="surname"
              value={values.surname}
              onChange={handleChange}
              name="surname"
              placeholder={t("surname")}
              error={errors.surname && touched.surname ? errors.surname : null}
            />
            <Input
              label={t("description")}
              id="description"
              value={values.description}
              onChange={handleChange}
              name="description"
              placeholder={t("description")}
              error={
                errors.description && touched.description
                  ? errors.description
                  : null
              }
            />
            <Input
              label={t("personalNumber")}
              type="number"
              id="personal_number"
              value={values.personal_number}
              onChange={handleChange}
              name="personal_number"
              placeholder={t("personalNumber")}
              error={
                errors.personal_number && touched.personal_number
                  ? errors.personal_number
                  : null
              }
            />
            <Input
              type="date"
              label={t("created")}
              id="created_date"
              onChange={handleChange}
              name="created_date"
              placeholder={t("created")}
            />
            <Input
              type="date"
              label={t("expired")}
              id="expiration_date"
              onChange={handleChange}
              name="expiration_date"
              placeholder={t("expired")}
              error={
                errors.expiration_date && touched.expiration_date
                  ? errors.expiration_date
                  : null
              }
            />
            <Input
              label={t("value" ) + " €"}
              type="number"
              id="value"
              onChange={handleChange}
              name="value"
              placeholder={t("value" ) + " €"}
              error={errors.value && touched.value ? errors.value : null}
            />
            <Input
              label={t("householdNumber")}
              type="number"
              id="household_id"
              value={values.household_id}
              onChange={handleChange}
              name="household_id"
              placeholder={t("householdNumber")}
              error={
                errors.household_id && touched.household_id
                  ? errors.household_id
                  : null
              }
            />
            <Input
              as="textarea"
              label={t("comment")}
              id="comment"
              onChange={handleChange}
              name="comment"
              placeholder={t("comment")}
              error={errors.comment && touched.comment ? errors.comment : null}
            />
            <div className="flex justify-end">
              <Link to="/household">
                <button
                  type="button"
                  className="px-4 py-2 mr-2 bg-white text-[#28ABE2] rounded-md"
                >
                  {t("cancel")}
                </button>
              </Link>
              <button
                type="submit"
                className="px-12 py-2 bg-[#2FBFDE] text-white rounded-md"
              >
                {t("save")}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
