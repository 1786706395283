import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LanguageSwitcher } from "../../components";

export default function FirstPage() {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="fixed right-0 mt-4" >
        <LanguageSwitcher />
      </div>
      <div className="flex flex-col justify-between items-center h-[100vh] p-4">
        <div>
          <img src="/assets/images/Layer_1.svg" alt="kosovo" />
        </div>
        <div>
          <p className="p-4 text-center" data-aos="fade-down">
            {t("welcome")}
            <br></br>
            {t("pleasure")}
          </p>
          <div className="flex gap-4 p-4 justify-center" data-aos="fade-down">
            <Link to="/login">
              <button
                type="submit"
                className="w-[140px] py-2  tracking-wide text-theme-color text-sm transition-colors duration-200 transform bg-white border-1 border-theme-color rounded-md "
              >
                {t("login")}
              </button>
            </Link>
            <Link to="/search-bill">
              <button
                type="submit"
                className="w-[140px] py-[9px]  text-white text-sm transition-colors duration-200 transform bg-theme-color rounded-md "
              >
                {t("searchBill")}
              </button>
            </Link>
          </div>
        </div>
        <div>
          {" "}
          <Link to="/info">
            <button className="w-full text-gray-400 text-sm p-2 rounded-md  border my-6 ">
              {t("projectInfo")}
            </button>
          </Link>
        </div>
      </div>
    </>
  );
}
