import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  FaDollarSign,
  FaReceipt,
  FaMinus,
  FaEdit,
  FaTrash,
  FaSearch,
  FaFilter,
  FaPlus,
} from "react-icons/fa";
import { RiUpload2Fill } from "react-icons/ri";
import { TbFilterSearch } from "react-icons/tb";
import { tableDataDummy } from "../../../data/dummyData";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const TableFinancialCard = ({data}) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const totalItems = tableDataDummy.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = tableDataDummy.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };


  return (
    <div className="p-3 mt-3">
      <div
        className="relative overflow-x-auto shadow-md sm:rounded-lg"
        style={{ borderRadius: 1 }}
      >
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-blue-800 font-light whitespace-nowrap	 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
              {t("billNumber")} 
              </th>
              <th scope="col" className="px-6 py-3">
              {t("date")}
              </th>
              <th scope="col" className="px-6 py-3">
              {t("description")}
              </th>
              <th scope="col" className="px-6 py-3">
              {t("debit")} €
              </th>
              <th scope="col" className="px-6 py-3">
              {t("credit")} €
              </th>
              <th scope="col" className="px-6 py-3">
              {t("oweLeft")} €
              </th>
              <th scope="col" className="px-1 py-3"></th>
            </tr>
          </thead>
          <tbody>
            {data?.bills?.map((bill, index) => (
              <tr key={index} className="border-b">
                <td className="px-6 py-4">{bill.household_bill_id}</td>
                <td className="px-6 py-4">
                  {new Date(bill.created_at).toLocaleDateString()}
                </td>
                <td className="px-6 py-4">{bill.description}</td>
                <td className="px-6 py-4">{bill.value}</td>
                <td className="px-6 py-4">{bill.total_collection_value}</td>
                <td className="px-6 py-4">{bill.remaining_balance}</td>
              </tr>
            ))}
            {data?.bills && data.bills.length > 0 ? (
              <tr className="border-b text-black">
                <td></td>
                <td></td>
                <td className="px-6 py-4 font-semibold">Totali:</td>
                <td className="px-6 py-4 font-semibold">
                  {data?.totals?.total_values}
                </td>
                <td className="px-6 py-4 font-semibold">
                  {data?.totals?.total_collection_values}
                </td>
                <td className="px-6 py-4 font-semibold">
                  {data?.totals?.total_remaining_balance}
                </td>
              </tr>
            ) : (
              "No data to show"
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableFinancialCard;
