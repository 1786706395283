import React from "react";
import { TableFinancialCard } from "../../components";

const AreasRatio = () => {
  return (
    <div className="m-4" data-aos="zoom-in-down">
      <TableFinancialCard />
    </div>
  );
};

export default AreasRatio;
