import React from "react";

const Button = ({ children, className, onClick }) => {
  return (
    <button
      className={`text-blue-800  p-2 ml-3 rounded text-xs flex items-center transition duration-300 ease-in-out hover:bg-blue-800 hover:text-white ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
