import React from "react";
import { TableCanceledBills } from "../../components";

const CanceledInvoices = () => {
  return (
    <div className="m-4" data-aos="zoom-in-down">
      <TableCanceledBills />
    </div>
  );
};

export default CanceledInvoices;
