import React, { useState, useEffect, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import jsPDF from "jspdf";
import html2canvas from "html2canvas"; 
import CertificationInvoice from "./CertificationInvoice";
import { savePDF } from "@progress/kendo-react-pdf";
import { fetchHouseholdById } from "./../../../store/slices/tablesInfoSlices/houseHoldsSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { fetchBusinessesById } from "../../../store/slices/tablesInfoSlices/businessSlice";

export default function Certification() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [date, setDate] = useState("");
  const { id } = useParams();
  const householdData = useSelector((state) => state.households.data);
  const businessData = useSelector((state) => state.businesses.data)

  useEffect(() => {
    dispatch(fetchHouseholdById(id));
    dispatch(fetchBusinessesById(id));
  }, [dispatch]);

  console.log(householdData);

  const componentRef = useRef();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const dateParam = searchParams.get("date");

    if (dateParam) {
      setDate(dateParam);
    }
  }, [location.search]);

  const handleDownload = () => {
    savePDF(
      componentRef.current,
      {
        title: `Order`,
        fileName: `Order }`,
        paperSize: "A4",
      },
      () => setDate(null)
    );
  };

  const generatePDF = () => {
    if (!date) {
      return toast.warning("Duhet ta plotesoni daten!");
    }
    const doc = new jsPDF();

    const canvas = componentRef.current;

    html2canvas(canvas).then((canvasImage) => {
      const imgData = canvasImage.toDataURL("image/png");
      const imgWidth = 200;
      const imgHeight = 200;
      doc.addImage(imgData, "PNG", 5, 0, imgWidth, imgHeight);
      doc.save(`Vertetimi-${id}`);
      toast.success("Vertetimi u gjenerua me sukses!");
    });
  };

  const handleDateChange = (e) => {
    setDate(e.target.value);
  };

  return (
    <div className="p-6">
      <h1 className="py-6 text-blue-900 font-semibold">Vertetimi</h1>
      <form className="space-y-4 text-xs">
        <div className="flex items-center space-x-2">
          <label htmlFor="input1" className="w-32">
            Data e vlefshmerise :
          </label>
          <input
            type="date"
            id="input1"
            name="input1"
            placeholder="Numri"
            className="flex-grow px-2 py-1 border rounded-md"
            value={date}
            onChange={handleDateChange}
            required
          />
        </div>
        <div className="flex justify-end">
          <button
            type="button"
            onClick={generatePDF}
            // onClick={handleDownload}
            className="px-12 py-2 bg-[#2FBFDE] text-white rounded-md"
          >
            Gjenero PDF
          </button>
        </div>
      </form>

      <div style={{ height: 0, overflow: "hidden" }}>
        {date && (
          <CertificationInvoice
            reff={componentRef}
            date={date}
            userId={id}
            data={householdData}
            businessData = {businessData}
          />
        )}
      </div>
    </div>
  );
}
