import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../api/axiosInstance";
import { toast } from "react-toastify";

const initialState = {
  data: null,
  status: "idle",
  error: null,
};

export const createCollectionHousehold = createAsyncThunk(
  "collectionHousehols/createCollectionHousehold",
  async (collectionData) => {
    try {
      const response = await axiosInstance.post(
        "/collectionHousehols/createCollection_household",
        collectionData
      );
      return response.data.collection_household;
    } catch (error) {
      throw error.response.data;
    }
  }
);

export const createCollectionBusiness = createAsyncThunk(
  "collectionBusiness/createCollectionBusiness",
  async (collectionData) => {
    try {
      const response = await axiosInstance.post(
        "/collectionBusiness/createCollectionBusiness",
        collectionData
      );
      return response.data.collection_household;
    } catch (error) {
      throw error.response.data;
    }
  }
);

export const getAllCollections = createAsyncThunk(
  "collectionHouseholds/getAllCollections",
  async () => {
    try {
      const response = await axiosInstance.get(
        "/collectionHousehols/getAllCollections"
      );
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }
);

export const getCollectionById = createAsyncThunk(
  "collectionHouseholds/getCollectionById",
  async (id) => {
    try {
      const response = await axiosInstance.get(
        `/collectionHousehols/getCollectionById/${id}`
      );
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }
);

export const deleteCollection = createAsyncThunk(
  "collectionHouseholds/deleteCollection",
  async (id) => {
    try {
      const response = await axiosInstance.delete(
        `/collectionHousehols/deleteCollection/${id}`
      );
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }
);

const collection_householdSlice = createSlice({
  name: "collections",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createCollectionHousehold.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createCollectionHousehold.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
        toast.success("Vlera e Inkasimit u perditsua me sukses!");
      })
      .addCase(createCollectionBusiness.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
        toast.success("Vlera e Inkasimit u perditsua me sukses!");
      })
      .addCase(getAllCollections.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(deleteCollection.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(createCollectionHousehold.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default collection_householdSlice.reducer;
