import React from "react";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Input from "../../../components/Inputs/Input";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createBusiness } from "../../../store/slices/tablesInfoSlices/businessSlice";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  company_name: Yup.string().required("Required"),
  entry: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  surface: Yup.string().required("Required"),
});

export default function AddNewBusiness() {
  const dispatch = useDispatch();
  const navigate = useNavigate();



  const initialValues = {
    name: "",
    company_name: "",
    entry: "",
    address: "",
    residence: "",
    surface: "",
  };

  return (
    <div className="p-6">
      <h1 className="py-6 text-blue-900 font-semibold">Shto Biznes</h1>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          dispatch(createBusiness(values));
          navigate("/business")
        }}
      >
        {({ errors, touched, handleChange, values }) => (
          <Form className="space-y-4 text-xs">
            <Input
              label="Emri"
              id="name"
              onChange={handleChange}
              name="name"
              placeholder="Emri"
              error={errors.name && touched.name ? errors.name : null}
            />
            <Input
              label="Emri i kompanise"
              id="company_name"
              onChange={handleChange}
              name="company_name"
              placeholder="Emri i kompanise"
              error={errors.company_name && touched.company_name ? errors.company_name : null}
            />
            <Input
              label="Hyrja"
              id="entry"
              onChange={handleChange}
              name="entry"
              placeholder="Hyrja"
              error={
                errors.entry && touched.entry
                  ? errors.entry
                  : null
              }
            />
            <Input
              label="Adresa"
              id="address"
              onChange={handleChange}
              name="address"
              placeholder="Adresa"
              error={errors.address && touched.address ? errors.address : null}
            />
            <Input
              label="Sipërfaqja"
              id="surface"
              onChange={handleChange}
              name="surface"
              placeholder="Sipërfaqja"
              error={errors.surface && touched.surface ? errors.surface : null}
            />
            <div className="flex justify-end">
              <Link to="/household">
                <button
                  type="button"
                  className="px-4 py-2 mr-2 bg-white text-[#28ABE2] rounded-md"
                >
                  Anulo
                </button>
              </Link>
              <button
                type="submit"
                className="px-12 py-2 bg-[#2FBFDE] text-white rounded-md"
              >
                Ruaj
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
