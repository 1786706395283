import React from "react";
import { Link } from "react-router-dom";

export default function MunicipalitiesRegistrationForm() {
  return (
    <div className="p-6  ">
      <h1 className="py-6 text-blue-900 font-semibold">Aktivizo Komunen</h1>
      <form className="space-y-4 text-xs">
        <div className="flex items-center space-x-2">
          <label htmlFor="input1" className="w-32">
            Nr Klientit
          </label>
          <input
            type="text"
            id="input1"
            name="input1"
            placeholder="Nr Klientit"
            className="flex-grow px-2 py-1 border rounded-md"
          />
        </div>

        <div className="flex items-center space-x-2">
          <label htmlFor="input1" className="w-32">
            Emri
          </label>
          <input
            type="text"
            id="input1"
            name="input1"
            placeholder="Emri"
            className="flex-grow px-2 py-1 border rounded-md"
          />
        </div>

        <div className="flex items-center space-x-2">
          <label htmlFor="input1" className="w-32">
            Mbiemri
          </label>
          <input
            type="text"
            id="input1"
            name="input1"
            placeholder="Mbiemri"
            className="flex-grow px-2 py-1 border rounded-md"
          />
        </div>

        <div className="flex items-center space-x-2">
          <label htmlFor="input1" className="w-32">
            Emri i Prindit
          </label>
          <input
            type="text
            "
            id="input1"
            name="input1"
            placeholder="Emri i Prindit"
            className="flex-grow px-2 py-1 border rounded-md"
          />
        </div>
        <div className="flex items-center space-x-2">
          <label htmlFor="input1" className="w-32">
            Nr. Personal
          </label>
          <input
            type="text"
            id="input1"
            name="input1"
            placeholder="Nr. Personal"
            className="flex-grow px-2 py-1 border rounded-md"
          />
        </div>

        <div className="flex items-center space-x-2">
          <label htmlFor="input1" className="w-32">
            Adresa
          </label>
          <input
            type="text"
            id="input1"
            name="input1"
            placeholder="Adresa"
            className="flex-grow px-2 py-1 border rounded-md"
          />
        </div>
        <div className="flex items-center space-x-2">
          <label htmlFor="input1" className="w-32">
            Dera
          </label>
          <input
            type="text"
            id="input1"
            name="input1"
            placeholder="Dera"
            className="flex-grow px-2 py-1 border rounded-md"
          />
        </div>
        <div className="flex items-center space-x-2">
          <label htmlFor="input1" className="w-32">
            Nr. Parcela
          </label>
          <input
            type="text"
            id="input1"
            name="input1"
            placeholder="Nr.Parcela"
            className="flex-grow px-2 py-1 border rounded-md"
          />
        </div>
        <div className="flex items-center space-x-2">
          <label htmlFor="input1" className="w-32">
            Zona
          </label>
          <input
            type="text"
            id="input1"
            name="input1"
            placeholder="Zona"
            className="flex-grow px-2 py-1 border rounded-md"
          />
        </div>
        <div className="flex items-center space-x-2">
          <label htmlFor="input1" className="w-32">
            Sipërfaqja
          </label>
          <input
            type="text"
            id="input1"
            name="input1"
            placeholder="Sipërfaqja"
            className="flex-grow px-2 py-1 border rounded-md"
          />
        </div>
        <div className="flex items-center space-x-2">
          <label htmlFor="input1" className="w-32">
            Status
          </label>
          <input
            type="text"
            id="input1"
            name="input1"
            placeholder="Status"
            className="flex-grow px-2 py-1 border rounded-md"
          />
        </div>
        <div className="flex items-center space-x-2">
          <label htmlFor="input1" className="w-32">
            Data e Regjistrimit
          </label>
          <input
            type="text"
            id="input1"
            name="input1"
            placeholder="Data e Regjistrimit"
            className="flex-grow px-2 py-1 border rounded-md"
          />
        </div>
        <div className="flex items-center space-x-2">
          <label htmlFor="input1" className="w-32">
            Koment
          </label>
          <input
            type="text"
            id="input1"
            name="input1"
            placeholder="Koment"
            className="flex-grow px-2 py-1 border rounded-md"
          />
        </div>

        <div className="flex justify-end">
          <Link to="/household">
            <button
              type="button"
              className="px-4 py-2 mr-2 bg-white text-[#28ABE2] rounded-md"
            >
              Anulo
            </button>
          </Link>
          <button
            type="submit"
            className="px-12 py-2 bg-[#2FBFDE] text-white rounded-md"
          >
            Ruaj
          </button>
        </div>
      </form>
    </div>
  );
}
